import "./bom.scss";
import React, { Component } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  FormLabel,
  Modal,
  OverlayTrigger,
  Row,
  Tab,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Editor from "@uiw/react-md-editor";

import {
  addFileAction,
  approveBomAction,
  downloadFileAction,
  generateFileAction,
  addBOMAction,
  updateBomAction,
  validateBomAction,
  downloadAllFilesAction,
  deactivateBomAction,
  newBomAction,
  manageAltAction,
  bomCommentsListAction,
  addBomCommentAction,
  bomRevUpdateAction,
  ImportStatusAction,
  deleteAllLineItemAction,
} from "../../actions/bom/bom-action";
import { ADD_GPN, SEARCH_DELAY } from "../../helpers/constants";
import { getSelectDarkStyle } from "../../helpers/filter-dropdown-dark";
import { errorToast, updateQueryParams } from "../../helpers/utility";
import Loading from "../shared/loader/spinner";
import { loadOptions } from "./bom-common";
import FileView from "../shared/fileView/fileView";
import LineItem from "./LineItemTable";
import { connect } from "react-redux";
import {
  addGPNAction,
  gpnAutoCompleteAction,
} from "../../actions/inventory/gpn-action";
import store from "../../store";
import AltUpdate from "./AltUpdate";
import CommentView from "../shared/commentView/commentView";
import PopulateBom from "./populateBom";
import RevModal from "./revModal";
import BOMErrorCheckModal from "../modals/BOMErrorCheckModal";
class AddEditBom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bomClasses: [],
      classId: "",
      gpnId: "",
      defaultGpn: {},
      defaultClass: {},
      label: "",
      desc: "",
      duration: "",
      selectedFile: null,
      fileViewHeight: 0,
      modalIsOpen: false,
      openNewModal: false,
      deactivate: false,
      copyLineItems: false,
      managedAlts: {},
      pendingAlt: [],
      acceptedAlt: [],
      rejectedAlt: [],
      additionAlt: [],
      deletionAlt: [],
      altStatus: "",
      comment: "",
      deleteModal: false,
      tab: "#home",
      addBomClasses: [],
      addClass: { label: "PCB Assembly", value: "1", default: "2" },
      addGpnId: "",
      addLabel: "",
      addDesc: "",
      create: false,
      gpnDesc: "",
      autoComplete: [],
      value:
        '<IFRAME SRC="javascript:javascript:alert(window.origin);"></IFRAME>',
      position: false,
      populateModal: false,
      bomId: "",
      revType: "",
      revModal: false,
      revStatus: false,
      critical: "DEFAULT_FALSE",
      fitted: "DEFAULT_TRUE",
      designator: "ERROR",
      showBOMErrorCheckModal: false
    };
    this.loadOptions = loadOptions.bind(this);
    this.onChange = this.onChange.bind(this);
    this.timeOutId = null;
  }

  componentDidMount() {
    // Update the size of the file scroll window

    if (!this.props.is_add) {
      const id = this.props.bom_id;
      store.dispatch(bomCommentsListAction(id));
      manageAltAction(id);
      const leftColHeight = this.leftColumnElement.clientHeight;
      const fileHeaderHeight = this.fileHeaderElement.clientHeight;
      this.setState({ fileViewHeight: leftColHeight - fileHeaderHeight });
    }
    if (window.location.hash) {
      this.setState({ tab: window.location.hash });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({ label: this.props.label });
      this.setState({ desc: this.props.desc });
      this.setState({ duration: this.props.duration });
      this.setState({ bomClasses: this.props.bomClasses });
      this.setState({
        defaultGpn: {
          label:
            this.props.defaultGpn !== undefined ? this.props.defaultGpn : "",
          value:
            this.props.defaultGpn !== undefined ? this.props.defaultGpnId : "",
        },
      });
      this.setState({
        defaultClass: {
          label:
            this.props.defaultClass !== undefined
              ? this.props.defaultClass.name
              : "",
          value:
            this.props.defaultClass !== undefined
              ? this.props.defaultClass.id
              : "",
        },
      });
      this.setState({
        classId:
          this.props.defaultClass !== undefined
            ? this.props.defaultClass.id
            : "",
      });
      document.title = this.props.is_add
        ? "Add Bom"
        : `${this.props.title} on Incontrol`;
    }
    if (
      JSON.stringify(prevProps.newBom) !== JSON.stringify(this.props.newBom)
    ) {
      this.props.revisionId(this.props.newBom?.uuid);
      this.setState({ bomId: this.props.newBom?.uuid });
    }
    if (JSON.stringify(prevProps.revId) !== JSON.stringify(this.props.revId)) {
      if (this.props.revId.task_id) {
        ImportStatusAction(this.props.revId.task_id);
        this.setState({ status: true });
      }
    }
    if (JSON.stringify(prevProps.newId) !== JSON.stringify(this.props.newId)) {
      const newId = this.props.newId.id;
      window.location.replace(`/bom/${newId}`);
    }

    if (prevProps !== this.props) {
      this.setState({ addBomClasses: this.props.bomClasses });
    }
    const autoCompletePropsDidUpdate =
      JSON.stringify(prevProps.gpnautoComplete) !==
      JSON.stringify(this.props.gpnautoComplete);

    // Our GPN data loaded

    if (autoCompletePropsDidUpdate) {
      this.setState({
        autoComplete: this.props.gpnautoComplete,
      });
    }
    if (
      JSON.stringify(prevProps.managedAlts) !==
      JSON.stringify(this.props.managedAlts)
    ) {
      this.setState({ managedAlts: this.props.managedAlts });
    }
    if (
      JSON.stringify(prevProps.pendingAlt) !==
      JSON.stringify(this.props.pendingAlt)
    ) {
      this.setState({ pendingAlt: this.props.pendingAlt });
    }
    if (
      JSON.stringify(prevProps.acceptedAlt) !==
      JSON.stringify(this.props.acceptedAlt)
    ) {
      this.setState({ acceptedAlt: this.props.acceptedAlt });
    }
    if (
      JSON.stringify(prevProps.rejectedAlt) !==
      JSON.stringify(this.props.rejectedAlt)
    ) {
      this.setState({ rejectedAlt: this.props.rejectedAlt });
    }
    if (
      JSON.stringify(prevProps.additionAlt) !==
      JSON.stringify(this.props.additionAlt)
    ) {
      this.setState({ additionAlt: this.props.additionAlt });
    }
    if (
      JSON.stringify(prevProps.deletionAlt) !==
      JSON.stringify(this.props.deletionAlt)
    ) {
      this.setState({ deletionAlt: this.props.deletionAlt });
    }
  }

  handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (
      (event.ctrlKey || event.metaKey) &&
      (charCode === "s") ^ (event.key === "Enter")
    ) {
      event.preventDefault();
      this.handleSubmit();
      console.log("Saving");
    }
  };

  onChange(e) {
    let searchQuery = e.target.value;
    let queryParams = this.props.queryParams;
    queryParams.search_query = searchQuery;
    clearTimeout(this.timeOutId);
    this.timeOutId = setTimeout(() => {
      updateQueryParams(queryParams);
      this.props.search(searchQuery);
    }, SEARCH_DELAY);
  }

  //             add bom

  handleAddSubmit = () => {
    const data = {
      bom_class_id: this.state.addClass.value,
      label: this.state.addLabel,
      product_group_pn_id: this.state.addGpnId,
      description: this.state.addDesc,
      active: true,
    };
    const createGpn = {
      part_type_id: this.state.addClass.default,
      gpn_class: "identical",
      description: this.state.gpnDesc,
      active: true,
      bom: true,
      product_group_pn_id: null,
      label: this.state.addLabel,
      bom_class_id: this.state.addClass.value,
      bomDesc: this.state.addDesc,
    };
    if (this.state.create) {
      store.dispatch(addGPNAction(createGpn));
    } else {
      addBOMAction(data);
    }
  };
  //              edit bom

  getClassid = (elem) => {
    const element = this.state.bomClasses.find((el) => el.name === elem[0]);

    if (element) {
      this.setState({ classId: element.id });
    }
  };

  fileHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    });
  };

  uploadFile = () => {
    const data = new FormData();
    data.append("file", this.state.selectedFile);
    const id = this.props.bom_id;
    // file upload api
    addFileAction(id, data);
  };

  // Upload more than one file at once
  uploadMultiFile = (filesArr) => {
    const data = new FormData();
    filesArr.forEach((file, i) => {
      data.append("file", file);
    });
    const id = this.props.bom_id;
    addFileAction(id, data);
  };

  getGpnMap() {
    return this.props.product.map((el) => ({
      id: el.id,
      value: el.id,
      description: el.description,
      label: `[${el.group_pn}] ${el.description}`,
    }));
  }
  getClassMap() {
    return this.props.bomClasses.map((el) => ({
      id: el.id,
      value: el.id,
      label: el.name,
      default: el.default_part_type_id,
    }));
  }
  errorCheck() {
    if(this.props.errorCounts?.CRITICAL > 0 || this.props.errorCounts?.WARNING > 0 || this.props.errorCounts?.ERROR > 0){
      this.setState({showBOMErrorCheckModal: true})
    } else {
      this.handleGenerate()
    }
  }
  handleGenerate() {
    this.setState({showBOMErrorCheckModal: false})
    const id = this.state.bomId
      ? this.state.bomId
      : this.props.revisions[0].uuid;
    generateFileAction(id);
  }
  handleSubmit() {
    const data = {
      label: this.state.label,
      bom_class_id: this.state.classId,
      description: this.state.desc,
      active: true,
      estimated_build_duration_hours: this.state.duration,
      product_group_pn_id: this.state.defaultGpn.value,
    };
    // console.log(data);
    const id = this.props.bom_id;
    updateBomAction(id, data);
  }
  handleValidate(valid) {
    console.log(valid);
    const data = {
      engineering_validation: valid,
    };
    const id = this.props.bom_id;
    validateBomAction(id, data);
    window.location.reload();
  }
  handleApprove(approve) {
    const data = {
      manufacturing_approval: approve,
    };
    const id = this.props.bom_id;
    approveBomAction(id, data);
    window.location.reload();
  }
  selectTab = (k) => {
    let url = window.location.protocol + window.location.pathname + k;
    window.history.pushState({ path: url }, "", url);
    this.setState({ tab: k });
  };
  altStatus = (e) => {
    if (this.state.altStatus !== e) {
      this.setState({ altStatus: e });
    }
  };
  updateComment(e) {
    this.setState({ comment: e });
  }
  render() {
    const id = this.props.bom_id;
    const path = window.location.pathname;
    let loadingSpinner = "";

    if (this.props.bomLoading) {
      loadingSpinner = (
        <div className="main-loading-spinner">
          <Loading></Loading>
        </div>
      );
    }
    const rev_id = this.state.bomId
      ? this.state.bomId
      : this.props.revisions && this.props.revisions[0].uuid;

    return (
      <div>
        {this.props.is_add ? (
          <div className="main-section">
            <Container>
              <Form>
                <Row>
                  <Col
                    xxl={6}
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="mb-2"
                  >
                    <h3 className="py-4">Add new BOM</h3>

                    <Form.Group as={Row} className="mb-3">
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Create GPN
                        </Form.Label>
                        <Col lg={"8"} sm={"10"} className="px-4 py-2">
                          <Form.Check
                            type={"checkbox"}
                            checked={this.state.create}
                            onChange={() =>
                              this.setState({ create: !this.state.create })
                            }
                          />
                        </Col>
                      </Form.Group>

                      {!this.state.create && (
                        <>
                          <Form.Label column sm="3">
                            Product
                          </Form.Label>
                          <Col lg={"7"} sm={"9"} className="px-2">
                            <AsyncSelect
                              cacheOptions
                              size="sm"
                              classNamePrefix="lp-copy-sel"
                              loadOptions={this.loadOptions}
                              styles={getSelectDarkStyle("md")}
                              defaultOptions
                              // onInputChange={this.handleInputChange}
                              onChange={(el) =>
                                this.setState({ addGpnId: el.value })
                              }
                              placeholder="Type to search GPN"
                            />
                          </Col>
                          <Col lg={"2"} className="px-1">
                            <Link to={ADD_GPN} target="_blank">
                              <Button size="sm" variant="outline-success">
                                Add Gpn
                              </Button>
                            </Link>
                          </Col>
                        </>
                      )}
                      {/* /////////////////////////////////////////// */}
                      {this.state.create && (
                        <>
                          <Form.Label column sm="3">
                            New GPN Description
                          </Form.Label>
                          <Col lg={"7"} sm={"9"} className="px-2">
                            <AsyncTypeahead
                              id="async-example"
                              isLoading={this.props.gpnautoCompleteLoading}
                              labelKey=""
                              onInputChange={(e) =>
                                this.setState({ gpnDesc: e })
                              }
                              onSearch={(e) => {
                                gpnAutoCompleteAction({
                                  field_name: "description",
                                  query: e,
                                  part_type_id: "1",
                                });
                                // this.handleDescriptionChange(e);
                              }}
                              onChange={(e) => this.setState({ gpnDesc: e[0] })}
                              options={this.state.autoComplete}
                              placeholder="Description"
                            />
                          </Col>
                        </>
                      )}
                      {/* //////////////////////////////////////////// */}
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Label
                      </Form.Label>
                      <Col lg={"7"} sm={"9"} className="px-2">
                        <Form.Control
                          type="text"
                          defaultValue=""
                          onChange={(e) =>
                            this.setState({ addLabel: e.target.value })
                          }
                          placeholder=""
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="3">
                        Class
                      </Form.Label>
                      <Col lg={"7"} sm={"9"} className="px-2">
                        <Select
                          size="sm"
                          defaultValue={{
                            label: "",
                            value: "",
                          }}
                          value={this.state.addClass}
                          options={this.getClassMap()}
                          styles={getSelectDarkStyle("md")}
                          onChange={(el) => {
                            console.log(el);
                            this.setState({ addClass: el });
                          }}
                        />
                      </Col>
                      <Col>
                        <OverlayTrigger
                          placement={"right"}
                          overlay={
                            <Tooltip id={`tooltip-right`}>
                              {this.state.bomClasses.map((el) => (
                                <p>
                                  <b>{el.name}:</b> {el.description}
                                </p>
                              ))}
                            </Tooltip>
                          }
                        >
                          <Badge bg="secondary">?</Badge>
                        </OverlayTrigger>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Col sm={"3"}>
                        <Form.Label>Description</Form.Label>
                      </Col>
                      <Col lg={"7"} sm={"9"} className="px-2">
                        <Form.Control
                          as="textarea"
                          type="text"
                          placeholder=""
                          defaultValue=""
                          onChange={(e) =>
                            this.setState({ addDesc: e.target.value })
                          }
                        />

                        <Button
                          className="btn btn-block px-4 my-4"
                          size="md"
                          onClick={this.handleAddSubmit}
                          variant="outline-success"
                        >
                          Add
                        </Button>
                      </Col>
                    </Form.Group>
                    <Col lg={"8"} sm={"10"} className="ml-auto"></Col>
                  </Col>
                </Row>
              </Form>
            </Container>
          </div>
        ) : (
          !this.props.bomLoading && (
            <div className="main-section">
              <Container>
                <Form onKeyDown={this.handleKeyDown}>
                  {/* Title */}
                  <div className="d-flex alig-items-center justify-content-between py-4">
                    <div className="d-flex align-items-center">
                      <h3
                        className={
                          !this.props.status ? "text-muted px-3" : "px-3"
                        }
                      >
                        {this.props.title}{" "}
                      </h3>
                      <i className="text-muted">{this.props.desc}</i>

                      {this.props.status ? (
                        ""
                      ) : (
                        <Badge className="mt-3" bg="danger">
                          Inactive
                        </Badge>
                      )}
                    </div>
                    <div className="w-50">
                      <Form.Select
                        value={this.state.bomId}
                        variant="dark"
                        onChange={(e) => {
                          this.setState({ bomId: e.target.value });
                          this.props.revisionId(e.target.value);
                        }}
                      >
                        {this.props.revisions &&
                          this.props.revisions.map((el) => {
                            var data = el.time_updated.split(".")[0];
                            data = data.replace("T", " ");

                            return (
                              <option value={el.uuid}>
                                {`Version ${el.name}`}{" "}
                                {el.committed ? "🔒" : "🔓"}
                                {el.buildable ? "🔨" : "🚫"}{" "}
                                {el.committed
                                  ? `Commited  ${data} by ${el.committed_by_name}`
                                  : `Edited ${data} by ${el.updated_by_name}`}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </div>
                  </div>

                  <Tabs
                    id="controlled-tab-example"
                    activeKey={this.state.tab}
                    onSelect={(k) => this.selectTab(k)}
                    className="mb-3"
                  >
                    <Tab
                      eventKey={"#home"}
                      title="Home"
                      className={!this.props.status && "text-muted"}
                    >
                      <Row className="mb-2">
                        {/* BOM fields */}
                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          ref={(el) => {
                            this.leftColumnElement = el;
                          }}
                        >
                          {/* Status indicator      */}
                          <Form.Group
                            as={Row}
                            className="mb-2"
                            controlId="formPlaintext"
                          >
                            <Form.Label column sm="2">
                              Status:
                            </Form.Label>
                            <Col lg={"10"} sm={"10"} className="px-4 pt-2">
                              <div>
                                {this.props.status == true
                                  ? "Active"
                                  : "Inactive"}
                              </div>
                            </Col>
                          </Form.Group>

                          {/* Product GPN dropdown select */}
                          <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                              Product
                            </Form.Label>
                            <Col lg={"10"} sm={"10"} className="px-4">
                              <AsyncSelect
                                cacheOptions
                                size="sm"
                                isDisabled={this.props.status ? false : true}
                                className={!this.props.status && "text-muted"}
                                classNamePrefix="lp-copy-sel"
                                defaultValue={{
                                  label: "gpn",
                                  value: "1",
                                }}
                                value={{
                                  label:
                                    this.state.defaultGpn !== undefined
                                      ? this.state.defaultGpn.label
                                      : "",
                                  value:
                                    this.state.defaultGpn !== undefined
                                      ? this.state.defaultGpn.id
                                      : "",
                                }}
                                loadOptions={this.loadOptions}
                                styles={getSelectDarkStyle("md")}
                                defaultOptions
                                // onInputChange={this.handleInputChange}
                                onChange={(el) =>
                                  this.setState({ defaultGpn: el })
                                }
                                // placeholder="Type to search GPN"
                              />
                            </Col>
                          </Form.Group>

                          {/* BOM labal */}
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formPlaintext"
                          >
                            <Form.Label column sm="2">
                              Label
                            </Form.Label>
                            <Col lg={"10"} sm={"10"} className="px-4">
                              <Form.Control
                                type="text"
                                disabled={this.props.status ? false : true}
                                className={!this.props.status && "text-muted"}
                                defaultValue={
                                  this.props.label && this.props.label
                                }
                                onChange={(e) =>
                                  this.setState({ label: e.target.value })
                                }
                                placeholder="[PRELIM] Product name, revision"
                              />
                            </Col>
                          </Form.Group>

                          {/* Bom class */}
                          <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                              Class
                            </Form.Label>
                            <Col lg={"10"} sm={"10"} className="px-4">
                              <Select
                                size="sm"
                                isDisabled={this.props.status ? false : true}
                                className={!this.props.status && "text-muted"}
                                defaultValue={{
                                  label: "",
                                  value: "",
                                }}
                                value={{
                                  label:
                                    this.state.defaultClass !== undefined
                                      ? this.state.defaultClass.label
                                      : "",
                                  value:
                                    this.state.defaultClass !== undefined
                                      ? this.state.defaultClass.id
                                      : "",
                                }}
                                options={this.getClassMap()}
                                styles={getSelectDarkStyle("md")}
                                onChange={(el) => {
                                  this.setState({ defaultClass: el });
                                  this.setState({ classId: el.id });
                                }}
                              />
                            </Col>
                            <Col>
                              <OverlayTrigger
                                placement={"right"}
                                overlay={
                                  <Tooltip className="tooltip-inner">
                                    {this.state.bomClasses.map((el) => (
                                      <p>
                                        <b>{el.name}:</b> {el.description}
                                      </p>
                                    ))}
                                  </Tooltip>
                                }
                              >
                                <Badge bg="secondary">?</Badge>
                              </OverlayTrigger>
                            </Col>
                          </Form.Group>

                          {/* Description */}
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formPlaintext"
                          >
                            <Col sm={"2"}>
                              <Form.Label>Description</Form.Label>
                            </Col>
                            <Col lg={"10"} sm={"10"} className="px-4">
                              <Form.Control
                                as="textarea"
                                disabled={this.props.status ? false : true}
                                className={!this.props.status && "text-muted"}
                                type="text"
                                placeholder=""
                                defaultValue={
                                  this.props.bomDescription && this.props.bomDescription
                                }
                                onChange={(e) =>
                                  this.setState({ desc: e.target.value })
                                }
                              />
                            </Col>
                          </Form.Group>

                          {/* Bom estimated duration */}
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formPlaintext"
                          >
                            <Col sm={"2"}>
                              <Form.Label>Estimated Duration</Form.Label>
                            </Col>
                            <Col lg={"6"} sm={"8"} className="px-4">
                              <Form.Control
                                type="text"
                                disabled={this.props.status ? false : true}
                                className={!this.props.status && "text-muted"}
                                placeholder=""
                                defaultValue={
                                  this.props.duration && this.props.duration
                                }
                                onChange={(e) =>
                                  this.setState({ duration: e.target.value })
                                }
                              />
                            </Col>
                            <Col>
                              <p>Hours</p>
                            </Col>
                          </Form.Group>
                        </Col>

                        {/* File content */}
                      </Row>
                      {/*  */}

                      {/* Action Buttons */}
                      <Row className="mb-5">
                        {/* BOM related actions */}
                        <Col md={"6"}>
                          <Row className="my-3">
                            <Col md={3}>
                              <Button
                                className="btn p-1 w-100"
                                disabled={this.props.status ? false : true}
                                onClick={() => this.handleSubmit()}
                                variant="outline-info"
                              >
                                Save
                              </Button>{" "}
                            </Col>
                            <Col>
                              <Button
                                md={2}
                                className="btn p-1 w-100"
                                onClick={() =>
                                  this.setState({ modalIsOpen: true })
                                }
                                variant={
                                  this.props.status
                                    ? "outline-danger"
                                    : "outline-success"
                                }
                              >
                                {this.props.status ? "Deactivate" : "Activate"}
                              </Button>
                            </Col>
                            <Col md={3}>
                              <Button
                                variant="outline-warning"
                                onClick={() =>
                                  this.setState({ openNewModal: true })
                                }
                              >
                                New version
                              </Button>
                            </Col>
                          </Row>
                          {/*                  Deactivate Modal                  */}
                          <Modal
                            show={this.state.modalIsOpen}
                            onHide={() => this.setState({ modalIsOpen: false })}
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>
                                {this.props.status ? "Deactivate" : "Activate"}{" "}
                                BOM
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Are you sure you want to{" "}
                              {this.props.status ? "deactivate" : "activate"}{" "}
                              <b>{this.props.title}</b>?
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                className="btn btn-danger"
                                onClick={() => {
                                  this.setState({ modalIsOpen: false });
                                  deactivateBomAction(id, {
                                    active: this.props.status ? false : true,
                                  });
                                }}
                              >
                                Yes
                              </Button>
                              <Button
                                className="btn btn-secondary"
                                onClick={() =>
                                  this.setState({ modalIsOpen: false })
                                }
                              >
                                No
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          {/*                 Deactivate Modal                      */}

                          {/*                  New Version Modal                    */}
                          <Modal
                            show={this.state.openNewModal}
                            onHide={() =>
                              this.setState({ openNewModal: false })
                            }
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>New BOM version</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form.Check
                                label="Copy Line Items"
                                onChange={() =>
                                  this.setState({
                                    copyLineItems: !this.state.copyLineItems,
                                  })
                                }
                              />
                              <Form.Check
                                label="Deactivate this version"
                                onChange={() =>
                                  this.setState({
                                    deactivate: !this.state.deactivate,
                                  })
                                }
                              />
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                className="btn btn-secondary"
                                onClick={() => {
                                  this.setState({ openNewModal: false });
                                }}
                              >
                                cancel
                              </Button>
                              <Button
                                variant="outline-success"
                                onClick={() => {
                                  newBomAction(this.props.title, {
                                    active: true,
                                  });
                                  //    {
                                  //   deactivate: this.state.deactivate,
                                  //   duplicate_line_items:
                                  //     this.state.copyLineItems,
                                  // }
                                  this.setState({ openNewModal: false });
                                }}
                              >
                                create
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          {/*                 New Version Modal                      */}
                        </Col>

                        {/* File related actions */}
                      </Row>
                    </Tab>
                    <Tab
                      eventKey={"#items"}
                      title={
                        <>
                          Line Items&nbsp;
                          <i class="fa fa-clock-o" aria-hidden="true"></i>
                        </>
                      }
                    >
                      <Modal
                        show={this.state.deleteModal}
                        onHide={() => this.setState({ deleteModal: false })}
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Delete Line Items</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure to delete All?</Modal.Body>
                        <Modal.Footer>
                          <Button
                            className="btn btn-secondary"
                            onClick={() => {
                              this.setState({ deleteModal: false });
                            }}
                          >
                            cancel
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => {
                              if (this.props.lineItems.length == 0) {
                                errorToast("Items Not Found");
                              } else {
                                let uuid =
                                  this.props.lineItems[0]?.bom_revision_uuid;
                                deleteAllLineItemAction(uuid);
                                this.setState({ deleteModal: false });
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <Form.Group as={Row} className="mb-3">
                            <Col lg={"6"}>
                              <Form.Label>
                                Show position information:
                              </Form.Label>
                            </Col>
                            <Col lg={"6"}>
                              <Form.Check
                                value={this.state.position}
                                onChange={() =>
                                  this.setState({
                                    position: !this.state.position,
                                  })
                                }
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className=""
                        >
                          <Row className="mb-4">
                            <Col
                              xxl={12}
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <div className="float-end">
                                <Button
                                  className="delete-button w-200"
                                  onClick={() =>
                                    this.setState({ deleteModal: true })
                                  }
                                >
                                  Delete All
                                </Button>
                              </div>
                            </Col>
                            <Col
                              xxl={12}
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <div className="float-end mt-3">
                                <Button
                                  size="sm"
                                  disabled={this.props.status ? false : true}
                                  variant="outline-light"
                                  onClick={() =>
                                    this.setState({ populateModal: true })
                                  }
                                >
                                  Populate from file
                                </Button>{" "}
                                <Button
                                  size="sm"
                                  disabled={this.props.status ? false : true}
                                  onClick={() => {
                                    {
                                      this.setState({ revType: "update" });
                                      this.setState({ revModal: true });
                                    }
                                  }}
                                  variant="outline-light"
                                >
                                  Update
                                </Button>{" "}
                                <Button
                                  size="sm"
                                  disabled={this.props.status ? false : true}
                                  onClick={() => {
                                    this.setState({ revType: "validate" });
                                    this.setState({ revModal: true });
                                  }}
                                  variant="outline-light"
                                >
                                  Validate
                                </Button>{" "}
                                <Button
                                  size="sm"
                                  disabled={this.props.status ? false : true}
                                  onClick={() => this.errorCheck()}
                                  variant="outline-light"
                                >
                                  Generate Files
                                </Button>
                                {this.state.showBOMErrorCheckModal && <BOMErrorCheckModal show={this.state.showBOMErrorCheckModal} onHide={() => this.setState({showBOMErrorCheckModal: false})} onProceed={() => this.handleGenerate()} errors={this.props.errorCounts}/>}
                                <Button
                                  size="sm"
                                  disabled={this.props.status ? false : true}
                                  onClick={() => {
                                    this.setState({ revType: "commit" });
                                    this.setState({ revModal: true });
                                  }}
                                  variant="outline-light"
                                >
                                  Commit
                                </Button>
                              </div>
                            </Col>
                          </Row>

                          {/* /////////////////////////////////////////////////////////////////////////////////// */}
                        </Col>
                      </Row>
                      <Row>
                        <LineItem
                          lineItems={this.props.lineItems}
                          listRaw={this.props.listRaw}
                          lineitemsLoading={this.props.lineitemsLoading}
                          showPosition={this.state.position}
                          errorCounts={this.props.errorCounts}
                        />
                      </Row>
                      {this.state.revModal && (
                        <RevModal
                          rev_id={rev_id}
                          revType={this.state.revType}
                          revModal={(e) => this.setState({ revModal: e })}
                        />
                      )}
                      {/*  */}
                      {this.state.populateModal && (
                        <PopulateBom
                          revId={
                            this.state.bomId === ""
                              ? this.props.revisions &&
                                this.props.revisions[0]?.uuid
                              : this.state.bomId
                          }
                          populateModal={(e) =>
                            this.setState({ populateModal: e })
                          }
                        />
                      )}
                      {/*  */}
                    </Tab>
                    <Tab eventKey={"#files"} title="Files">
                      <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                          <h5
                            ref={(el) => {
                              this.fileHeaderElement = el;
                            }}
                            style={{ display: "inline-block" }}
                          >
                            Files
                          </h5>
                          &nbsp; &nbsp; &nbsp;
                          <em className="text-muted">
                            (accepts drag &amp; drop)
                          </em>
                          <div>
                            <FileView
                              files={this.props.files}
                              deleteFileAction={this.props.deleteFile}
                              filesLoading={this.props.filesLoading}
                              addFileLoading={this.props.addFileLoading}
                              uploader={this.uploadMultiFile}
                              height={this.state.fileViewHeight}
                              downloadFileAction={downloadFileAction}
                            />
                          </div>
                        </Col>
                        <Row className="my-3">
                          <Col md={"3"}>
                            <Form.Control
                              type="file"
                              size={"sm"}
                              onChange={this.fileHandler}
                            />
                          </Col>
                          <Col md={"3"}>
                            <Button
                              className="btn w-100"
                              size="sm"
                              onClick={this.uploadFile}
                              variant="outline-light"
                              disabled={
                                this.state.selectedFile == null ||
                                !this.props.status
                                  ? true
                                  : false
                              }
                            >
                              Upload
                            </Button>
                          </Col>
                          {"  "}
                        </Row>
                        <Row>
                          <Col md={"6"}>
                            <Button
                              className="btn w-100"
                              size="sm"
                              variant="outline-light"
                              disabled={this.props.status ? false : true}
                              onClick={() => {
                                // console.log(id);
                                downloadAllFilesAction(id);
                              }}
                            >
                              <i className="fa fa-download"></i> All
                            </Button>
                          </Col>
                        </Row>
                      </Row>
                    </Tab>
                    <Tab
                      eventKey={"#Alts"}
                      title={
                        <div>
                          Alt Update{" "}
                          <i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;
                          <i
                            class={`fa ${this.state.altStatus}`}
                            aria-hidden="true"
                          ></i>
                        </div>
                      }
                    >
                      <AltUpdate
                        managedAlts={this.state.managedAlts}
                        pendingAlt={this.state.pendingAlt}
                        acceptedAlt={this.state.acceptedAlt}
                        rejectedAlt={this.state.rejectedAlt}
                        additionAlt={this.state.additionAlt}
                        deletionAlt={this.state.deletionAlt}
                        timeSynced={this.props.timeSynced}
                        altStatus={this.altStatus}
                        rev_id={rev_id}
                      />
                    </Tab>
                    <Tab
                      eventKey={"#comments"}
                      title={
                        <>
                          Comments &nbsp;&nbsp;
                          <Badge className="bg-secondary">
                            {this.props.bomCommentsList.length}
                          </Badge>
                        </>
                      }
                    >
                      <CommentView commentsList={this.props.bomCommentsList} />
                      <br />
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          store.dispatch(
                            addBomCommentAction(id, {
                              comment: this.state.comment,
                            })
                          );
                          this.setState({ comment: "" });
                          console.log("Form submitted");
                        }}
                      >
                        <Editor
                          preview="edit"
                          value={this.state.comment}
                          onChange={(value) => this.updateComment(value)}
                        />
                        <button
                          type="submit"
                          className="btn btn-outline-info my-2"
                        >
                          Comment
                        </button>
                      </Form>
                      <br />
                      <br />
                    </Tab>
                    <Tab eventKey={"#history"} title="History">
                      <h1>Histoy Tab</h1>
                    </Tab>
                  </Tabs>
                </Form>
              </Container>
            </div>
          )
        )}
        <div>{loadingSpinner}</div>
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    gpnautoComplete: state.inventory.gpnautoComplete,
    gpnautoCompleteLoading: state.inventory.gpnautoCompleteLoading,
    managedAlts: state.bom.managedAlts,
    managedAltsLoading: state.bom.managedAltsLoading,
    pendingAlt: state.bom.pendingAlt,
    pendingAltLoading: state.bom.pendingAltLoading,
    acceptedAlt: state.bom.acceptedAlt,
    acceptedAltLoading: state.bom.acceptedAltLoading,
    rejectedAlt: state.bom.rejectedAlt,
    rejectedAltLoading: state.bom.rejectedAltLoading,
    additionAlt: state.bom.additionAlt,
    additionAltLoading: state.bom.additionAltLoading,
    deletionAlt: state.bom.deletionAlt,
    deletionAltLoading: state.bom.deletionAltLoading,
    timeSynced: state.bom.timeSynced,
    timeSyncedLoading: state.bom.timeSyncedLoading,
    bomCommentsList: state.comments.getBomComments,
    bomcommentsLoading: state.comments.getBomCommentsLoading,
    revId: state.bom.revId,
    revIdLoading: state.bom.revIdLoading,
    newBom: state.bom.newBom,
    newBomLoading: state.bom.newBomLoading,
    errorCounts: state.bom.errorCounts,
    errorCountsLoading: state.bom.errorCountsLoading,
  };
};
export default connect(stateMap)(AddEditBom);
