import React, { Component } from "react";
import { connect } from "react-redux";
import { getALTERNATIVEListAction } from "../../../actions/inventory/alternative-action";
import Header from "../../../components/header/header";
import Search from "../../../components/shared/search/search";
import CustomTable from "../../../components/shared/table/table";
import { stringToInt, updateQueryParams } from "../../../helpers/utility";
import store from "../../../store";
import qs from "qs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
class alternativeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alternativeList: [],
      columnName: [
        { label: "Alt Name", key: "alt_name" },
        { label: "Base GPN", key: "group_pn" },
        { label: "Base Description", key: "description" },
        { label: "Alt GPN", key: "group_pn" },
        { label: "Alternative Description", key: "description" },
        { label: "Alt Class", key: "label" },
      ],
      queryParams: {},
      requestContent: {
        search_query: "",
      },
      pagination: {},
    };
    this.tableFilters = this.tableFilters.bind(this);
    this.headerSearch = this.headerSearch.bind(this);
    this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    document.title = "Alternative List";
    this.setState(
      {
        queryParams: stringToInt(
          qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        ),
      },
      () => {
        this.getData(this.state.queryParams);
      }
    );
  }
  //
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.alternativeList) !==
      JSON.stringify(this.props.alternativeList)
    ) {
      this.setState({
        alternativeList: this.props.alternativeList.map((elem) => {
          return {
            0: elem.name,
            1: (
              <Link to={`/gpn/${elem.base_group_pn.id}`}>
                {elem.base_group_pn.group_pn}
              </Link>
            ),
            2: elem.base_group_pn.description,
            3: (
              <Link to={`/gpn/${elem.alternative_group_pn.id}`}>
                {elem.alternative_group_pn.group_pn}
              </Link>
            ),
            4: elem.alternative_group_pn.description,
            5: elem.alternative_class_name,
            id: elem.id,
            active: elem.active,
          };
        }),
      });
    }
    if (
      JSON.stringify(prevProps.alternativeListRaw) !==
      JSON.stringify(this.props.alternativeListRaw)
    ) {
      // PageCount
      this.setState({ pagination: this.props.alternativeListRaw.pagination });
    }
    //
  }
  //
  headerSearch(searchQuery) {
    this.setState(
      {
        requestContent: {
          ...this.state.requestContent,
          search_query: searchQuery,
        },
      },
      () => {
        updateQueryParams(this.state.requestContent);
        this.getData(this.state.requestContent);
      }
    );
  }

  tableFilters(requestContent = {}, type = "list") {
    let { search_query } = requestContent;
    this.setState(
      { requestContent: { ...requestContent, search_query } },
      () => {
        this.getData(this.state.requestContent, type);
      }
    );
  }
  handleRowClick = (id) => {
    console.log(id);
  };
  getData(requestContent = {}, type = "list") {
    requestContent = stringToInt(requestContent);
    // console.log("getData: ", requestContent);
    store.dispatch(
      getALTERNATIVEListAction({
        request_content: requestContent,
        request_type: type,
      })
    );
  }
  render() {
    console.log(this.state.requestContent);
    return (
      <div>
        <Header>
          <Search
            search={this.headerSearch}
            queryParams={this.state.queryParams}
          />
        </Header>
        <div className="bom-section">
          <CustomTable
            queryParams={this.state.queryParams}
            columns={this.state.columnName}
            getId={this.handleRowClick}
            isLoading={this.props.alternativeListLoading}
            tableFilters={this.tableFilters}
            data={this.state.alternativeList}
            pagination={this.state.pagination}
          />
        </div>
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    alternativeList: state.inventory.alternativeList,
    alternativeListRaw: state.inventory.alternativeListRaw,
    alternativeListLoading: state.inventory.alternativeListLoading,
  };
};
export default connect(stateMap)(alternativeContainer);
