import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  getAltAction,
  getAltClassesAction,
} from "../../../actions/inventory/alternative-action";
import Header from "../../../components/header/header";
import AddEditAlternative from "../../../components/inventory/alternative/add-edit-alternative";

class EditAlternativeContainer extends Component {
  constructor(props) {
    super(props);
    const alt_id = this.props.match.params.id;
    this.state = {
      altClasses: [],
      alt: {},
      alt_id: alt_id,
    };
  }
  componentDidMount() {
    getAltClassesAction();
    if(this.state.alt_id !== 'add') {
      getAltAction(this.state.alt_id);
    }
    this.setState({
      altClasses: this.props.altClasses,
      alt: {},
      baseGpn: "",
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.altClasses) !==
      JSON.stringify(this.props.altClasses)
    ) {
      this.setState({ altClasses: this.props.altClasses });
    }
    if (
      JSON.stringify(prevProps.getAlt) !== JSON.stringify(this.props.getAlt)
    ) {
      this.setState({ alt: this.props.getAlt });
    }
    if (
      JSON.stringify(prevProps.altClasses) !==
      JSON.stringify(this.props.altClasses)
    ) {
      this.setState({ altClasses: this.props.altClasses });
    }
  }
  render() {
    // console.log(this.state.alt);
    return (
      <Fragment>
        <Header />
        <AddEditAlternative
          alt_id={this.state.alt_id}
          altTitle={this.state.alt.name}
          altClasses={this.state.altClasses}
          baseGpn={this.state.alt.base_group_pn_name}
          baseGpnValue={this.state.alt.base_group_pn_id}
          baseDesc={this.state.alt.base_group_pn?.description}
          altGpn={this.state.alt.alternative_group_pn?.group_pn}
          altGpnValue={this.state.alt.alternative_group_pn?.id}
          altDesc={this.state.alt.alternative_group_pn?.description}
          altClass={this.state.alt.alternative_class_name}
          altClassValue={this.state.alt.alternative_class_id}
          note={this.state.alt.note}
          active={this.state.alt.active}
          priority={this.state.alt.priority}
        />
      </Fragment>
    );
  }
}
const stateMap = (state) => {
  return {
    altClasses: state.inventory.altClasses,
    altClassesLoading: state.inventory.altClassesLoading,
    getAlt: state.inventory.getAlt,
    getAltLoading: state.inventory.getAltLoading,
  };
};
export default connect(stateMap)(EditAlternativeContainer);
