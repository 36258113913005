import { http } from "../http";
import {
  LOGIN_API,
  REFRESH_API,
  REVOKE_ACCESS,
  REVOKE_REFRESH,
} from "../constants";
import { getRefreshToken } from "../../helpers/utility";

export const login = async (data) => {
  const token = Buffer.from(
    `${data.username}:${data.password}`,
    "utf8"
  ).toString("base64");

  return http.post(
    LOGIN_API,
    {},
    {
      headers: {
        authorization: `Basic ${token}`,
        Authorization: `Basic ${token}`,
      },
    }
    // Note to Haseeb: I am unsure why using auth: seems not to work. Sometimes
    // it seemed to send both Bearer and Basic auth, which isn't really allowed.
    // Generating the token manually seems to work better.
    // {
    //   auth: {
    //     username: data.username,
    //     password: data.password,
    //   },
    // }
  );
};

export const googleLogin = async (token) => {
  var axios = require("axios");
  var FormData = require("form-data");
  var data = new FormData();
  data.append("idtoken", token);

  var config = {
    method: "post",
    url: LOGIN_API,
    data: data,
  };

  return axios(config);
};

export const refresh = async () => {
  return http.post(REFRESH_API);
};
export const revokeAccess = async () => {
  return http.delete(REVOKE_ACCESS);
};
export const revokeRefresh = async () => {
  return http.delete(REVOKE_REFRESH);
};
