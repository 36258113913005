import { BACKEND_VERSION } from "../constants";
import { http } from "../http";

// const config = {
//   "access-control-allow-origin": "*",
// };

export const getAbout = async () => {
  return http.get(BACKEND_VERSION);
};
