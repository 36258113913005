import "./search.scss";

import React from "react";

import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { Link } from "react-router-dom";

import {
  ADD_ALTER,
  ADD_BOM,
  ADD_GPN,
  ADD_MPN,
  SEARCH_DELAY,
} from "../../../helpers/constants";
import { updateQueryParams } from "../../../helpers/utility";

export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.timeOutId = null;
  }
  onChange(e) {
    e.preventDefault();
    let searchQuery = e.target.value;
    let queryParams = this.props.queryParams;
    queryParams.search_query = searchQuery;
    clearTimeout(this.timeOutId);
    this.timeOutId = setTimeout(() => {
      updateQueryParams(queryParams);
      this.props.search(searchQuery);
    }, SEARCH_DELAY);
  }
  render() {
    const page = window.location.pathname;
    return (
      <div className="search-bar">
        <Row className="d-flex justify-content-end align-items-center">
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="text-end pt-1 pb-1">
              {page.includes("bom") && (
                <Link to={ADD_BOM}>
                  <Button className="grey-btn btn-sm" variant="">
                    Add Bom
                  </Button>
                </Link>
              )}{" "}
              {page.includes("mpn") && (
                <Link to={ADD_MPN}>
                  <Button className="grey-btn btn-sm" variant="">
                    Add IPN
                  </Button>
                </Link>
              )}
              {page.includes("gpn") && (
                <Link to={ADD_GPN}>
                  <Button className="grey-btn btn-sm" variant="">
                    Add GPN
                  </Button>
                </Link>
              )}
              {page.includes("alternatives") && (
                <Link to={ADD_ALTER}>
                  <Button className="grey-btn btn-sm" variant="">
                    Add Alt
                  </Button>
                </Link>
              )}
              <Form className="search-input" onSubmit={this.onChange}>
                <FormControl
                  onChange={this.onChange}
                  onKeyUp={(e) => {
                    e.preventDefault();
                    if (e.keyCode === 13) {
                      this.onChange(e);
                    }
                  }}
                  defaultValue={this.props.queryParams.search_query}
                  type="text"

                  placeholder="Search"
                  className="mr-2 form-control-sm search-bar"
                  aria-label="text"
                />
                <Button className="grey-btn btn-sm" variant="" id="myBtn">
                  Search
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
