import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Container } from "react-bootstrap";
import { getuserName, logout } from "../../helpers/utility";
import { Link } from "react-router-dom";
import {
  TO_ABOUT,
  TO_ADMIN,
  TO_ALTER,
  TO_BOM,
  TO_BULK,
  TO_GPN,
  TO_MPN,
} from "../../helpers/constants";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      admin: false,
    };
  }

  componentDidMount() {
    const adminStatus = localStorage.getItem("admin");
    this.setState({ admin: adminStatus });
  }

  render() {
    return (
      <Navbar
        className="py-0"
        sticky="top"
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
      >
        <Container fluid>
          <Navbar.Brand as={Link} to={TO_GPN}>
            Incontrol Online
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {/* <Nav.Link href="#Inventory"> Inventory </Nav.Link> */}
              <NavDropdown title="Inventory" id="collasible-nav-dropdown">
                <NavDropdown.Item as={Link} to={TO_GPN}>
                  GPN View
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={TO_MPN}>
                  MPN View
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={TO_BULK}>
                  Bulk Process
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={TO_ALTER}>
                  Alternatives
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Projects" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">
                  Project List
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={TO_BOM}>
                  BOM List
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Reports</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Tools" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">
                  Resistor Ratio Calculator
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Item ID Converter
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Panel Optimizer
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={getuserName()} id="collasible-nav-dropdown">
                {this.state.admin ? (
                  <NavDropdown.Item as={Link} to={TO_ADMIN}>
                    Admin Console
                  </NavDropdown.Item>
                ) : (
                  ""
                )}
                <NavDropdown.Item as={Link} to={TO_ABOUT}>
                  About
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Change password
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#" onClick={logout}>
                  {" "}
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            {this.props.children}
            {/* <Nav>
                            <Nav.Link href="#deets">

                            </Nav.Link>
                        </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}
