// Wrapper possible dark theme for react-select


/**
 * Return a theming object for select with default or small size options.
 * @param {str} size can either be blank or 'sm' (small) for now
 * @returns
 */
export function getSelectDarkStyle(size) {
    const small = size === "sm"
    // const small = false

    // control_small = {
    //     minHeight: '30px',
    //     height: '30px',
    // }

    return {

        clearIndicator: (provided, state) => ({ ...provided }),
        container: (provided, state) => ({ ...provided }),

        // The main dropdown box
        control: (provided, state) => ({
            ...provided,
            ...(small) && {
                // minHeight: '30px',
                // height: '30px',
            }
        }),

        // lineHeight: 1.5,
        // height: 32,
        // }),

        dropdownIndicator: (provided, state) => ({ ...provided }),
        group: (provided, state) => ({ ...provided }),
        groupHeading: (provided, state) => ({ ...provided }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            // ...(small) && { height: '30px', }
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            // display: 'none',
        }),

        // Text that is typed
        input: (provided, state) => ({
            ...provided,
            ...(small) && {
                margin: '0px',
            }
        }),
        loadingIndicator: (provided, state) => ({ ...provided }),
        loadingMessage: (provided, state) => ({ ...provided }),

        // Background on the dropdow.
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#222'
        }),

        menuList: (provided, state) => ({ ...provided }),
        menuPortal: (provided, state) => ({ ...provided }),
        multiValue: (provided, state) => ({ ...provided }),
        multiValueLabel: (provided, state) => ({ ...provided }),
        multiValueRemove: (provided, state) => ({ ...provided }),
        noOptionsMessage: (provided, state) => ({ ...provided }),

        // A menu dropdown option. Default color is transparant, gets from 'menu'
        option: (provided, state) => ({
            ...provided,
            // borderBottom: '1px dotted #999',
            color: 'white',
            backgroundColor: state.isFocused ? '#395B7E' : 'transparant',
        }),

        // Default text
        placeholder: (provided, state) => ({ ...provided }),
        singleValue: (provided, state) => ({ ...provided }),
        valueContainer: (provided, state) => ({
            ...provided,
            ...(small) && {
                // height: '30px',
                padding: '0 6px'
            }
        }),
    }

}
// export const selectDarkStyles =
