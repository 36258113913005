import fileDownload from "js-file-download";
import { init } from "../../api/http";
import {
  createAlternative,
  getAlternative,
  getAlternativeClasses,
  getAlternativeList,
  updateAlternative,
} from "../../api/inventory/alternative-api";
import {
  ALTERNATIVE_CLASSES,
  ALTERNATIVE_CLASSES_LOADING,
  ALTERNATIVE_LIST,
  ALTERNATIVE_LIST_LOADING,
  ALTERNATIVE_LIST_RAW,
  CREATE_ALTERNATIVE,
  CREATE_ALTERNATIVE_LOADING,
  GET_ALTERNATIVE,
  GET_ALTERNATIVE_LOADING,
  UPDATE_ALTERNATIVE,
  UPDATE_ALTERNATIVE_LOADING,
} from "../../helpers/constants";
import { successToast } from "../../helpers/utility";
import store from "../../store";

export function getALTERNATIVEListAction(data) {
  init();
  store.dispatch({
    type: ALTERNATIVE_LIST_LOADING,
    payload: true,
  });
  return async function (dispatch) {
    try {
      const res = await getAlternativeList(data);
      if (data.request_type === "csv") {
        fileDownload(res.data, "alternative_data.csv");
      }

      dispatch({
        type: ALTERNATIVE_LIST_LOADING,
        payload: false,
      });
      store.dispatch({
        type: ALTERNATIVE_LIST,
        payload: [],
      });
      if (res.data.alternative_list) {
        dispatch({
          type: ALTERNATIVE_LIST,
          payload: res.data.alternative_list,
        });
        dispatch({
          type: ALTERNATIVE_LIST_RAW,
          payload: res.data,
        });
        dispatch({
          type: ALTERNATIVE_LIST_LOADING,
          payload: false,
        });
      }
    } catch (error) {
      dispatch({
        type: ALTERNATIVE_LIST_LOADING,
        payload: false,
      });
    }
  };
}

//             alts classes

export async function getAltClassesAction() {
  init();
  store.dispatch({
    type: ALTERNATIVE_CLASSES_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ALTERNATIVE_CLASSES,
    payload: [],
  });
  try {
    const res = await getAlternativeClasses();

    if (res.data.alternative_class_list) {
      store.dispatch({
        type: ALTERNATIVE_CLASSES,
        payload: res.data.alternative_class_list,
      });
      store.dispatch({
        type: ALTERNATIVE_CLASSES_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    store.dispatch({
      type: ALTERNATIVE_CLASSES_LOADING,
      payload: false,
    });
  }
}

//                  create alts

export function createAltAction(data) {
  init();
  store.dispatch({
    type: CREATE_ALTERNATIVE_LOADING,
    payload: true,
  });
  return async function (dispatch) {
    try {
      const res = await createAlternative(data);
      if (res) {
        window.location.replace("/alternatives");
      }
      dispatch({
        type: CREATE_ALTERNATIVE_LOADING,
        payload: false,
      });
      store.dispatch({
        type: CREATE_ALTERNATIVE,
        payload: {},
      });
      if (res.data) {
        dispatch({
          type: CREATE_ALTERNATIVE,
          payload: res.data,
        });
        dispatch({
          type: CREATE_ALTERNATIVE,
          payload: false,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_ALTERNATIVE_LOADING,
        payload: false,
      });
    }
  };
}

//                       get ALt

export async function getAltAction(id) {
  init();
  store.dispatch({
    type: GET_ALTERNATIVE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GET_ALTERNATIVE,
    payload: {},
  });
  try {
    const res = await getAlternative(id);

    if (res.data) {
      store.dispatch({
        type: GET_ALTERNATIVE,
        payload: res.data,
      });
      store.dispatch({
        type: GET_ALTERNATIVE_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    store.dispatch({
      type: GET_ALTERNATIVE_LOADING,
      payload: false,
    });
  }
}
//             update

export async function updateAltAction(id, data) {
  init();
  store.dispatch({
    type: UPDATE_ALTERNATIVE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: UPDATE_ALTERNATIVE,
    payload: {},
  });
  try {
    const res = await updateAlternative(id, data);


    if (res) {
      // store.dispatch({
      //   type: GET_ALTERNATIVE,
      //   payload: res.data,
      // });
      // store.dispatch({
      //   type: GET_ALTERNATIVE_LOADING,
      //   payload: false,
      // });
      successToast("Updated successfully");
    }
  } catch (error) {
    store.dispatch({
      type: UPDATE_ALTERNATIVE_LOADING,
      payload: false,
    });
  }
}

//     update alt
