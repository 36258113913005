import { combineReducers } from "redux";
import global from "./global";
import user from "./user";
import bom from "./bom";
import about from "./about";
import inventory from "./inventory";
import comments from "./comments";
import docs from "./docs"
const appReducer = combineReducers({
  global,
  inventory,
  bom,
  user,
  about,
  comments,
  docs,
});

const rootReducer = (state, action) => {
  if (action.type === "IS_LOGIN") {
    if (!action.payload) state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
