import {
  INVENTORY_ERROR,
  BOM_LIST,
  BOM_LIST_RAW,
  BOM_LIST_LOADING,
  BOM_DETAILS,
  BOM_DETAILS_LOADING,
  BOM_LINEITEMS,
  BOM_LINEITEMS_RAW,
  BOM_LINEITEMS_LOADING,
  BOM_FILES,
  BOM_FILES_LOADING,
  BOM_CLASSES,
  BOM_CLASSES_LOADING,
  ADD_BOM_ITEM,
  ADD_BOM_LOADING,
  ADD_BOM_FILE,
  ADD_BOM_FILE_LOADING,
  DELETE_BOM_FILE,
  DELETE_BOM_FILE_LOADING,
  GET_IMPORTS,
  GET_IMPORTS_LOADING,
  DOWNLOAD_BOM_FILE,
  DOWNLOAD_BOM_FILE_LOADING,
  ADD_IMPORT,
  ADD_IMPORT_LOADING,
  PRE_IMPORT,
  PRE_IMPORT_LOADING,
  GET_IMPORT,
  GET_IMPORT_LOADING,
  PENDING_ALTERNATIVE,
  PENDING_ALTERNATIVE_LOADING,
  ACCEPTED_ALTERNATIVE,
  ACCEPTED_ALTERNATIVE_LOADING,
  REJECTED_ALTERNATIVE,
  REJECTED_ALTERNATIVE_LOADING,
  ADDITION_ALTERNATIVE,
  ADDITION_ALTERNATIVE_LOADING,
  DELETION_ALTERNATIVE,
  DELETION_ALTERNATIVE_LOADING,
  TIME_SYNCED,
  TIME_SYNCED_LOADING,
  MANAGED_ALTS,
  MANAGED_ALTS_LOADING,
  BOM_COMMENT_LIST,
  BOM_COMMENT_LIST_RAW,
  BOM_COMMENT_LIST_LOADING,
  ADD_BOM_COMMENT_LOADING,
  ADD_BOM_COMMENT,
  IMPORT_STATUS,
  IMPORT_STATUS_LOADING,
  REV_BOM,
  REV_BOM_LOADING,
  NEW_BOM,
  NEW_BOM_LOADING,
  ERROR_COUNT,
  ERROR_COUNT_LOADING
} from "../helpers/constants";

export default function bom(
  state = {
    bomList: [],
    bomListRaw: {},
    bomListLoading: false,
    bomDetails: {},
    bomDetailsLoading: false,
    bomLineitems: [],
    bomLineitemsRaw: {},
    bomLineitemsLoading: false,
    bomFiles: [],
    bomFilesLoading: false,
    bomClasses: [],
    bomClassesLoading: false,
    addBom: {},
    addBomLoading: false,
    addBomFile: {},
    addBomFileLoading: false,
    deleteFile: {},
    deleteFileLoading: false,
    downloadFile: "",
    downloadFileLoading: false,
    getImports: [],
    getImportsLoading: false,
    preImport: [],
    preImportLoading: false,
    addImport: [],
    addImportLoading: false,
    importStatus: [],
    importStatusLoading: false,
    getImport: {},
    getImportLoading: false,
    managedAlts: {},
    managedAltsLoading: false,
    pendngAlt: [],
    pendngAltLoading: false,
    acceptedAlt: [],
    acceptedAltLoading: false,
    rejectedAlt: [],
    rejectedAltLoading: false,
    additionAlt: [],
    additionAltLoading: false,
    deletionAlt: [],
    deletionAltLoading: false,
    timeSynced: {},
    timeSyncedLoading: false,
    bomCommentsList: [],
    bomcommentsListRaw: {},
    bomCommentsListLoading: false,
    addBomComment: {},
    addBomCommentLoading: false,
    revId: {},
    revIdLoading: false,
    newBom: {},
    newBomLoading: false,
    errorCounts: {},
    errorCountsLoading: false,
    errorMessage: "",
  },
  action
) {
  switch (action.type) {
    case BOM_LIST: {
      return { ...state, bomList: action.payload };
    }
    case BOM_LIST_RAW: {
      return { ...state, bomListRaw: action.payload };
    }
    case BOM_LIST_LOADING: {
      return { ...state, bomListLoading: action.payload };
    }
    //
    case BOM_DETAILS: {
      return { ...state, bomDetails: action.payload };
    }
    case BOM_DETAILS_LOADING: {
      return { ...state, bomDetailsLoading: action.payload };
    }
    // new bom version
    case NEW_BOM: {
      return { ...state, newBom: action.payload };
    }
    case NEW_BOM_LOADING: {
      return { ...state, newBomLoading: action.payload };
    }
    //
    case BOM_LINEITEMS: {
      return { ...state, bomLineitems: action.payload };
    }
    case BOM_LINEITEMS_RAW: {
      return { ...state, bomLineitemsRaw: action.payload };
    }
    case BOM_LINEITEMS_LOADING: {
      return { ...state, bomLineitemsLoading: action.payload };
    }
    case ERROR_COUNT: {
      return { ...state, errorCounts: action.payload };
    }
    case ERROR_COUNT_LOADING: {
      return { ...state, errorCountsLoading: action.payload };
    }
    //
    case BOM_FILES: {
      return { ...state, bomFiles: action.payload };
    }
    case BOM_FILES_LOADING: {
      return { ...state, bomFilesLoading: action.payload };
    }
    //
    case BOM_CLASSES: {
      return { ...state, bomClasses: action.payload };
    }
    case BOM_CLASSES_LOADING: {
      return { ...state, bomClassesLoading: action.payload };
    }
    //               add bom

    case ADD_BOM_ITEM: {
      return { ...state, addBom: action.payload };
    }
    case ADD_BOM_LOADING: {
      return { ...state, addBomLoading: action.payload };
    }
    //               add file

    case ADD_BOM_FILE: {
      return { ...state, addBomFile: action.payload };
    }
    case ADD_BOM_FILE_LOADING: {
      return { ...state, addBomFileLoading: action.payload };
    }
    //               delete file

    case DELETE_BOM_FILE: {
      return { ...state, deleteFile: action.payload };
    }
    case DELETE_BOM_FILE_LOADING: {
      return { ...state, deleteFileLoading: action.payload };
    }
    //               download file

    case DOWNLOAD_BOM_FILE: {
      return { ...state, downloadFile: action.payload };
    }
    case DOWNLOAD_BOM_FILE_LOADING: {
      return { ...state, downloadFileLoading: action.payload };
    }
    //               get imports

    case GET_IMPORTS: {
      return { ...state, getImports: action.payload };
    }
    case GET_IMPORTS_LOADING: {
      return { ...state, getImportsLoading: action.payload };
    }
    //               preimport table

    case PRE_IMPORT: {
      return { ...state, preImport: action.payload };
    }
    case PRE_IMPORT_LOADING: {
      return { ...state, preImportLoading: action.payload };
    }
    //               add import

    case ADD_IMPORT: {
      return { ...state, addImport: action.payload };
    }
    case ADD_IMPORT_LOADING: {
      return { ...state, addImportLoading: action.payload };
    }
    //                import status

    case IMPORT_STATUS: {
      return { ...state, importStatus: action.payload };
    }
    case IMPORT_STATUS_LOADING: {
      return { ...state, importStatusLoading: action.payload };
    }
    //          get import details
    case GET_IMPORT: {
      return { ...state, getImport: action.payload };
    }
    case GET_IMPORT_LOADING: {
      return { ...state, getImportLoading: action.payload };
    }
    case MANAGED_ALTS: {
      return { ...state, managedAlts: action.payload };
    }
    case MANAGED_ALTS_LOADING: {
      return { ...state, managedAltsLoading: action.payload };
    }
    case PENDING_ALTERNATIVE: {
      return { ...state, pendingAlt: action.payload };
    }
    case PENDING_ALTERNATIVE_LOADING: {
      return { ...state, pendingAltLoading: action.payload };
    }
    case ACCEPTED_ALTERNATIVE: {
      return { ...state, acceptedAlt: action.payload };
    }
    case ACCEPTED_ALTERNATIVE_LOADING: {
      return { ...state, acceptedAltLoading: action.payload };
    }
    case REJECTED_ALTERNATIVE: {
      return { ...state, rejectedAlt: action.payload };
    }
    case REJECTED_ALTERNATIVE_LOADING: {
      return { ...state, rejectedAltLoading: action.payload };
    }
    case ADDITION_ALTERNATIVE: {
      return { ...state, additionAlt: action.payload };
    }
    case ADDITION_ALTERNATIVE_LOADING: {
      return { ...state, additionAltLoading: action.payload };
    }
    case DELETION_ALTERNATIVE: {
      return { ...state, deletionAlt: action.payload };
    }
    case DELETION_ALTERNATIVE_LOADING: {
      return { ...state, deletionAltLoading: action.payload };
    }
    case TIME_SYNCED: {
      return { ...state, timeSynced: action.payload };
    }
    case TIME_SYNCED_LOADING: {
      return { ...state, timeSyncedLoading: action.payload };
    }
    //
    //    bom rev update
    case REV_BOM: {
      return { ...state, revId: action.payload };
    }
    case REV_BOM_LOADING: {
      return { ...state, revIdLoading: action.payload };
    }
    //               comments
    case BOM_COMMENT_LIST: {
      return { ...state, bomCommentsList: action.payload };
    }
    case BOM_COMMENT_LIST_RAW: {
      return { ...state, bomcommentsListRaw: action.payload };
    }
    case BOM_COMMENT_LIST_LOADING: {
      return { ...state, bomCommentsListLoading: action.payload };
    }
    case ADD_BOM_COMMENT: {
      return { ...state, addBomComment: action.payload };
    }
    case ADD_BOM_COMMENT_LOADING: {
      return { ...state, addBomCommentLoading: action.payload };
    }
    //
    /////////////////////////////////////////////////////
    case INVENTORY_ERROR: {
      return { ...state, errorMessage: action.payload };
    }

    default: {
      if (action.type.indexOf("@@") === -1) {
      }
    }
  }
  return state;
}
///////////////////////////////////////////////////////////////////////////////////////////
