import { getGPNList } from "../../api/inventory/gpn-api";

let timeoutId = null;
export const loadOptions = (inputValue, callback) => {
  clearTimeout(timeoutId);

  timeoutId = setTimeout(async () => {
    const list = await getGPNList({
      request_content: { search_query: inputValue },
    });
    callback(
      list.data.gpn_list.map((el) => ({
        id: el.id,
        value: el.id,
        description: el.description,
        label: `[${el.group_pn}] ${el.description}`,
      }))
    );
  }, 1000);
};
