import React, { Component } from "react";

import qs from "qs";
import { Container, Row } from "react-bootstrap";
import { connect } from "react-redux";

import {
  deleteFileAction,
  getBOMClassesAction,
  getBOMfilesAction,
  getBOMLineItemsAction,
  ViewEditBom,
} from "../../actions/bom/bom-action";
import { getGPNListAction } from "../../actions/inventory/gpn-action";
import AddEditBom from "../../components/bom/add-edit-bom";
import LineItem from "../../components/bom/LineItemTable";
import Header from "../../components/header/header";
import { stringToInt, updateQueryParams } from "../../helpers/utility";
import store from "../../store";
import { useParams, withRouter } from "react-router-dom";
import { getBomfiles } from "../../api/bom/bom-api";

class EditBomContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gpnList: [],
      defaultGpn: "",
      title: "",
      active: false,
      productId: "",
      label: "",
      description: "",
      duration: "",
      files: [],
      lineItems: [],
      queryParams: {},
      requestContent: {
        search_query: "",
      },
    };
    this.csvLink = React.createRef();
    this.headerSearch = this.headerSearch.bind(this);
    this.getData = this.getData.bind(this);
  }

  // Delete a file then update the list of files in state
  handleDelete = (uuid) => {
    deleteFileAction(uuid);
    const id = window.location.pathname.split("/bom/")[1];
    setTimeout(() => {
      getBOMfilesAction(id);
    }, 2000);
  };
  componentDidMount() {
    const id = this.props.match.params.id;
    ViewEditBom(id);
    // getBOMLineItemsAction("e11e0507-e233-11ec-9f2e-0242c0a80002");
    // if (this.props.bomDetails && this.props.bomDetails.revisions !== 0) {
    //   setTimeout(() => {
    //     getBOMLineItemsAction(this.props.bomDetails.revisions[0].uuid);
    //   }, 2000);
    // }

    this.setState(
      {
        queryParams: stringToInt(
          qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        ),
      },
      () => {
        this.getData(this.state.queryParams);
      }
    );
    getBOMClassesAction();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.bomDetails) !==
      JSON.stringify(this.props.bomDetails)
    ) {
      this.setState({
        defaultGpn: `[${this.props.bomDetails.product_group_pn?.group_pn}]  ${this.props.bomDetails?.product_group_pn?.description}`,
      });
      this.setState({ title: this.props.bomDetails.name });
      this.setState({ active: this.props.bomDetails.active });

      this.setState({ productId: this.props.bomDetails.product_group_pn_id });
      this.setState({ label: this.props.bomDetails.label });

      this.setState({
        description:
          this.props.bomDetails &&
          this.props.bomDetails?.product_group_pn?.description,
      });
      this.setState({
        duration: this.props.bomDetails.estimated_build_duration_hours,
      });
    }
    if (
      JSON.stringify(prevProps.gpnList) !== JSON.stringify(this.props.gpnList)
    ) {
      this.setState({
        gpnList: this.props.gpnList,
      });
    }
    if (this.props.bomFiles !== prevProps.bomFiles) {
      this.setState({
        files: this.props.bomFiles,
      });
    }
    if (this.props.bomLineitems !== prevProps.bomLineitems) {
      this.setState({
        lineItems: this.props.bomLineitems,
      });
    }
  }
  headerSearch(searchQuery) {
    this.setState(
      {
        requestContent: {
          ...this.state.requestContent,
          search_query: searchQuery,
        },
      },
      () => {
        updateQueryParams(this.state.requestContent);
        this.getData(this.state.requestContent);
      }
    );
  }

  // get gpn list
  getData(requestContent = {}, type = "list") {
    requestContent = stringToInt(requestContent);
    // console.log("getData: ", requestContent);
    store.dispatch(
      getGPNListAction({
        request_content: { ...requestContent, items_per_page: 100 },
        request_type: type,
      })
    );
  }
  getLineItems(id) {
    getBOMLineItemsAction(id);
  }
  render() {
    const defaultClass = this.props.bomClasses.find(
      (el) => el.id == this.props.bomDetails.bom_class_id
    );
    const id = this.props.match.params.id;
    // console.log(this.props.bomDetails);
    return (
      <div>
        <Header />
        <Container>
          <AddEditBom
            bom_id={id}
            title={this.state.title}
            bomDescription={this.props.bomDetails?.description}
            bomLoading={this.props.bomDetailsLoading}
            search={this.headerSearch}
            queryParams={this.state.queryParams}
            product={this.state.gpnList}
            defaultGpn={this.state.defaultGpn}
            defaultGpnId={this.state.productId}
            status={this.state.active}
            label={this.state.label}
            defaultClass={defaultClass}
            bomClasses={this.props.bomClasses}
            desc={this.state.description}
            duration={this.state.duration}
            files={this.state.files}
            filesLoading={this.props.bomFilesLoading}
            addFileLoading={this.props.addBomFileLoading}
            deleteFile={this.handleDelete}
            validation={this.props.bomDetails.engineering_validation}
            approval={this.props.bomDetails.manufacturing_approval}
            lineItems={this.props.bomLineitems}
            listRaw={this.props.bomLineitemsRaw}
            lineitemsLoading={this.props.bomLineitemsLoading}
            revisions={this.props.bomDetails.revisions}
            revisionId={this.getLineItems}
          />
        </Container>
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    gpnList: state.inventory.gpnList,
    gpnListRaw: state.inventory.gpnListRaw,
    bomDetails: state.bom.bomDetails,
    bomDetailsLoading: state.bom.bomDetailsLoading,
    bomClasses: state.bom.bomClasses,
    bomClassesLoading: state.bom.bomClassesLoading,
    bomLineitems: state.bom.bomLineitems,
    bomLineitemsRaw: state.bom.bomLineitemsRaw,
    bomLineitemsLoading: state.bom.bomLineitemsLoading,
    bomFiles: state.bom.bomFiles,
    bomFilesLoading: state.bom.bomFilesLoading,
    addBomFile: state.bom.addBomFile,
    addBomFileLoading: state.bom.addBomFileLoading,
  };
};

export default connect(stateMap)(EditBomContainer);
