import {
  ADD_GPN_ITEM,
  ADD_GPN_LOADING,
  ALTERNATIVE_LIST,
  ALTERNATIVE_LIST_LOADING,
  ALTERNATIVE_LIST_RAW,
  ALTERNATIVE_CLASSES,
  ALTERNATIVE_CLASSES_LOADING,
  GET_ALTERNATIVE,
  GET_ALTERNATIVE_LOADING,
  BULK_ITEM_LOOKUP,
  BULK_ITEM_LOOKUP_LOADING,
  EDIT_GPN_ITEM,
  EDIT_GPN_LOADING,
  GPN_BOM_USAGE,
  GPN_BOM_USAGE_LOADING,
  GPN_BY_ID,
  GPN_BY_ID_LOADING,
  GPN_HISTORY,
  GPN_HISTORY_LOADING,
  GPN_LIST,
  GPN_LIST_LOADING,
  GPN_LIST_RAW,
  GPN_MPN_MEMBERS,
  GPN_MPN_MEMBERS_LOADING,
  GPN_SIMILAR,
  GPN_SIMILAR_LOADING,
  GPN_STOCK_LOCATIONS,
  GPN_STOCK_LOCATIONS_LOADING,
  GPN_SUGGEST,
  GPN_SUGGEST_LOADING,
  GPN_TOTAL_STOCK,
  GPN_TOTAL_STOCK_LOADING,
  INVENTORY_ERROR,
  MPN_AUTOCOMPLETE,
  MPN_AUTOCOMPLETE_LOADING,
  MPN_BOM_USAGE,
  MPN_BOM_USAGE_LOADING,
  MPN_BOM_USAGE_SIMILAR,
  MPN_BOM_USAGE_SIMILAR_LOADING,
  MPN_BY_ID,
  MPN_BY_ID_LOADING,
  MPN_GPN_MEMBERS,
  MPN_GPN_MEMBERS_LOADING,
  MPN_HISTORY,
  MPN_HISTORY_LOADING,
  MPN_ITEM_ADD,
  MPN_ITEM_ADD_LOADING,
  MPN_ITEM_EDIT,
  MPN_ITEM_EDIT_LOADING,
  MPN_LIST,
  MPN_LIST_LOADING,
  MPN_LIST_RAW,
  MPN_PARAM_SUGGESTIONS,
  MPN_PARAM_SUGGESTIONS_LOADING,
  MPN_WITH_PARAMETERS,
  MPN_WITH_PARAMETERS_LOADING,
  PART_TYPE,
  PART_TYPE_LOADING,
  GPN_AUTOCOMPLETE,
  GPN_AUTOCOMPLETE_LOADING,
  GPN_FILES,
  GPN_FILES_LOADING,
  ADD_GPN_FILE,
  ADD_GPN_FILE_LOADING,
  MPN_FILES,
  MPN_FILES_LOADING,
  ADD_MPN_FILE,
  ADD_MPN_FILE_LOADING,
  MPN_AUTOCOMPLETE_RAW,
  ALT_TO,
  ALT_TO_LOADING,
  ALT_FROM,
  ALT_FROM_LOADING,
  MPN_STOCK,
  MPN_STOCK_LOADING,
} from "../helpers/constants";

export default function inventory(
  state = {
    gpnList: [],
    gpnListLoading: false,
    gpnListRaw: {},
    mpnList: [],
    mpnListLoading: false,
    mpnListRaw: {},
    //
    gpnbyId: {},
    gpnbyIdLoading: false,
    mpnMembers: [],
    mpnMembersLoading: false,
    stockLocations: [],
    stockLocationsLoading: false,
    totalstock: {},
    totalstockLoading: false,
    bomusage: [],
    bomusageLoading: false,
    history: [],
    historyLoading: false,
    alternative_to: [],
    alternative_toLoading: false,
    alternative_from: [],
    alternative_fromLoading: false,
    gpnautoComplete: [],
    gpnautoCompleteLoading: false,
    //
    mpnbyId: {},
    mpnbyIdLoading: false,
    gpnMembers: [],
    gpnMembersLoading: false,
    parameters: [],
    parametersLoading: false,
    mpnbomusage: [],
    mpnbomusageLoading: false,
    bomusageSimilar: [],
    bomusageSimilarLoading: false,
    mpnhistory: [],
    mpnhistoryLoading: false,
    mpnStock: {},
    mpnStockLoading: false,
    addMpn: {},
    addMpnLoading: false,
    editMpn: {},
    editMpnLoading: false,
    addGpn: {},
    addGpnLoading: false,
    editGpn: {},
    editGpnLoading: false,
    //
    altClasses: [],
    altClassesLoading: false,
    alternativeList: [],
    alternativeListLoading: false,
    alternativeListRaw: {},
    getAlt: {},
    getAltLoading: false,
    //
    gpnFiles: [],
    gpnFilesLoading: false,
    addGpnFile: {},
    addGpnFileLoading: false,
    mpnFiles: [],
    mpnFilesLoading: false,
    addMpnFile: {},
    addMpnFileLoading: false,

    //
    errorMessage: "",
    partType: [],
    partTypeLoading: false,
    similargpn: [],
    similargpnLoading: false,
    suggestmpn: [],
    suggestmpnLoading: false,
    mpnAutocompleteRaw: [],

    // For loading octopart suggestions
    paramSuggestions: {},
    paramSuggestionsLoading: false,
    bulkItemLookup: {},
    bulkItemLookupLoading: false,
  },
  action
) {
  switch (action.type) {
    case BULK_ITEM_LOOKUP_LOADING: {
      return { ...state, bulkItemLookupLoading: action.payload };
    }
    case BULK_ITEM_LOOKUP: {
      return { ...state, bulkItemLookup: action.payload };
    }
    case GPN_LIST: {
      return { ...state, gpnList: action.payload };
    }
    case GPN_LIST_RAW: {
      return { ...state, gpnListRaw: action.payload };
    }
    case GPN_LIST_LOADING: {
      return { ...state, gpnListLoading: action.payload };
    }
    case MPN_LIST: {
      return { ...state, mpnList: action.payload };
    }
    case MPN_LIST_RAW: {
      return { ...state, mpnListRaw: action.payload };
    }
    case MPN_LIST_LOADING: {
      return { ...state, mpnListLoading: action.payload };
    }
    case ALTERNATIVE_CLASSES: {
      return { ...state, altClasses: action.payload };
    }
    case ALTERNATIVE_CLASSES_LOADING: {
      return { ...state, altClassesLoading: action.payload };
    }
    case ALTERNATIVE_LIST: {
      return { ...state, alternativeList: action.payload };
    }
    case ALTERNATIVE_LIST_RAW: {
      return { ...state, alternativeListRaw: action.payload };
    }
    case ALTERNATIVE_LIST_LOADING: {
      return { ...state, alternativeListLoading: action.payload };
    }
    case GET_ALTERNATIVE: {
      return { ...state, getAlt: action.payload };
    }
    case GET_ALTERNATIVE_LOADING: {
      return { ...state, getAltLoading: action.payload };
    }
    //          Get single gpn details
    case GPN_BY_ID: {
      return { ...state, gpnbyId: action.payload };
    }
    case GPN_BY_ID_LOADING: {
      return { ...state, gpnbyIdLoading: action.payload };
    }
    // mpn members
    case GPN_MPN_MEMBERS: {
      return { ...state, mpnMembers: action.payload };
    }
    case GPN_MPN_MEMBERS_LOADING: {
      return { ...state, mpnMembersLoading: action.payload };
    }
    // stock locations
    case GPN_STOCK_LOCATIONS: {
      return { ...state, stockLocations: action.payload };
    }
    case GPN_STOCK_LOCATIONS_LOADING: {
      return { ...state, stockLocationsLoading: action.payload };
    }
    // total stock
    case GPN_TOTAL_STOCK: {
      return { ...state, totalstock: action.payload };
    }
    case GPN_TOTAL_STOCK_LOADING: {
      return { ...state, totalstockLoading: action.payload };
    }
    // bom usage
    case GPN_BOM_USAGE: {
      return { ...state, bomusage: action.payload };
    }
    case GPN_BOM_USAGE_LOADING: {
      return { ...state, bomusageLoading: action.payload };
    }
    // history
    case GPN_HISTORY: {
      return { ...state, history: action.payload };
    }
    case GPN_HISTORY_LOADING: {
      return { ...state, historyLoading: action.payload };
    }
    // ALT TO
    case ALT_TO: {
      return { ...state, alternative_to: action.payload };
    }
    case ALT_TO_LOADING: {
      return { ...state, alternative_toLoading: action.payload };
    }
    // ALT FROM
    case ALT_FROM: {
      return { ...state, alternative_from: action.payload };
    }
    case ALT_FROM_LOADING: {
      return { ...state, alternative_fromLoading: action.payload };
    }
    // Auto Complete
    case GPN_AUTOCOMPLETE: {
      return { ...state, gpnautoComplete: action.payload };
    }
    case GPN_AUTOCOMPLETE_LOADING: {
      return { ...state, gpnautoCompleteLoading: action.payload };
    }

    case GPN_FILES: {
      return { ...state, gpnFiles: action.payload };
    }
    case GPN_FILES_LOADING: {
      return { ...state, gpnFilesLoading: action.payload };
    }

    case ADD_GPN_FILE: {
      return { ...state, addGpnFile: action.payload };
    }
    case ADD_GPN_FILE_LOADING: {
      return { ...state, addGpnFileLoading: action.payload };
    }
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //          Get single mpn details

    case MPN_BY_ID: {
      return { ...state, mpnbyId: action.payload };
    }
    case MPN_BY_ID_LOADING: {
      return { ...state, mpnbyIdLoading: action.payload };
    }
    // gpn members
    case MPN_GPN_MEMBERS: {
      return { ...state, gpnMembers: action.payload };
    }
    case MPN_GPN_MEMBERS_LOADING: {
      return { ...state, gpnMembersLoading: action.payload };
    }
    // parameters
    case MPN_WITH_PARAMETERS: {
      return { ...state, parameters: action.payload };
    }
    case MPN_WITH_PARAMETERS_LOADING: {
      return { ...state, parametersLoading: action.payload };
    }
    // mpn bom usage
    case MPN_BOM_USAGE: {
      return { ...state, mpnbomusage: action.payload };
    }
    case MPN_BOM_USAGE_LOADING: {
      return { ...state, mpnbomusageLoading: action.payload };
    }
    // bom usage similar
    case MPN_BOM_USAGE_SIMILAR: {
      return { ...state, bomusageSimilar: action.payload };
    }
    case MPN_BOM_USAGE_SIMILAR_LOADING: {
      return { ...state, bomusageSimilarLoading: action.payload };
    }
    // mpn history
    case MPN_HISTORY: {
      return { ...state, mpnhistory: action.payload };
    }
    case MPN_HISTORY_LOADING: {
      return { ...state, mpnhistoryLoading: action.payload };
    }
     // mpn stock
     case MPN_STOCK: {
      return { ...state, mpnStock: action.payload };
    }
    case MPN_STOCK_LOADING: {
      return { ...state, mpnStockLoading: action.payload };
    }

    // Add GPN
    case MPN_ITEM_ADD: {
      return { ...state, addMpn: action.payload };
    }
    case MPN_ITEM_ADD_LOADING: {
      return { ...state, addMpnLoading: action.payload };
    }
    // Files
    case MPN_FILES: {
      return { ...state, mpnFiles: action.payload };
    }
    case MPN_FILES_LOADING: {
      return { ...state, mpnFilesLoading: action.payload };
    }

    case ADD_MPN_FILE: {
      return { ...state, addMpnFile: action.payload };
    }
    case ADD_MPN_FILE_LOADING: {
      return { ...state, addMpnFileLoading: action.payload };
    }
    // Edit MPN
    case MPN_ITEM_EDIT: {
      return { ...state, editMpn: action.payload };
    }
    case MPN_ITEM_EDIT_LOADING:
      {
        return { ...state, editMpnLoading: action.payload };
      }

      a; // MPN autocomplete, add result to dictionary
    case MPN_AUTOCOMPLETE: {
      const suggestions = action.payload?.suggestions;
      if (suggestions == null) return { ...state };

      let autocomplete = { ...state.mpnAutocomplete };
      autocomplete[action.fieldName] = suggestions;
      return { ...state, mpnAutocomplete: autocomplete };
    }
    case MPN_AUTOCOMPLETE_RAW: {
      return { ...state, mpnAutocompleteRaw: action.payload };
    }
    case MPN_AUTOCOMPLETE_LOADING: {
      return { ...state, mpnAutocompleteLoading: action.payload };
    }

    //////////////////////////////////////////////////////////
    // MPN parameter suggestions from octopart

    case MPN_PARAM_SUGGESTIONS: {
      const paramSuggestions = action.payload?.field_suggestions;
      if (paramSuggestions == null) return { ...state };

      return { ...state, paramSuggestions: paramSuggestions };
    }

    case MPN_PARAM_SUGGESTIONS_LOADING: {
      return { ...state, paramSuggestionsLoading: action.payload };
    }

    //////////////////////////////////////////////////////////
    //               Get gpn partType

    case PART_TYPE: {
      return { ...state, partType: action.payload };
    }
    case PART_TYPE_LOADING: {
      return { ...state, partTypeLoading: action.payload };
    }

    //////////////////////////////////////////////////////////
    //               Get similar gpn

    case GPN_SIMILAR: {
      return { ...state, similargpn: action.payload };
    }
    case GPN_SIMILAR_LOADING: {
      return { ...state, similargpnLoading: action.payload };
    }

    //////////////////////////////////////////////////////////
    //               Get suggested gpn

    case GPN_SUGGEST: {
      return { ...state, suggestmpn: action.payload };
    }
    case GPN_SUGGEST_LOADING: {
      return { ...state, suggestmpnLoading: action.payload };
    }
    //////////////////////////////////////////////////////////
    //               add gpn

    case ADD_GPN_ITEM: {
      return { ...state, addGpn: action.payload };
    }
    case ADD_GPN_LOADING: {
      return { ...state, addGpnLoading: action.payload };
    }
    ///////////////////////////////////////////////////////
    //               edit gpn

    case EDIT_GPN_ITEM: {
      return { ...state, editGpn: action.payload };
    }
    case EDIT_GPN_LOADING: {
      return { ...state, editGpnLoading: action.payload };
    }
    /////////////////////////////////////////////////////
    case INVENTORY_ERROR: {
      return { ...state, errorMessage: action.payload };
    }

    default: {
      if (action.type.indexOf("@@") === -1) {
      }
    }
  }
  return state;
}
///////////////////////////////////////////////////////////////////////////////////////////
