import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import Loader from "../shared/loader/loader";
import Loading from "../shared/loader/spinner";
import "./sidebar.scss";
const options = [
  {
    name: "Enable both scrolling & backdrop",
    scroll: true,
    backdrop: true,
  },
];
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRow: "",
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.row !== this.props.row) {
      this.setState({ activeRow: this.props.row });
    }
  }

  render() {
    return (
      <>
        {options.map((props, idx) => (
          <Fragment key={idx}>
            {/* <Button variant="primary" onClick={toggleShow} className="me-2">
                  Sidebar
              </Button> */}
            <div
              className={`sidebar-section ${
                this.state.activeRow !== "" ? "show-sidebar" : ""
              }`}
            >
              <Offcanvas.Body>
                <div className="header-section">
                  <h2 className="header-heading d-flex align-items-center justify-content-between">
                    {this.props.module6Loading ? (
                      <Loading />
                    ) : (
                      this.props.module6
                    )}
                    <button
                      type="button"
                      class="btn-close"
                      onClick={() => this.setState({ activeRow: "" })}
                    ></button>
                  </h2>
                  <div>
                    <p className="mb-0">
                      {this.props.module7 && this.props.module7}
                    </p>
                    {window.location.pathname === "/gpn" ? (
                      this.props.module5Loading ? (
                        <Loading />
                      ) : (
                        `${this.props.module5.available_stock} available, ${this.props.module5.total_stock} total stock`
                      )
                    ) : (
                      ""
                    )}
                    {window.location.pathname === "/mpn" ? (
                      this.props.module8Loading ? (
                        <Loading />
                      ) : (
                        <>
                          Identical stock:{" "}
                          <b>
                            {this.props.module8.identical_stock_total}{" "}
                            {`(${this.props.module8.identical_stock_available} available)`}
                          </b>
                          <br />
                          General stock:{" "}
                          <b>
                            {this.props.module8.general_stock_total}{" "}
                            {`(${this.props.module8.general_stock_available} available)`}
                          </b>
                        </>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="sidebar-body pt-3">
                  <Accordion>
                    {this.props.module1Loading ? (
                      <div className="text-center py-3">
                        <Loading />
                      </div>
                    ) : (
                      <Accordion.Item eventKey="0" className="blue-border">
                        <Accordion.Header>
                          {this.props.module1name}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="table-section">
                            <Table striped responsive variant="dark">
                              <thead>
                                <tr>
                                  {this.props.module1Headings.map((el, ind) => {
                                    return <th key={ind}>{el.label}</th>;
                                  })}
                                </tr>
                              </thead>

                              {this.props.module1Loading ? (
                                <div className="text-center py-3">
                                  <Loading />
                                </div>
                              ) : (
                                <tbody>
                                  {this.props.module1.map((elem, index) => {
                                    return (
                                      <tr key={index}>
                                        {Object.values(elem).map((el, ind) => {
                                          return <td key={ind}>{el}</td>;
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              )}
                            </Table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    {this.props.module2Loading ? (
                      <div className="text-center py-3">
                        <Loading />
                      </div>
                    ) : (
                      <Accordion.Item eventKey="1" className="mt-4 blue-border">
                        <Accordion.Header>
                          {this.props.module2name}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="table-section">
                            <Table striped responsive variant="dark">
                              <thead>
                                <tr>
                                  {this.props.module2Headings.map((el, ind) => {
                                    return <th key={ind}>{el.label}</th>;
                                  })}
                                </tr>
                              </thead>
                              {this.props.module2Loading ? (
                                "Loading"
                              ) : (
                                <tbody>
                                  {this.props.module2.map((elem, index) => {
                                    return (
                                      <tr key={index}>
                                        {Object.values(elem).map((el, ind) => {
                                          return <td key={ind}>{el}</td>;
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              )}
                            </Table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    {this.props.module3Loading ? (
                      <div className="text-center py-3">
                        <Loading />
                      </div>
                    ) : (
                      <Accordion.Item eventKey="2" className="mt-4 blue-border">
                        <Accordion.Header>
                          {this.props.module3name}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="table-section">
                            <Table striped responsive variant="dark">
                              <thead>
                                <tr>
                                  {this.props.module3Headings.map((el, ind) => {
                                    return <th key={ind}>{el.label}</th>;
                                  })}
                                </tr>
                              </thead>
                              {this.props.module3Loading ? (
                                "Loading"
                              ) : (
                                <tbody>
                                  {this.props.module3.map((elem, index) => {
                                    return (
                                      <tr key={index}>
                                        {Object.values(elem).map((el, ind) => {
                                          return <td key={ind}>{el}</td>;
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              )}
                            </Table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    {this.props.module4Loading ? (
                      <div className="text-center py-3">
                        <Loading />
                      </div>
                    ) : (
                      <Accordion.Item eventKey="3" className="mt-4 blue-border">
                        <Accordion.Header>
                          {this.props.module4name}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="table-section">
                            <Table striped responsive variant="dark">
                              <thead>
                                <tr>
                                  {this.props.module4Headings.map((el, ind) => {
                                    return <th key={ind}>{el.label}</th>;
                                  })}
                                </tr>
                              </thead>
                              {this.props.module4Loading ? (
                                <Loading />
                              ) : (
                                <tbody>
                                  {this.props.module4.map((elem, index) => {
                                    return (
                                      <tr key={index}>
                                        {Object.values(elem).map((el, ind) => {
                                          return <td key={ind}>{el}</td>;
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              )}
                            </Table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}

                    {/*             6th                */}

                    {window.location.pathname === "/mpn" &&
                      (this.props.module5Loading ? (
                        <div className="text-center py-3">
                          <Loading />
                        </div>
                      ) : (
                        <Accordion.Item
                          eventKey="4"
                          className="mt-4 blue-border"
                        >
                          <Accordion.Header>
                            {this.props.module5name}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="table-section">
                              <Table striped responsive variant="dark">
                                <thead>
                                  <tr>
                                    {this.props.module5Headings.map(
                                      (el, ind) => {
                                        return <th key={ind}>{el.label}</th>;
                                      }
                                    )}
                                  </tr>
                                </thead>
                                {this.props.module5Loading ? (
                                  <Loading />
                                ) : (
                                  <tbody>
                                    {this.props.module5.map((elem, index) => {
                                      return (
                                        <tr key={index}>
                                          {Object.values(elem).map(
                                            (el, ind) => {
                                              return <td key={ind}>{el}</td>;
                                            }
                                          )}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                )}
                              </Table>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}

                    {/*             6th           */}
                  </Accordion>
                </div>
              </Offcanvas.Body>
            </div>
          </Fragment>
        ))}
      </>
    );
  }
}

export default Sidebar;
