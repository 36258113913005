// routes constants
// export const TO_HOME = '/'
export const TO_GPN = "/gpn";
export const ADD_GPN = "/gpn/add";
export const EDIT_GPN = "/gpn/:id";
export const GET_GPN = "/gpn/:id";
export const TO_MPN = "/mpn";
export const TO_BULK = "/bulk";
export const TO_ALTER = "/alternatives";
export const EDIT_ALTER = "/alternatives/:id";
export const ADD_ALTER = "/alternatives/add";
export const ADD_MPN = "/mpn/add";
export const GET_MPN = "/mpn/:id";
export const EDIT_MPN = "/mpn/:id";
export const TO_BOM = "/bom";
export const ADD_BOM = "/bom/add";
export const EDIT_BOM = "/bom/:id";
export const IMPORT_BOM = "/bom/:id/import/add";
export const IMPORTS_BOM = "/bom/:id/tasks";
export const IMPORT_DETAILS = "/bom/import/:id";
export const TO_ADMIN = "/admin";
export const TO_ABOUT = "/about";
export const TO_PART_TYPE = "/parttypes";
export const DOCS = "/docs/:page_key"

export const NOT_FOUND = "/404";

//action constants
export const IS_LOGIN = "IS_LOGIN";
export const LOGIN_RES = "LOGIN_RES";
export const LOGIN_MESSAGE = "LOGIN_MESSAGE";
export const REFRESH_ERROR = "REFRESH_ERROR";
export const IN_PROCESS = "IN_PROCESS";
export const CONFIRM_MESSAGE = "CONFIRM_MESSAGE";
export const GPN_LIST = "GPN_LIST";
export const GPN_LIST_RAW = "GPN_LIST_RAW";
export const GPN_LIST_LOADING = "GPN_LIST_LOADING";
export const MPN_LIST = "MPN_LIST";
export const MPN_LIST_RAW = "MPN_LIST_RAW";
export const MPN_LIST_LOADING = "MPN_LIST_LOADING";
export const ALTERNATIVE_LIST = "ALTERNATIVE_LIST";
export const ALTERNATIVE_LIST_RAW = "ALTERNATIVE_LIST_RAW";
export const ALTERNATIVE_LIST_LOADING = "ALTERNATIVE_LIST_LOADING";
export const ALTERNATIVE_CLASSES = "ALTERNATIVE_CLASSES";
export const ALTERNATIVE_CLASSES_LOADING = "ALTERNATIVE_CLASSES_LOADING";
export const CREATE_ALTERNATIVE = "CREATE_ALTERNATIVE";
export const CREATE_ALTERNATIVE_LOADING = "CREATE_ALTERNATIVE_LOADING";
export const GET_ALTERNATIVE = "GET_ALTERNATIVE";
export const GET_ALTERNATIVE_LOADING = "GET_ALTERNATIVE_LOADING";
export const UPDATE_ALTERNATIVE = "UPDATE_ALTERNATIVE";
export const UPDATE_ALTERNATIVE_LOADING = "UPDATE_ALTERNATIVE_LOADING";
export const PENDING_ALTERNATIVE = "PENDING_ALTERNATIVE";
export const PENDING_ALTERNATIVE_LOADING = "PENDING_ALTERNATIVE_LOADING";
export const ACCEPTED_ALTERNATIVE = "ACCEPTED_ALTERNATIVE";
export const ACCEPTED_ALTERNATIVE_LOADING = "ACCEPTED_ALTERNATIVE_LOADING";
export const REJECTED_ALTERNATIVE = "REJECTED_ALTERNATIVE";
export const REJECTED_ALTERNATIVE_LOADING = "REJECTED_ALTERNATIVE_LOADING";
export const ADDITION_ALTERNATIVE = "ADDITION_ALTERNATIVE";
export const ADDITION_ALTERNATIVE_LOADING = "ADDITION_ALTERNATIVE_LOADING";
export const DELETION_ALTERNATIVE = "DELETION_ALTERNATIVE";
export const DELETION_ALTERNATIVE_LOADING = "DELETION_ALTERNATIVE_LOADING";
export const ADD_MANAGE_ALTERNATIVE = "ADD_MANAGE_ALTERNATIVE";
export const ADD_MANAGE_ALTERNATIVE_LOADING = "ADD_MANAGE_ALTERNATIVE_LOADING";
export const UPDATE_STATUS_ALTERNATIVE = "UPDATE_STATUS_ALTERNATIVE";
export const UPDATE_STATUS_ALTERNATIVE_LOADING = "UPDATE_STATUS_ALTERNATIVE_LOADING";
export const TIME_SYNCED = "TIME_SYNCED";
export const TIME_SYNCED_LOADING = "TIME_SYNCED_LOADING";
export const SYNC_BOM = "SYNC_BOM";
export const SYNC_BOM_LOADING = "SYNC_BOM_LOADING";
export const MANAGED_ALTS = "MANAGED_ALTS";
export const MANAGED_ALTS_LOADING = "MANAGED_ALTS_LOADING";


export const INVENTORY_ERROR = "INVENTORY_ERROR";

//gpn constants for single gpn details
export const GPN_BY_ID = "GPN_BY_ID";
export const GPN_BY_ID_LOADING = "GPN_BY_ID_LOADING";

export const GPN_MPN_MEMBERS = "GPN_MPN_MEMBERS";
export const GPN_MPN_MEMBERS_LOADING = "GPN_MPN_MEMBERS_LOADING";

export const GPN_STOCK_LOCATIONS = "GPN_STOCK_LOCATIONS";
export const GPN_STOCK_LOCATIONS_LOADING = "GPN_STOCK_LOCATIONS_LOADING";

export const GPN_TOTAL_STOCK = "GPN_TOTAL_STOCK";
export const GPN_TOTAL_STOCK_LOADING = "GPN_TOTAL_STOCK_LOADING";

export const GPN_BOM_USAGE = "GPN_BOM_USAGE";
export const GPN_BOM_USAGE_LOADING = "GPN_BOM_USAGE_LOADING";

export const GPN_HISTORY = "GPN_HISTORY";
export const GPN_HISTORY_LOADING = "GPN_HISTORY_LOADING";

// mpn constants for single mpn details
export const MPN_BY_ID = "MPN_BY_ID";
export const MPN_BY_ID_LOADING = "MPN_BY_ID_LOADING";

export const MPN_GPN_MEMBERS = "MPN_GPN_MEMBERS";
export const MPN_GPN_MEMBERS_LOADING = "MPN_GPN_MEMBERS_LOADING";

export const MPN_WITH_PARAMETERS = "MPN_WITH_PARAMETERS";
export const MPN_WITH_PARAMETERS_LOADING = "MPN_WITH_PARAMETERS_LOADING";

export const MPN_BOM_USAGE = "MPN_BOM_USAGE";
export const MPN_BOM_USAGE_LOADING = "MPN_BOM_USAGE_LOADING";

export const MPN_BOM_USAGE_SIMILAR = "MPN_BOM_USAGE_SIMILAR";
export const MPN_BOM_USAGE_SIMILAR_LOADING = "MPN_BOM_USAGE_SIMILAR_LOADING";

export const MPN_HISTORY = "MPN_HISTORY";
export const MPN_HISTORY_LOADING = "MPN_HISTORY_LOADING";

export const MPN_STOCK = "MPN_STOCK";
export const MPN_STOCK_LOADING = "MPN_STOCK_LOADING";

export const ALT_TO = "ALT_TO";
export const ALT_TO_LOADING = "ALT_TO_LOADING";
export const ALT_FROM = "ALT_FROM";
export const ALT_FROM_LOADING = "ALT_FROM_LOADING";

export const MPN_ITEM_ADD = "MPN_ITEM_ADD";
export const MPN_ITEM_ADD_LOADING = "MPN_ITEM_ADD_LOADING";

export const MPN_ITEM_EDIT = "MPN_ITEM_EDIT";
export const MPN_ITEM_EDIT_LOADING = "MPN_ITEM_EDIT_LOADING";

export const MPN_AUTOCOMPLETE = "MPN_AUTOCOMPLETE";
export const MPN_AUTOCOMPLETE_RAW = "MPN_AUTOCOMPLETE_RAW";
export const MPN_AUTOCOMPLETE_LOADING = "MPN_AUTOCOMPLETE_LOADING";

export const MPN_PARAM_SUGGESTIONS = "MPN_PARAM_SUGGESTIONS";
export const MPN_PARAM_SUGGESTIONS_LOADING = "MPN_PARAM_SUGGESTIONS_LOADING";

export const DEACTIVATE_MPN = "DEACTIVATE_MPN";
export const DEACTIVATE_MPN_LOADING = "DEACTIVATE_MPN_LOADING";
export const ACTIVATE_MPN = "ACTIVATE_MPN";
export const ACTIVATE_MPN_LOADING = "ACTIVATE_MPN_LOADING";

//           add gpn constants
export const PART_TYPE = "PART_TYPE";
export const PART_TYPE_LOADING = "PART_TYPE_LOADING";

export const GPN_SIMILAR = "GPN_SIMILAR";
export const GPN_SIMILAR_LOADING = "GPN_SIMILAR_LOADING";

export const GPN_SUGGEST = "GPN_SUGGEST";
export const GPN_SUGGEST_LOADING = "GPN_SUGGEST_LOADING";

export const ADD_GPN_ITEM = "ADD_GPN_ITEM";
export const ADD_GPN_LOADING = "ADD_GPN_LOADING";

export const EDIT_GPN_ITEM = "EDIT_GPN_ITEM";
export const EDIT_GPN_LOADING = "EDIT_GPN_LOADING";

export const DEACTIVATE_GPN = "DEACTIVATE_GPN";
export const DEACTIVATE_GPN_LOADING = "DEACTIVATE_GPN_LOADING";
export const ACTIVATE_GPN = "ACTIVATE_GPN";
export const ACTIVATE_GPN_LOADING = "ACTIVATE_GPN_LOADING";

export const GPN_AUTOCOMPLETE = "GPN_AUTOCOMPLETE";
export const GPN_AUTOCOMPLETE_LOADING = "GPN_AUTOCOMPLETE_LOADING";

//           Bulk constants

export const ADD_BULK_ITEM = "ADD_BULK_ITEM";
export const ADD_BULK_ITEM_LOADING = "ADD_BULK_ITEM_LOADING";

//            bom constants
export const BOM_LIST = "BOM_LIST";
export const BOM_LIST_RAW = "BOM_LIST_RAW";
export const BOM_LIST_LOADING = "BOM_LIST_LOADING";
export const BOM_DETAILS = "BOM_DETAILS";
export const BOM_DETAILS_LOADING = "BOM_DETAILS_LOADING";
export const BOM_LINEITEMS = "BOM_LINEITEMS";
export const BOM_LINEITEMS_RAW = "BOM_LINEITEMS_RAW";
export const BOM_LINEITEMS_LOADING = "BOM_LINEITEMS_LOADING";

export const DELETE_LINEITEMS = "DELETE_LINEITEMS";
export const DELETE_LINEITEMS_LOADING = "DELETE_LINEITEMS_LOADING";
export const DELETE_ALL_LINEITEMS = "DELETE_ALL_LINEITEMS";
export const DELETE_ALL_LINEITEMS_LOADING = "DELETE_ALL_LINEITEMS_LOADING";

export const ERROR_COUNT = "ERROR_COUNT";
export const ERROR_COUNT_LOADING = "ERROR_COUNT_LOADING";

export const BOM_CLASSES = "BOM_CLASSES";
export const BOM_CLASSES_LOADING = "BOM_CLASSES_LOADING";
export const ADD_BOM_ITEM = "ADD_BOM_ITEM";
export const ADD_BOM_LOADING = "ADD_BOM_LOADING";
export const PRE_IMPORT = "PRE_IMPORT";
export const PRE_IMPORT_LOADING = "PRE_IMPORT_LOADING";
export const GET_IMPORTS = "GET_IMPORTS";
export const GET_IMPORTS_LOADING = "GET_IMPORTS_LOADING";
export const ADD_IMPORT = "ADD_IMPORT";
export const ADD_IMPORT_LOADING = "ADD_IMPORT_LOADING";
export const GET_IMPORT = "GET_IMPORT";
export const GET_IMPORT_LOADING = "GET_IMPORT_LOADING";
export const DELETE_IMPORT = "DELETE_IMPORT";
export const DELETE_IMPORT_LOADING = "DELETE_IMPORT_LOADING";
export const VALIDATE_IMPORT = "VALIDATE_IMPORT";
export const VALIDATE_IMPORT_LOADING = "VALIDATE_IMPORT_LOADING";
export const EXECUTE_IMPORT = "EXECUTE_IMPORT";
export const EXECUTE_IMPORT_LOADING = "EXECUTE_IMPORT_LOADING";
export const IMPORT_STATUS = "IMPORT_STATUS";
export const IMPORT_STATUS_LOADING = "IMPORT_STATUS_LOADING";
export const NEW_BOM = "NEW_BOM";
export const NEW_BOM_LOADING = "NEW_BOM_LOADING";
export const REV_BOM = "REV_BOM";
export const REV_BOM_LOADING = "REV_BOM_LOADING";

//bulk
export const BULK_ITEM_LOOKUP_LOADING = "BULK_ITEM_LOOKUP_LOADING";
export const BULK_ITEM_LOOKUP = "BULK_ITEM_LOOKUP";

// Files
export const BOM_FILES = "BOM_FILES";
export const BOM_FILES_LOADING = "BOM_FILES_LOADING";
export const ADD_BOM_FILE = "ADD_BOM_FILE";
export const ADD_BOM_FILE_LOADING = "ADD_BOM_FILE_LOADING";
export const DELETE_BOM_FILE = "DELETE_BOM_FILE";
export const DELETE_BOM_FILE_LOADING = "DELETE_BOM_FILE_LOADING";
export const DOWNLOAD_BOM_FILE = "DOWNLOAD_BOM_FILE";
export const DOWNLOAD_BOM_FILE_LOADING = "DOWNLOAD_BOM_FILE_LOADING";
export const DOWNLOAD_ALL_FILES = "DOWNLOAD_ALL_FILES";
export const DOWNLOAD_ALL_FILES_LOADING = "DOWNLOAD_ALL_FILES_LOADING";

export const GPN_FILES = "GPN_FILES";
export const GPN_FILES_LOADING = "GPN_FILES_LOADING";
export const ADD_GPN_FILE = "ADD_GPN_FILE";
export const ADD_GPN_FILE_LOADING = "ADD_GPN_FILE_LOADING";
export const DELETE_GPN_FILE = "DELETE_GPN_FILE";
export const DELETE_GPN_FILE_LOADING = "DELETE_GPN_FILE_LOADING";
export const DOWNLOAD_GPN_FILE = "DOWNLOAD_GPN_FILE";
export const DOWNLOAD_GPN_FILE_LOADING = "DOWNLOAD_GPN_FILE_LOADING";
export const DOWNLOAD_ALL_GPN_FILES = "DOWNLOAD_ALL_GPN_FILES";
export const DOWNLOAD_ALL_GPN_FILES_LOADING = "DOWNLOAD_ALL_GPN_FILES_LOADING";

export const MPN_FILES = "MPN_FILES";
export const MPN_FILES_LOADING = "MPN_FILES_LOADING";
export const ADD_MPN_FILE = "ADD_MPN_FILE";
export const ADD_MPN_FILE_LOADING = "ADD_MPN_FILE_LOADING";

//   Comments

export const GPN_COMMENT_LIST = "GPN_COMMENT_LIST";
export const GPN_COMMENT_LIST_RAW = "GPN_COMMENT_LIST_RAW";
export const GPN_COMMENT_LIST_LOADING = "GPN_COMMENT_LIST_LOADING";
export const ADD_GPN_COMMENT = "ADD_GPN_COMMENT";
export const ADD_GPN_COMMENT_LOADING = "ADD_GPN_COMMENT_LOADING";
// mpn
export const MPN_COMMENT_LIST = "MPN_COMMENT_LIST";
export const MPN_COMMENT_LIST_RAW = "MPN_COMMENT_LIST_RAW";
export const MPN_COMMENT_LIST_LOADING = "MPN_COMMENT_LIST_LOADING";
export const ADD_MPN_COMMENT = "ADD_MPN_COMMENT";
export const ADD_MPN_COMMENT_LOADING = "ADD_MPN_COMMENT_LOADING";
//   bom
export const BOM_COMMENT_LIST = "BOM_COMMENT_LIST";
export const BOM_COMMENT_LIST_RAW = "BOM_COMMENT_LIST_RAW";
export const BOM_COMMENT_LIST_LOADING = "BOM_COMMENT_LIST_LOADING";
export const ADD_BOM_COMMENT = "ADD_BOM_COMMENT";
export const ADD_BOM_COMMENT_LOADING = "ADD_BOM_COMMENT_LOADING";

// docs
export const TO_DOC = "TO_DOC"
//
export const GET_COMMENT = "GET_COMMENT";
export const GET_COMMENT_LOADING = "GET_COMMENT_LOADING";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_COMMENT_LOADING = "UPDATE_COMMENT_LOADING";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_LOADING = "DELETE_COMMENT_LOADING";
export const ADD_REPLY = "ADD_REPLY";
export const ADD_REPLY_LOADING = "ADD_REPLY_LOADING";
export const GET_REPLY_LIST = "GET_REPLY_LIST";
export const GET_REPLY_LIST_RAW = "GET_REPLY_LIST_RAW";
export const GET_REPLY_LIST_LOADING = "GET_REPLY_LIST_LOADING";
export const ADD_REACTION = "ADD_REACTION";
export const ADD_REACTION_LOADING = "ADD_REACTION_LOADING";
export const GET_REACTION_LIST = "GET_REACTION_LIST";
export const GET_REACTION_LIST_RAW = "GET_REACTION_LIST_RAW";
export const GET_REACTION_LIST_LOADING = "GET_REACTION_LIST_LOADING";
export const GET_REACTION = "GET_REACTION";
export const GET_REACTION_LOADING = "GET_REACTION_LOADING";
export const DELETE_REACTION = "DELETE_REACTION";
export const DELETE_REACTION_LOADING = "DELETE_REACTION_LOADING";

export const GET_ABOUT = "GET_ABOUT";
export const GET_ABOUT_LOADING = "GET_ABOUT_LOADING";

//app contants
export const SEARCH_DELAY = 850;
export const TOAST_DURATION = 2000;
export const TOKEN_COOKIE = "CONTROL_TOKEN";
export const REFRESH_TOKEN_COOKIE = "CONTROL_REFRESH_TOKEN";
export const USER_COOKIE = "CONTROL_USER";
export const USERNAME_COOKIE = "USERNAME";
export const PATH_COOKIE = "/";
