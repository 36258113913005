import React, { Component } from "react";
import {
  Badge,
  Col,
  Form,
  FormGroup,
  FormLabel,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";

export default class ImportDropdowns extends Component {
  render() {
    return (
      <div>
        <Row className="my-3">
          <Col md={2}>
            <FormLabel>Critical Handling </FormLabel>
          </Col>
          <Col md={3}>
            <Form.Select
              defaultValue={this.props.critical}
              onChange={(e) => this.props.set_critical(e.target.value)}
            >
              <option value="DEFAULT_TRUE">Default True</option>
              <option value="DEFAULT_FALSE">Default False</option>
              <option value="ERROR">Error</option>
              <option value="FORCE_TRUE">Force True</option>
              <option value="FORCE_TRUE">Force False</option>
            </Form.Select>
          </Col>
          <Col>
            <OverlayTrigger
              placement={"right"}
              overlay={
                <Tooltip id={`tooltip-right`}>
                  Choose whether a line item should be assumed to be critical
                  ("Default True"), not critical ("Default False"), or create an
                  error ("Error") if it is not specified. Or, if you want to
                  ignore what is specified and mark all items as critical or
                  not, choose "Force True" or "Force False". Defaults to
                  "Default False" (item not critical).
                </Tooltip>
              }
            >
              <Badge bg="secondary">?</Badge>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="my-3">
          <Col md={2}>
            <FormLabel>Fitted Handling</FormLabel>
          </Col>
          <Col md={3}>
            <Form.Select
              defaultValue={this.props.fitted}
              onChange={(e) => this.props.set_fitted(e.target.value)}
            >
              <option value="DEFAULT_TRUE">Default True</option>
              <option value="DEFAULT_FALSE">Default False</option>
              <option value="ERROR">Error</option>
              <option value="FORCE_TRUE">Force True</option>
              <option value="FORCE_TRUE">Force False</option>
            </Form.Select>
          </Col>
          <Col>
            <OverlayTrigger
              placement={"right"}
              overlay={
                <Tooltip id={`tooltip-right`}>
                  Choose whether a line item should be assumed to be fitted
                  ("Default True"), not fitted ("Default False"), or create an
                  error ("Error") if it is not specified. Or, if you want to
                  ignore what is specified and mark all items as fitted or not,
                  choose "Force True" or "Force False". Defaults to "Default
                  True" (item is fitted)
                </Tooltip>
              }
            >
              <Badge bg="secondary">?</Badge>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="my-3">
          <Col md={2}>
            <FormLabel>Designator Handling</FormLabel>
          </Col>
          <Col md={3}>
            <Form.Select
              defaultValue={this.props.designator}
              onChange={(e) => this.props.set_designator(e.target.value)}
            >
              <option value="ERROR">Error</option>
              <option value="DEFAULT_AUTO">Default Auto</option>
            </Form.Select>
          </Col>
          <Col>
            <OverlayTrigger
              placement={"right"}
              overlay={
                <Tooltip id={`tooltip-right`}>
                  Choose what should happen when a designator is not specified.
                  Defaults to raising an error ("Error"), but can also
                  autogenerate a designator if requested ("Default Auto").
                </Tooltip>
              }
            >
              <Badge bg="secondary">?</Badge>
            </OverlayTrigger>
          </Col>
        </Row>
      </div>
    );
  }
}
