import React, { Component } from "react";
import {
  Badge,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  OverlayTrigger,
  Row,
  Tab,
  Table,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Button from "@restart/ui/esm/Button";
import {
  addGPNAction,
  addGpnCommentAction,
  addGpnFileAction,
  deactivateGPNAction,
  deleteGpnFileAction,
  downloadAllGpnFilesAction,
  downloadGpnFileAction,
  editGPNAction,
  getGPNAction,
  getGPNfilesAction,
  getGPNPartTypeAction,
  getGPNSimilarAction,
  getGPNSuggestAction,
  gpnAutoCompleteAction,
  gpnCommentsListAction,
} from "../../../actions/inventory/gpn-action";
import { GET_GPN, TO_GPN, TO_PART_TYPE } from "../../../helpers/constants";
import { getSelectDarkStyle } from "../../../helpers/filter-dropdown-dark";
import store from "../../../store";
import Loading from "../../shared/loader/spinner";
import { loadOptions } from "./gpn-common";
import CommentView from "../../shared/commentView/commentView";
import FileView from "../../shared/fileView/fileView";
import { downloadFileAction } from "../../../actions/bom/bom-action";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import AltFrom from "./AltFrom";
import AltTo from "./AltTo";
import { updateQueryParams } from "../../../helpers/utility";
import Editor from "@uiw/react-md-editor";
// Class to wrap all add & edit functionality for GPNs
class AddEditGPN extends Component {
  constructor(props) {
    super(props);

    // Whether this view is for adding or editing
    const is_add = this.props.gpn_id == null;

    this.timeout = 0;
    this.state = {
      showDropdown: false,
      tab: "#home",
      gpnActive: true,
      is_add: is_add,
      gpn_id: this.props.gpn_id,

      gpnbyId: this.props.gpnbyId,
      title_text: is_add ? "Add GPN" : "\u00a0",

      // List of part type objects
      partTypes: [],
      classDropdown: [
        { value: "identical", label: "Identical" },
        { value: "general", label: "General" },
      ],
      partId: "",
      description: this.props.gpnbyId.description,
      descOptions: [],
      class: {
        value: this.props.gpnbyId.gpn_class,
        label: this.props.gpnbyId.gpn_class,
      },
      modalIsOpen: false,
      selectedFile: null,
      // selectedPartType: {},

      selectedPartType: {},
      note: "",
      changeMsg: "",
      similargpn: [],
      mpnSuggestion: [],
      mpnstoAdd: [],
      checkbtn: false,
      fileViewHeight: 500,
      comment: "",
    };
    this.loadOptions = loadOptions.bind(this);
  }

  fileHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    });
  };

  uploadFile = () => {
    const data = new FormData();
    data.append("file", this.state.selectedFile);
    // file upload api
    addGpnFileAction(this.state.gpn_id, data);
  };

  // Upload more than one file at once
  uploadMultiFile = (filesArr) => {
    const data = new FormData();
    filesArr.forEach((file, i) => {
      data.append("file", file);
    });
    addGpnFileAction(this.state.gpn_id, data);
  };
  handleDelete = (uuid) => {
    deleteGpnFileAction(uuid);
    setTimeout(() => {
      getGPNfilesAction(this.state.gpn_id);
    }, 2000);
  };

  handleLinkedMPNDelete = (id) => {
    let mpnSuggestion = this.state.mpnSuggestion;
    this.state.mpnSuggestion.forEach((elem, index) => {
      if (elem.id === id) {
        mpnSuggestion[index].isChecked = false;
      }
    });
    const newMPNlist = this.state.mpnstoAdd.filter((el) => el.id !== id);
    this.setState({ mpnstoAdd: newMPNlist });
    this.setState({ checkbtn: id });
  };

  handleLinkedMPNAdd = (id) => {
    let mpnSuggestion = this.state.mpnSuggestion;

    const verify = this.state.mpnstoAdd.find((el) => el.id == id);

    if (verify) {
      this.state.mpnSuggestion.forEach((elem, index) => {
        if (elem.id === verify.id) {
          // mpnSuggestion[index].isChecked = false
        }
      });
    } else {
      const item = this.state.mpnSuggestion.find((el) => el.id == id);
      item.isChecked = true;
      this.state.mpnSuggestion.forEach((elem, index) => {
        if (elem.id === item.id) {
          mpnSuggestion[index].isChecked = true;
        }
      });
      this.setState({ mpnstoAdd: [...this.state.mpnstoAdd, item] });
      this.setState({ checkbtn: id });
    }
    this.setState({ mpnSuggestion });
  };

  checkDisable = (id) => {
    this.state.mpnstoAdd.find((el) => el.id == id);
  };

  // Load similar GPNs and suggested MPNs
  dispatchGPNSimilarSuggest = () => {
    if (
      this.state.selectedPartType.id != null &&
      this.state.selectedPartType.id !== "" &&
      this.state.class !== "" &&
      this.state.description !== ""
    ) {
      this.timeout = setTimeout(() => {
        store.dispatch(
          getGPNSimilarAction({
            type_id: this.state.selectedPartType.id,
            gpn_class: this.state.class.value,
            description: this.state.description,
          })
        );
        store.dispatch(
          getGPNSuggestAction({
            type_id: this.state.selectedPartType.id,
            gpn_class: this.state.class.value,
            description: this.state.description,
          })
        );
      }, 1000);
    }
  };

  // Reload suggestions when the description field changes
  handleDescriptionChange = (e) => {
    this.setState({ description: e });
    if (this.timeout) clearTimeout(this.timeout);
    this.dispatchGPNSimilarSuggest();
  };

  // Action for when main save/add button is clicked
  handleSubmit = () => {
    const data = {
      part_type_id: this.state.selectedPartType.id,
      description: this.state.description,
      gpn_class: this.state.class.value,
      active: true,
      change_message: this.state.changeMsg,
      note: this.state.note,
      linked_mpn_ids: this.state.mpnstoAdd.map((el) => el.id),
    };
    if (this.state.is_add) {
      store.dispatch(addGPNAction(data));
    } else {
      console.log(data, "==========data to edit");
      store.dispatch(editGPNAction(this.state.gpn_id, data));
    }
  };

  handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (
      (event.ctrlKey || event.metaKey) &&
      (charCode === "s") ^ (event.key === "Enter")
    ) {
      event.preventDefault();
      this.handleSubmit();
      console.log("Saving");
    }
  };

  componentDidMount() {
    // console.log(this.state.is_add);
    // Update part types
    store.dispatch(getGPNPartTypeAction());

    // If we are editing an item, load its contents
    if (!this.state.is_add) getGPNAction(this.state.gpn_id);

    const id = this.state.gpn_id;
    if (!this.state.is_add) {
      getGPNfilesAction(id);
      store.dispatch(gpnCommentsListAction(id));
      // const leftColHeight = this.leftColumnElement.clientHeight;
      // const fileHeaderHeight = this.fileHeaderElement.clientHeight;
      // this.setState({ fileViewHeight: leftColHeight - fileHeaderHeight });
    } else {
      this.setState({ showDropdown: true });
    }

    this.setState({ gpnbyId: this.props.gpnbyId });

    if (window.location.hash) {
      this.setState({ tab: window.location.hash });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Update when part type dropdown changes
    const partTypesDidUpdate =
      JSON.stringify(prevProps.partTypes) !==
      JSON.stringify(this.props.partTypes);

    // Update parent GPN item
    const gpnDidUpdate =
      JSON.stringify(prevProps.gpnbyId) !== JSON.stringify(this.props.gpnbyId);

    const gpnClassDidUpdate =
      JSON.stringify(prevProps.gpnbyId.gpn_class) !==
      JSON.stringify(this.props.gpnbyId.gpn_class);

    const gpnDescriptionDidUpdate =
      JSON.stringify(prevProps.gpnbyId.description) !==
      JSON.stringify(this.props.gpnbyId.description);

    const similarGPNDidUpdate =
      JSON.stringify(prevProps.similargpn) !==
      JSON.stringify(this.props.similargpn);

    const suggestMPNDidUpdate =
      JSON.stringify(prevProps.suggestmpn) !==
      JSON.stringify(this.props.suggestmpn);
    const mpnMembersDidUpdate =
      JSON.stringify(prevProps.mpnMembers) !==
      JSON.stringify(this.props.mpnMembers);
    const addedGPNDidComplete =
      JSON.stringify(prevProps.addedGPNItem) !==
      JSON.stringify(this.props.addedGPNItem);

    const partTypesStateDidUpdate =
      JSON.stringify(prevState.partTypes) !==
      JSON.stringify(this.state.partTypes);

    const autoCompletePropsDidUpdate =
      JSON.stringify(prevProps.gpnautoComplete) !==
      JSON.stringify(this.props.gpnautoComplete);

    // Our GPN data loaded

    if (autoCompletePropsDidUpdate) {
      this.setState({
        descOptions: this.props.gpnautoComplete,
      });
    }

    if (gpnDidUpdate) {
      this.setState({
        gpnbyId: this.props.gpnbyId,
        gpnActive: this.props.gpnbyId.active,
        title_text: this.props.gpnbyId.group_pn,
      });
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      document.title = `${this.props.gpnbyId.group_pn} on Incontrol`;
      this.dispatchGPNSimilarSuggest();
    }

    if (partTypesDidUpdate) {
      this.setState({
        partTypes: this.props.partTypes,
      });
    }

    // If our state successfully updated with new part types, set the dropdown
    if (partTypesStateDidUpdate) {
      const selectedPartTypeId = this.state.selectedPartType.id;
      const gpnPartTypeID = this.state.gpnbyId.part_type_id;

      const partTypeId =
        selectedPartTypeId == null ? gpnPartTypeID : selectedPartTypeId;

      this.setPartTypebyID(partTypeId);
    }

    if (gpnClassDidUpdate) {
      this.setState({
        class: {
          value: this.props.gpnbyId.gpn_class,
          label: this.props.gpnbyId.gpn_class,
        },
      });
    }

    if (gpnDescriptionDidUpdate) {
      this.setState({
        description: this.props.gpnbyId.description,
        showDropdown: true,
      });
    }

    if (similarGPNDidUpdate) {
      this.setState({
        similargpn: this.props.similargpn,
      });
    }

    if (suggestMPNDidUpdate) {
      this.setState({
        mpnSuggestion: this.props.suggestmpn.map((el) =>
          Object.assign(el, { isChecked: false })
        ),
      });
    }

    if (mpnMembersDidUpdate) {
      this.setState({
        mpnstoAdd: this.props.mpnMembers,
      });
    }

    if (addedGPNDidComplete) {
      const new_url = GET_GPN.replace(":id", this.state.addedGPNItem.id);
      const history = useHistory();
      history.push(new_url);
    }
  }

  // Part type options for select dropdown
  getPartTypesMap() {
    return this.state.partTypes.map((el) => ({
      id: el.id,
      value: el.id,
      descriptionFormat: el.part_description_format,
      label: `[${el.group_pn_prefix}] - ${el.name}`,
    }));
  }

  // Set the active part type given an ID
  setPartTypebyID(id) {
    // const partTypeMap = this.getPartTypesMap();
    // const element = partTypeMap.find((el) => el.id === id);

    // if (element == null) return;
    this.setState({
      selectedPartType: {
        id: this.state.gpnbyId.part_type_id,
        value: this.state.gpnbyId.part_type_name,
        descriptionFormat: this.state.gpnbyId.description,
        label: `[${this.state.gpnbyId?.group_pn}] - ${this.state.gpnbyId.part_type_name}`,
      },
    });
  }
  selectTab = (k) => {
    let url = window.location.protocol + window.location.pathname + k;
    window.history.pushState({ path: url }, "", url);
    this.setState({ tab: k });
  };

  render() {
    // const actionButtonLocation = GET_GPN.replace(':id', this.state.mpn_id)
    const button_text = this.state.is_add ? "Add GPN" : "Save GPN";
    const actionButtonLocation = this.state.is_add
      ? ""
      : GET_GPN.replace(":id", this.state.gpn_id);
    let loadingSpinner = "";

    if (this.props.gpnbyIdLoading || this.props.partTypeLoading) {
      loadingSpinner = (
        <div className="main-loading-spinner">
          <Loading></Loading>
        </div>
      );
    }
    const url = window.location.pathname.split("/");
    const id = this.state.gpn_id;

    // console.log(this.state.selectedPartType);

    return (
      <div className="main-section">
        <Container>
          <div className="d-flex align-items-center">
            <h3
              className={
                !this.state.gpnActive
                  ? "text-muted py-3 px-3 mt-3"
                  : "py-3 px-3 mt-3"
              }
            >
              {this.state.title_text}{" "}
            </h3>
            <i className="mt-3 text-muted">{this.props.gpnbyId?.description}</i>
            {!this.state.gpnActive && (
              <Badge className="mt-3" bg="danger">
                Inactive
              </Badge>
            )}
          </div>

          <Tabs
            id="controlled-tab-example"
            activeKey={this.state.tab}
            onSelect={(k) => this.selectTab(k)}
            className="mb-3"
          >
            <Tab
              eventKey={"#home"}
              title="Home"
              className={!this.state.gpnActive && "text-muted"}
            >
              <Form onKeyDown={this.handleKeyDown}>
                <Row>
                  <Col
                    xxl={6}
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="mb-5"
                  >
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="2">
                        Part Type
                      </Form.Label>
                      <Col lg={"8"} sm={"10"} className="px-4">
                        {!this.state.is_add ? (
                          this.state.selectedPartType && (
                            <Select
                              size="sm"
                              classNamePrefix="lp-copy-sel"
                              isDisabled={this.state.gpnActive ? false : true}
                              // defaultValue={'abc'}
                              value={this.state.selectedPartType}
                              options={this.getPartTypesMap()}
                              styles={getSelectDarkStyle("md")}
                              onChange={(el) =>
                                this.setState({ selectedPartType: el })
                              }
                            />
                          )
                        ) : (
                          <Select
                            size="sm"
                            classNamePrefix="lp-copy-sel"
                            isDisabled={this.state.gpnActive ? false : true}
                            options={this.getPartTypesMap()}
                            styles={getSelectDarkStyle("md")}
                            onChange={(el) =>
                              this.setState({ selectedPartType: el })
                            }
                          />
                        )}
                      </Col>
                      <Col>
                        {!this.state.is_add && (
                          <Link
                            to={TO_PART_TYPE}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Badge bg="secondary">?</Badge>
                          </Link>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                      <Col sm={"2"}>
                        <Form.Label>Class</Form.Label>
                      </Col>
                      <Col lg={"8"} sm={"10"} className="px-4">
                        <Select
                          size="sm"
                          isDisabled={this.state.gpnActive ? false : true}
                          defaultValue={{
                            label: "Identical",
                            value: "identical",
                          }}
                          value={this.state.class}
                          options={this.state.classDropdown}
                          styles={getSelectDarkStyle("md")}
                          onChange={(el) => this.setState({ class: el })}
                        />
                      </Col>
                      <Col>
                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip id={`tooltip-right`}>
                              Identical:
                              <br /> Same part, different packaging.Always
                              interchangeable.
                              <br /> General:
                              <br /> Parts with similar parameters,
                              interchangeable in most cases
                            </Tooltip>
                          }
                        >
                          <Badge bg="secondary">?</Badge>
                        </OverlayTrigger>
                      </Col>
                    </Form.Group>
                    <p className="py-2">
                      Description format:{" "}
                      {this.state.selectedPartType.descriptionFormat}
                    </p>
                    <Form.Group
                      as={Row}
                      className="py-3"
                      style={{ minHeight: "110px" }}
                    >
                      <Form.Label column sm="2">
                        Description
                      </Form.Label>
                      <Col lg={"8"} sm={"10"} className="px-4">
                        {this.state.showDropdown && (
                          <AsyncTypeahead
                            id="async-example"
                            isLoading={this.props.gpnautoCompleteLoading}
                            labelKey=""
                            defaultInputValue={this.state.description}
                            disabled={this.state.gpnActive ? false : true}
                            onSearch={(e) => {
                              gpnAutoCompleteAction({
                                field_name: "description",
                                query: e,
                                part_type_id: this.state.selectedPartType?.id,
                              });
                              this.handleDescriptionChange(e);
                            }}
                            onChange={(e) =>
                              this.setState({ description: e[0] })
                            }
                            options={this.state.descOptions}
                            placeholder="Description"
                          />
                        )}
                      </Col>
                    </Form.Group>

                    <p>
                      Possible similar matches: do not create if this would be
                      repeated
                    </p>
                    {/*             Table                   */}
                    <Col
                      lg={"11"}
                      style={{
                        height: 200,
                        overflow: "scroll",
                      }}
                    >
                      <Table
                        striped
                        bordered
                        hover
                        className={!this.state.gpnActive && "text-muted"}
                      >
                        <thead>
                          <tr>
                            <th>GPN</th>
                            <th>Description</th>
                            <th>Class</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.similargpn.map((el, ind) => {
                            return (
                              <tr key={ind}>
                                <td
                                  className={
                                    !this.state.gpnActive && "text-muted"
                                  }
                                >
                                  {el.group_pn}
                                </td>
                                <td
                                  className={
                                    !this.state.gpnActive && "text-muted"
                                  }
                                >
                                  {el.description}
                                </td>
                                <td
                                  className={
                                    !this.state.gpnActive && "text-muted"
                                  }
                                >
                                  {el.gpn_class}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Col>
                  <Col
                    xxl={6}
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="mb-5"
                  >
                    <Form.Group as={Row}>
                      <Form.Label column sm="4" lg="4">
                        Link another MPN
                      </Form.Label>
                      <Col sm={"10"} lg={"8"}>
                        <AsyncSelect
                          cacheOptions
                          size="sm"
                          classNamePrefix="lp-copy-sel"
                          isDisabled={this.state.gpnActive ? false : true}
                          loadOptions={this.loadOptions}
                          styles={getSelectDarkStyle("md")}
                          defaultOptions
                          // onInputChange={this.handleInputChange}
                          onChange={(el) =>
                            this.setState({
                              mpnstoAdd: [...this.state.mpnstoAdd, el],
                            })
                          }
                          placeholder="Type to search for a MPN"
                        />
                      </Col>
                    </Form.Group>

                    <div className="my-4">
                      <p>MPN Suggestions</p>
                      {/*                Table                  */}
                      <Col
                        lg={"12"}
                        style={{
                          height: 200,
                          overflow: "scroll",
                        }}
                      >
                        <Table
                          striped
                          bordered
                          hover
                          className={!this.state.gpnActive && "text-muted"}
                        >
                          <thead>
                            <tr>
                              <th>IPN</th>
                              <th>MPN</th>
                              <th>Description</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.mpnSuggestion.map((el, ind) => {
                              return (
                                <tr key={ind}>
                                  <td
                                    className={
                                      !this.state.gpnActive && "text-muted"
                                    }
                                  >
                                    {el.ics_pn}
                                  </td>
                                  <td
                                    className={
                                      !this.state.gpnActive && "text-muted"
                                    }
                                  >
                                    {el.manufacturer_pn}
                                  </td>
                                  <td
                                    className={
                                      !this.state.gpnActive && "text-muted"
                                    }
                                  >
                                    {el.description}
                                  </td>
                                  <td align="center">
                                    <Form.Check
                                      checked={el.isChecked}
                                      disabled={
                                        this.state.gpnActive ? false : true
                                      }
                                      onChange={() =>
                                        this.handleLinkedMPNAdd(el.id)
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </div>

                    <p className="font-weight-bolder">
                      {this.state.is_add ? "MPNs to add" : "MPN Members"}
                    </p>
                    {/*               MPN members Table                  */}
                    <Col
                      lg={"12"}
                      style={{
                        height: 200,
                        overflow: "scroll",
                      }}
                    >
                      <Table
                        striped
                        bordered
                        hover
                        className={!this.state.gpnActive && "text-muted"}
                      >
                        <thead>
                          <tr>
                            <th>IPN</th>
                            <th>MPN</th>
                            <th>Description</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.mpnstoAdd.map((el, ind) => {
                            return (
                              <tr key={el.id}>
                                <td
                                  className={
                                    !this.state.gpnActive && "text-muted"
                                  }
                                >
                                  {el.ics_pn}
                                </td>
                                <td
                                  className={
                                    !this.state.gpnActive && "text-muted"
                                  }
                                >
                                  {el.manufacturer_pn}
                                </td>
                                <td
                                  className={
                                    !this.state.gpnActive && "text-muted"
                                  }
                                >
                                  {el.description}
                                </td>
                                <td align="center">
                                  <Button
                                    className="btn btn-danger"
                                    disabled={
                                      this.state.gpnActive ? false : true
                                    }
                                    onClick={() =>
                                      this.handleLinkedMPNDelete(el.id)
                                    }
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Col>
                </Row>
                <Form.Group as={Row} className="py-2">
                  <Form.Label column sm="4" lg="2">
                    GPN Notes:
                  </Form.Label>
                  <Col lg={"6"} sm={"8"}>
                    <AsyncTypeahead
                      id="async-example"
                      // isLoading={this.props.gpnautoCompleteLoading}
                      labelKey=""
                      // defaultInputValue={"abc"}
                      disabled={this.state.gpnActive ? false : true}
                      onSearch={(e) => {
                        gpnAutoCompleteAction({
                          field_name: "note",
                          query: e,
                          part_type_id: this.state.selectedPartType?.id,
                        });
                        this.setState({ note: e });
                      }}
                      onChange={(e) => this.setState({ note: e[0] })}
                      options={this.state.descOptions}
                      placeholder="Note"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="py-2">
                  <Form.Label column sm="4" lg="2">
                    Change Message
                  </Form.Label>
                  <Col lg={"6"} sm={"8"}>
                    <AsyncTypeahead
                      id="async-example"
                      // isLoading={this.props.gpnautoCompleteLoading}
                      labelKey=""
                      // defaultInputValue={"abc"}
                      disabled={this.state.gpnActive ? false : true}
                      onSearch={(e) => {
                        gpnAutoCompleteAction({
                          field_name: "change_message",
                          query: e,
                          part_type_id: this.state.selectedPartType?.id,
                        });
                        this.setState({ changeMsg: e });
                      }}
                      onChange={(e) => this.setState({ changeMsg: e[0] })}
                      options={this.state.descOptions}
                      placeholder="Change Message"
                    />
                  </Col>
                </Form.Group>

                {/* Action buttons */}
                <Row>
                  <Col className="my-4">
                    <Link to={TO_GPN}>
                      <Button className="btn btn-outline-light float-left">
                        Exit without saving
                      </Button>
                    </Link>

                    <Link to={actionButtonLocation}>
                      <Button
                        className="btn btn-outline-info float-end mx-2"
                        disabled={this.state.gpnActive ? false : true}
                        onClick={this.handleSubmit}
                      >
                        {button_text}
                      </Button>
                    </Link>
                    {this.state.is_add ? (
                      ""
                    ) : (
                      <Button
                        className={
                          this.state.gpnActive
                            ? "btn btn-outline-danger float-end"
                            : "btn btn-outline-success float-end"
                        }
                        onClick={() => this.setState({ modalIsOpen: true })}
                      >
                        {this.state.gpnActive ? "Deactivate" : "Activate"}
                      </Button>
                    )}
                  </Col>
                </Row>
                {/*                  Modal                  */}
                <Modal
                  show={this.state.modalIsOpen}
                  onHide={() => this.setState({ modalIsOpen: false })}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {this.state.gpnActive ? "Deactivate" : "Activate"} GPN
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to{" "}
                    {this.state.gpnActive ? "deactivate" : "activate"}{" "}
                    <b>GPN {this.state.title_text}</b>?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-danger"
                      onClick={() => {
                        this.setState({ modalIsOpen: false });
                        store.dispatch(
                          deactivateGPNAction(id, {
                            active:
                              this.state.gpnActive == false ? true : false,
                          })
                        );
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      onClick={() => this.setState({ modalIsOpen: false })}
                    >
                      No
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/*                 Modal                     */}
              </Form>
            </Tab>
            {!this.state.is_add && (
              <Tab
                eventKey={"#files"}
                title={
                  <>
                    Files &nbsp;&nbsp;
                    <Badge className="bg-secondary">
                      {this.props.gpnFiles.length}
                    </Badge>
                  </>
                }
              >
                <Row>
                  <Col>
                    <h5 style={{ display: "inline-block" }}>Files</h5>
                    &nbsp; &nbsp; &nbsp;
                    <em className="text-muted">(accepts drag &amp; drop)</em>
                    <div>
                      <FileView
                        files={this.props.gpnFiles}
                        deleteFileAction={this.handleDelete}
                        filesLoading={this.props.gpnFilesLoading}
                        uploader={this.uploadMultiFile}
                        addFileLoading={this.props.addGpnFileLoading}
                        height={this.state.fileViewHeight}
                        downloadFileAction={downloadGpnFileAction}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={"6"}>
                    <Row className="my-3">
                      <Col md={"5"}>
                        <Form.Control
                          type="file"
                          size={"sm"}
                          onChange={this.fileHandler}
                        />
                      </Col>
                      <Col>
                        <button
                          className="btn w-100 btn-outline-light"
                          size="sm"
                          onClick={this.uploadFile}
                          // disabled={
                          //   this.state.selectedFile == null || !this.props.status
                          //     ? true
                          //     : false
                          // }
                        >
                          Upload
                        </button>
                      </Col>
                      {"  "}

                      <Col>
                        <button
                          className="btn w-100 btn-outline-light"
                          size="sm"
                          // disabled={this.props.status ? false : true}
                          onClick={() => {
                            console.log(id);
                            downloadAllGpnFilesAction(id);
                          }}
                        >
                          <i className="fa fa-download"></i> All
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tab>
            )}
            {!this.state.is_add && (
              <Tab
                eventKey={"#comments"}
                title={
                  <>
                    Comments &nbsp;&nbsp;
                    <Badge className="bg-secondary">
                      {this.props.gpnCommentsList.length}
                    </Badge>
                  </>
                }
              >
                <CommentView commentsList={this.props.gpnCommentsList} />
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    store.dispatch(
                      addGpnCommentAction(id, { comment: this.state.comment })
                    );
                    this.setState({ comment: "" });
                    console.log("comment submitted");
                  }}
                >
                  <Editor
                    preview="edit"
                    value={this.state.comment}
                    onChange={(value) => this.setState({ comment: value })}
                  />
                  <button type="submit" className="btn btn-outline-info my-2">
                    Comment
                  </button>
                </Form>
                <br />
                <br />
              </Tab>
            )}
            {!this.state.is_add && (
              <Tab eventKey={"#history"} title="History">
                <h1>Histoy Tab</h1>
              </Tab>
            )}
            {!this.state.is_add && (
              <Tab eventKey={"#from"} title="Alts From">
                <AltFrom altList={this.props.alternative_from} />
              </Tab>
            )}
            {!this.state.is_add && (
              <Tab eventKey={"#to"} title="Alts To">
                <AltTo altList={this.props.alternative_to} />
              </Tab>
            )}
          </Tabs>
        </Container>
        <div>{loadingSpinner}</div>
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    addedGPNItem: state.inventory.addGpn, // After adding, we can get the id from here
    partTypes: state.inventory.partType,
    partTypeRaw: state.inventory.partTypeRaw,
    partTypeLoading: state.inventory.partTypeLoading,
    gpnbyId: state.inventory.gpnbyId, // Details about a single GPN from its ID
    gpnbyIdLoading: state.inventory.gpnbyIdLoading,
    mpnMembers: state.inventory.mpnMembers,
    mpnMembersLoading: state.inventory.mpnMembersLoading,
    similargpn: state.inventory.similargpn,
    similargpnRaw: state.inventory.similargpnRaw,
    similargpnLoading: state.inventory.similargpnLoading,
    suggestmpn: state.inventory.suggestmpn,
    suggestmpnRaw: state.inventory.suggestmpnRaw,
    suggestmpnLoading: state.inventory.suggestmpnLoading,
    editGpn: state.inventory.editGpn,
    editGpnLoading: state.inventory.editGpnLoading,
    gpnautoComplete: state.inventory.gpnautoComplete,
    gpnautoCompleteLoading: state.inventory.gpnautoCompleteLoading,
    gpnFiles: state.inventory.gpnFiles,
    gpnFilesLoading: state.inventory.gpnFilesLoading,
    addGpnFile: state.inventory.addGpnFile,
    addGpnFileLoading: state.inventory.addGpnFileLoading,
    alternative_to: state.inventory.alternative_to,
    alternative_toLoading: state.inventory.alternative_toLoading,
    alternative_from: state.inventory.alternative_from,
    alternative_fromLoading: state.inventory.alternative_fromLoading,
    gpnCommentsList: state.comments.getGpnComments,
    gpncommentsLoading: state.comments.getGpnCommentsLoading,
  };
};
export default connect(stateMap)(AddEditGPN);
