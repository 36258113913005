import React, { Component } from "react";
import {
  Col,
  Container,
  Form,
  FormControl,
  Row,
  Tabs,
  Tab,
  Modal,
  Badge,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import Button from "@restart/ui/esm/Button";
import {
  addMPNAction,
  addMpnFileAction,
  deactivateMPNAction,
  downloadAllMpnFilesAction,
  editMPNAction,
  getMPNfilesAction,
  getMPNWithFieldsAction,
  getPartTypesWithFieldsAction,
  mpnAutocompleteAction,
  mpnCommentsListAction,
  mpnLoadSuggestionsAction,
  addMpnCommentAction,
} from "../../../actions/inventory/mpn-action";
import { GET_MPN, TO_MPN, TO_PART_TYPE } from "../../../helpers/constants";
import { getSelectDarkStyle } from "../../../helpers/filter-dropdown-dark";
import store from "../../../store";
import Loading from "../../shared/loader/spinner";
import { COL_W, ParameterTable } from "./mpn-parameter-table";
import {
  deleteFileAction,
  downloadAllFilesAction,
  downloadFileAction,
} from "../../../actions/bom/bom-action";
import FileView from "../../shared/fileView/fileView";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { successToast } from "../../../helpers/utility";
import GpnMembership from "./gpn-membership";
import CommentView from "../../shared/commentView/commentView";
import Editor from "@uiw/react-md-editor";
import SymbolsTable from "./symbols-table";

class AddEditMPN extends Component {
  constructor(props) {
    super(props);
    // Either 'add' or 'edit'
    // if (this.action === "edit") {
    //     this.id = useParams()
    // } else {
    //     this.id = null
    // }
    const is_add = this.props.mpn_id == null;

    this.state = {
      mpnActive: true,
      tab: "#home",
      // List of possible part types
      partTypes: [],
      // Selected part type with all values
      activePartType: null,

      // Fields from the selected part type
      partTypeFields: [],
      // Suggestions loaded from the Octopart API
      suggestions: [],
      // Default to adding a MPN if we don't have it

      // Edit only params
      mpn_id: this.props.mpn_id,
      is_add: is_add,
      title_text: is_add ? "Add IPN" : " ",
      modalIsOpen: false,
      // Things to send for add/edit
      manufacturer: "",
      manufacturer_pn: "",
      description: "",
      // These exist in a field_name:value
      parameters: [],

      // Dict of field_name: [possibilities]
      autocomplete: { "": "" },
      autocompleteList: [],
      selectedFile: null,
      fileViewHeight: 500,
      //
      comment: "",
    };
  }

  fileHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    });
  };

  uploadFile = () => {
    const data = new FormData();
    data.append("file", this.state.selectedFile);
    // file upload api

    addMpnFileAction(this.state.mpn_id, data);
  };

  // Upload more than one file at once
  uploadMultiFile = (filesArr) => {
    const data = new FormData();
    filesArr.forEach((file, i) => {
      data.append("file", file);
    });
    addMpnFileAction(this.state.mpn_id, data);
  };
  handleDelete = (uuid) => {
    deleteFileAction(uuid);
    setTimeout(() => {
      getMPNfilesAction(this.state.mpn_id);
    }, 2000);
  };

  handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (
      (event.ctrlKey || event.metaKey) &&
      (charCode === "s") ^ (event.key === "Enter")
    ) {
      event.preventDefault();
      this.handleSubmit();
      console.log("Saving");
    }
  };

  generateSelectFromPartType(el) {
    if (el == null)
      return {
        value: "",
        label: "",
      };

    return {
      value: el.id,
      label: `[${el.ics_pn_prefix}] ${el.name}`,
    };
  }

  getPartTypesMap() {
    return this.state.partTypes.map((el) =>
      this.generateSelectFromPartType(el)
    );
  }

  // Handler for when part type dropdown is changed
  handlePartTypeChange = (elem) => {
    // Update the element in state
    const element = this.state.partTypes.find((el) => el.id === elem.value);
    if (!element) return;

    this.setState({
      activePartType: element,
    });
    const mpn = this.state.manufacturer_pn;
    const partTypeId = element.id;
    if (mpn != null && partTypeId != null) {
      store.dispatch(mpnLoadSuggestionsAction(mpn, partTypeId));
    }
  };

  // Callback for when a field's content is changed
  handleFieldChange = (evt) => {
    const val = evt.target.value;
    const name = evt.target.name;
    this.setState({
      [name]: val,
    });
  };

  // Handler for when a parameter changes
  handleParameterChange = (fieldName, fieldValue) => {
    let params = [...this.state.parameters];
    const objIndex = params.findIndex((param) => param.field_name == fieldName);
    if (objIndex != -1) params[objIndex].value = fieldValue;
    else params.push({ field_name: fieldName, value: fieldValue });

    this.setState({
      parameters: params,
    });
  };

  // What to do once the "add" or "edit" submit button is clicked
  handleSubmit = () => {
    let desc = this.state.parameters.find(
      (el) => el.field_name === "description"
    );
    const data = {
      part_type_id: this.state.activePartType.id,
      description: desc ? desc.value : "",
      manufacturer: this.state.manufacturer,
      manufacturer_pn: this.state.manufacturer_pn,
      parameters: this.state.parameters,
    };

    if (this.state.is_add) store.dispatch(addMPNAction(data));
    else store.dispatch(editMPNAction(this.state.mpn_id, data));
  };

  /**
   * Actions that run once the page is loaded
   */
  componentDidMount() {
    const id = this.state.mpn_id;

    store.dispatch(mpnCommentsListAction(id));
    store.dispatch(getPartTypesWithFieldsAction());

    if (window.location.pathname !== "/mpn/add") {
      getMPNfilesAction(id);
    }
    if (window.location.hash) {
      this.setState({ tab: window.location.hash });
    }
  }

  /**
   * // Actions that run on page updates
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      const partTypesDidUpdate =
        JSON.stringify(prevProps.partTypes) !==
        JSON.stringify(this.props.partTypes);
      const MPNDidUpdate =
        JSON.stringify(prevProps.mpnWithParams) !==
        JSON.stringify(this.props.mpnWithParams);
      const selectedPartTypeDidUpdate =
        JSON.stringify(prevState.activePartType) !==
        JSON.stringify(this.state.activePartType);

      const autoCompletePropsDidUpdate =
        JSON.stringify(prevProps.autocomplete) !==
        JSON.stringify(this.props.autocomplete);

      const autoCompleteListPropsDidUpdate =
        JSON.stringify(prevProps.autocompleteList) !==
        JSON.stringify(this.props.autocompleteList);

      if (autoCompleteListPropsDidUpdate) {
        this.setState({ autocompleteList: this.props.autocompleteList });
      }

      // if (prevProps.addMpn !== this.props.addMpn) {
      //  this.props.history.push(`/mpn/${this.props.addMpn.id}`)
      // }
      // Handle update when params load
      if (partTypesDidUpdate) {
        this.setState({
          // ADD HERE update parameters shown
          partTypes: this.props.partTypes,
        });

        if (!this.state.is_add)
          store.dispatch(getMPNWithFieldsAction(this.state.mpn_id));
      }

      // Handle update when mpn loads
      if (selectedPartTypeDidUpdate || MPNDidUpdate) {
        let part_type = this.state.activePartType;

        if (MPNDidUpdate) {
          part_type = this.state.partTypes.find(
            (el) => el.id === this.props.mpnWithParams["part_type_id"]
          );
          this.setState({
            activePartType: part_type,
          });
        }

        // Load fields here

        let fields = [];
        if (part_type?.fields != null) {
          fields = part_type.fields.sort((first, second) => {
            return first.visibility_sort_order - second.visibility_sort_order;
          });
        }

        this.setState({
          partTypeFields: fields,
        });

        const mpn = this.props.mpnWithParams?.manufacturer_pn;
        const partTypeId = part_type?.id;
        if ((mpn != null || mpn !== "") && partTypeId != null) {
          store.dispatch(mpnLoadSuggestionsAction(mpn, partTypeId));
        }
      }

      // State changes once the MPN loads
      if (MPNDidUpdate) {
        this.setState({
          title_text: this.props.mpnWithParams["ics_pn"],
          manufacturer: this.props.mpnWithParams["manufacturer"],
          manufacturer_pn: this.props.mpnWithParams["manufacturer_pn"],
          parameters: this.props.mpnWithParams["parameters"],
          description: this.props.mpnWithParams["description"],
          mpnActive: this.props.mpnWithParams["active"],
        });
        document.title = `${this.props.mpnWithParams["ics_pn"]} on Incontrol`;
      }
    }
  }
  selectTab = (k) => {
    let url = window.location.protocol + window.location.pathname + k;
    window.history.pushState({ path: url }, "", url);
    this.setState({ tab: k });
  };
  render() {
    const button_text = this.state.is_add ? "Add IPN" : "Save IPN";
    const partTypeMap = this.getPartTypesMap();
    // const actionButtonLocation = this.state.is_add
    //   ? GET_MPN.replace(":id", this.state.mpn_id)
    //   : "";
    //  console.log(this.state.parameters?.[19] ,'PARAMETERS');
    if (this.props.mpnLoading || this.props.partTypeLoading) {
      return (
        <div className="main-section">
          <Container>
            <div className="main-loading-spinner">
              <Loading></Loading>
            </div>
          </Container>
        </div>
      );
    }
    const id = this.props.mpn_id;
    // console.log(this.state.autocompleteList);
    const matched =
      this.state.autocompleteList &&
      this.state.autocompleteList.find(
        (el) => el === this.state.manufacturer_pn?.trim()
      );

    return (
      <div className="main-section">
        <Container>
          {/* <h3 className="py-3 mt-3">{this.state.title_text}</h3> */}
          <div className="d-flex align-items-center">
            <h3
              className={
                !this.state.mpnActive
                  ? "text-muted py-3 px-3 mt-3"
                  : "py-3 px-3 mt-3"
              }
            >
              {this.state.title_text}{" "}
            </h3>
            <i className="mt-3 text-muted">
              {this.props.mpnWithParams?.description}
            </i>
            {!this.state.mpnActive && (
              <Badge className="mt-3" bg="danger">
                Inactive
              </Badge>
            )}
          </div>
          <Tabs
            id="controlled-tab-example"
            activeKey={this.state.tab}
            onSelect={(k) => this.selectTab(k)}
          >
            <Tab eventKey={"#home"} title="Home">
              <Form onKeyDown={this.handleKeyDown}>
                {/* Fields presented at the beginning */}
                <fieldset>
                  <Form.Group as={Row} className="my-1">
                    <Form.Label column lg={COL_W.lab.lg} sm={COL_W.lab.sm}>
                      Manufacturer PN
                    </Form.Label>
                    <Col lg={COL_W.field.lg} sm={COL_W.field.sm}>
                      <AsyncTypeahead
                        id="async-example"
                        size="sm"
                        // isLoading={this.props.autocompleteLoading}
                        labelKey=""
                        // value={this.state.manufacturer_pn}
                        defaultInputValue={this.state.manufacturer_pn}
                        onChange={(e) => {
                          this.setState({ manufacturer_pn: e[0] });
                        }}
                        onSearch={(e) => {
                          store.dispatch(
                            mpnAutocompleteAction(
                              "manufacturer_pn",
                              e,
                              this.state.activePartType?.id
                            )
                          );
                          this.setState({ manufacturer_pn: e });
                        }}
                        options={this.state.autocompleteList}
                        placeholder="Manufacturer Part Number"
                      />
                    </Col>
                    {matched && (
                      <Col>
                        <span>
                          <OverlayTrigger
                            placement={"right"}
                            overlay={
                              <Tooltip id={`tooltip`}>
                                Manufacturer part number {matched} already
                                exists in database
                              </Tooltip>
                            }
                          >
                            <i
                              class="fa fa-exclamation-triangle text-warning"
                              aria-hidden="true"
                            ></i>
                          </OverlayTrigger>
                        </span>
                      </Col>
                    )}
                  </Form.Group>

                  <Form.Group as={Row} className="my-1">
                    <Form.Label column lg={COL_W.lab.lg} sm={COL_W.lab.sm}>
                      Manufacturer
                    </Form.Label>
                    <Col lg={COL_W.field.lg} sm={COL_W.field.sm} className="">
                      <AsyncTypeahead
                        id="async-example"
                        size="sm"
                        // isLoading={this.props.autocompleteLoading}
                        labelKey=""
                        // value={this.state.manufacturer}
                        defaultInputValue={this.state.manufacturer}
                        onChange={(e) => {
                          console.log("DropDown changed!");
                          this.setState({ manufacturer: e[0] });
                        }}
                        onSearch={(e) => {
                          store.dispatch(
                            mpnAutocompleteAction(
                              "manufacturer",
                              e,
                              this.state.activePartType?.id
                            )
                          );
                          this.setState({ manufacturer: e });
                        }}
                        options={this.state.autocompleteList}
                        placeholder="Manufacturer"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="my-1">
                    <Form.Label column lg={COL_W.lab.lg} sm={COL_W.lab.sm}>
                      Part Type
                    </Form.Label>
                    <Col lg={COL_W.field.lg} sm={COL_W.field.sm} className="">
                      <Select
                        size="sm"
                        classNamePrefix="lp-copy-sel"
                        isDisabled={this.state.mpnActive ? false : true}
                        name="part_type_id"
                        defaultInputValue={""}
                        options={partTypeMap}
                        styles={getSelectDarkStyle("sm")}
                        onChange={this.handlePartTypeChange}
                        // value={this.state.activePartType}
                        value={this.generateSelectFromPartType(
                          this.state.activePartType
                        )}
                      />
                    </Col>
                    <Col>
                      {!this.state.is_add && (
                        <Link
                          to={TO_PART_TYPE}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                         <Badge bg="secondary">?</Badge>
                        </Link>
                      )}
                    </Col>
                  </Form.Group>
                </fieldset>

                {/* Main part parameters*/}
                <ParameterTable
                  partTypeFields={this.state.partTypeFields}
                  activePartType={this.state.activePartType?.id}
                  fieldCallback={this.handleParameterChange}
                  parameters={this.state.parameters}
                  suggestions={this.props.suggestions}
                  autocomplete={this.state.autocompleteList}
                  autocompleteLoading={this.props.autocompleteLoading}
                />

                {/* Action buttons */}
                <Row className="my-4">
                  <Col className="">
                    <Link to={TO_MPN}>
                      <Button className="btn btn-outline-light float-left">
                        Exit without saving
                      </Button>
                    </Link>
                    {/* <Link to={actionButtonLocation}> */}
                    <Button
                      className="btn btn-outline-info float-end mx-2"
                      disabled={this.state.mpnActive ? false : true}
                      onClick={this.handleSubmit}
                    >
                      {button_text}
                    </Button>
                    {/* </Link> */}
                    {this.state.is_add ? (
                      ""
                    ) : (
                      <Button
                        className={
                          this.state.mpnActive
                            ? "btn btn-outline-danger float-end"
                            : "btn btn-outline-success float-end"
                        }
                        onClick={() => this.setState({ modalIsOpen: true })}
                      >
                        {this.state.mpnActive ? "Deactivate" : "Activate"}
                      </Button>
                    )}
                  </Col>
                </Row>
                {/*                  Modal                  */}
                <Modal
                  show={this.state.modalIsOpen}
                  onHide={() => this.setState({ modalIsOpen: false })}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {" "}
                      {this.state.mpnActive ? "Deactivate" : "Activate"} MPN
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to{" "}
                    {this.state.mpnActive ? "deactivate" : "activate"}{" "}
                    <b>{this.state.title_text}</b>?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-danger"
                      onClick={() => {
                        this.setState({ modalIsOpen: false });
                        store.dispatch(
                          deactivateMPNAction(id, {
                            active: this.state.mpnActive ? false : true,
                          })
                        );
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      onClick={() => this.setState({ modalIsOpen: false })}
                    >
                      No
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/*                 Modal                     */}
              </Form>
              {/*                      Table                     */}
              <SymbolsTable />
              <br />
            </Tab>
            <Tab
              eventKey={"#files"}
              title={
                <>
                  Files &nbsp;&nbsp;
                  <Badge className="bg-secondary">
                    {this.props.mpnFiles.length}
                  </Badge>
                </>
              }
            >
              <Row>
                <Col>
                  <h5 style={{ display: "inline-block" }}>Files</h5>
                  &nbsp; &nbsp; &nbsp;
                  <em className="text-muted">(accepts drag &amp; drop)</em>
                  <div>
                    <FileView
                      files={this.props.mpnFiles}
                      deleteFileAction={this.handleDelete}
                      filesLoading={this.props.mpnFilesLoading}
                      addFileLoading={this.props.addMpnFileLoading}
                      uploader={this.uploadMultiFile}
                      height={this.state.fileViewHeight}
                      downloadFileAction={downloadFileAction}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={"6"}>
                  <Row className="my-3">
                    <Col md={"5"}>
                      <Form.Control
                        type="file"
                        size={"sm"}
                        onChange={this.fileHandler}
                      />
                    </Col>
                    <Col>
                      <button
                        className="w-100 btn btn-outline-light"
                        size="sm"
                        onClick={this.uploadFile}
                        // disabled={
                        //   this.state.selectedFile == null || !this.props.status
                        //     ? true
                        //     : false
                        // }
                      >
                        Upload
                      </button>
                    </Col>
                    {"  "}

                    <Col>
                      <button
                        className="btn w-100 btn-outline-light"
                        size="sm"
                        variant="outline-light"
                        // disabled={this.props.status ? false : true}
                        onClick={() => {
                          console.log(id);
                          downloadAllMpnFilesAction(id);
                        }}
                      >
                        <i className="fa fa-download"></i> All
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey={"#comments"}
              title={
                <>
                  Comments &nbsp;&nbsp;
                  <Badge className="bg-secondary">
                    {this.props.mpnCommentsList.length}
                  </Badge>
                </>
              }
            >
              <CommentView commentsList={this.props.mpnCommentsList} />
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  store.dispatch(
                    addMpnCommentAction(id, { comment: this.state.comment })
                  );
                  this.setState({ comment: "" });
                  console.log("comment submitted");
                }}
              >
                <Editor
                  preview="edit"
                  value={this.state.comment}
                  onChange={(value) => this.setState({ comment: value })}
                />
                <button type="submit" className="btn btn-outline-info my-2">
                  Comment
                </button>
              </Form>
              <br />
              <br />
            </Tab>
            <Tab eventKey={"#history"} title="History">
              <h1>Histoy Tab</h1>
            </Tab>
            {window.location.pathname !== "/mpn/add" && (
              <Tab eventKey={"#membership"} title="GPN Membership">
                <GpnMembership
                  module1={this.props.module1}
                  module1Headings={this.props.module1Headings}
                  module1Loading={this.props.module1Loading}
                />
              </Tab>
            )}
          </Tabs>
        </Container>
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    partTypes: state.inventory.partType,
    partTypeRaw: state.inventory.partTypeRaw,
    partTypeLoading: state.inventory.partTypeLoading,

    mpnWithParams: state.inventory.parameters,
    mpnLoading: state.inventory.parametersLoading,

    autocomplete: state.inventory.mpnAutocomplete,
    autocompleteList: state.inventory.mpnAutocompleteRaw,
    autocompleteLoading: state.inventory.mpnAutocompleteLoading,

    suggestions: state.inventory.paramSuggestions,
    suggestionsLoading: state.inventory.paramSuggestionsLoading,
    addMpn: state.inventory.addMpn,
    addMpnLoading: state.inventory.addMpnLoading,

    mpnFiles: state.inventory.mpnFiles,
    mpnFilesLoading: state.inventory.mpnFilesLoading,
    addMpnFile: state.inventory.addMpnFile,
    addMpnFileLoading: state.inventory.addMpnFileLoading,

    mpnCommentsList: state.comments.getMpnComments,
    mpncommentsLoading: state.comments.getMpnCommentsLoading,
  };
};
export default connect(stateMap)(withRouter(AddEditMPN));
