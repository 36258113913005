import { COMMENTS_API } from "../constants";
import { http } from "../http";

export const getComment = async (id) => {
  return http.get(COMMENTS_API + id);
};
export const updateComment = async (id, data) => {
  return http.get(COMMENTS_API+'/' + id, data);
};
export const deleteComment = async (id) => {
  return http.delete(COMMENTS_API + "/" + id);
};
export const addReply = async (id, data) => {
  return http.post(COMMENTS_API + `/${id}/replies`, data);
};
export const getListReplies = async (id) => {
  return http.get(COMMENTS_API + `/${id}/replies`);
};
export const addReaction = async (id, data) => {
  return http.post(COMMENTS_API + `/${id}/reactions`, data);
};
export const getListReactions = async (id) => {
  return http.get(COMMENTS_API + `/${id}/reactions`);
};
export const getSingleReaction = async (id) => {
  return http.get(COMMENTS_API + `/reactions/${id}`);
};
export const deleteSingleReaction = async (id) => {
  return http.delete(COMMENTS_API + `/reactions/${id}`);
};
