import React, { Component } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { getSelectDarkStyle } from "../../../helpers/filter-dropdown-dark";
import { loadOptions } from "../../bom/bom-common";
import Select from "react-select";
import {
  createAltAction,
  getAltClassesAction,
  getALTERNATIVEListAction,
  updateAltAction,
} from "../../../actions/inventory/alternative-action";
import store from "../../../store";
import Slider from "@mui/material/Slider";

export default class AddEditAlternative extends Component {
  constructor(props) {
    super(props);
    this.state = {
      altClasses: [],
      class: "",
      classTitle: "",
      base: "",
      baseGpn: "",
      alt: "",
      altGpn: "",
      active: false,
      note: "",
      addPage: false,
    };
    this.loadOptions = loadOptions.bind(this);
  }
  componentDidMount() {
    getAltClassesAction();
    this.setState({ altClasses: this.props.altClasses });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.altClasses) !==
      JSON.stringify(this.props.altClasses)
    ) {
      this.setState({ altClasses: this.props.altClasses });
    }
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.setState({
        base: this.props.baseGpn,
        baseGpn: this.props.baseGpnValue,
        alt: this.props.alt,
        altGpn: this.props.altGpnValue,
        classTitle: this.props.altClass,
        class: this.props.altClassValue,
        note: this.props.note,
        active: this.props.active,
      });
      document.title = !this.props.altTitle ? "Add Alternative" : `${this.props.altTitle} on Incontrol`
    }
  }
  handleAddSubmit = () => {
    const data = {
      alternative_class_id: this.state.class,
      base_group_pn_id: this.state.baseGpn,
      alternative_group_pn_id: this.state.altGpn,
      active: this.state.active,
      note: this.state.note,
    };
    console.log(data);
    const page = window.location.pathname;
    const id = this.props.alt_id
    if (page === "/alternatives/add") {
      store.dispatch(createAltAction(data));
    } else {
      updateAltAction(id, data);
    }
  };

  handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (
      (event.ctrlKey || event.metaKey) &&
      (charCode === "s") ^ (event.key === "Enter")
    ) {
      event.preventDefault();
      this.handleAddSubmit();
    }
  };

  render() {
    const altMarks = [
      {
        value: 0,
        label: "Strongly Avoid",
      },
      { value: 10 },
      { value: 20 },
      { value: 30 },
      { value: 40 },
      {
        value: 50,
        label: "Neutral",
      },
      { value: 60 },
      { value: 70 },
      { value: 80 },
      { value: 90 },
      {
        value: 100,
        label: "Strongly prefer",
      },
    ];

    const options = this.state.altClasses.map((el) => ({
      id: el.id,
      value: el.id,
      description: el.description,
      key: el.key,
      label: el.name,
    }));
    const page = window.location.pathname;
    let addPage = false;
    if (page === "/alternatives/add") {
      addPage = true;
    } else {
      addPage = false;
    }
    return (
      <div className="main-section">
        <Container>
          <Form onKeyDown={this.handleKeyDown}>
            <Row>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="mb-2"
              >
                <h3 className="py-4">
                  {addPage ? "Add Alternative" : this.props.altTitle}
                </h3>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="3">
                    Base GPN
                  </Form.Label>
                  <Col lg={"8"} sm={"10"} className="px-4">
                    {!addPage ? (
                      this.props.baseGpn && (
                        <AsyncSelect
                          cacheOptions
                          size="sm"
                          loadOptions={this.loadOptions}
                          classNamePrefix="lp-copy-sel"
                          styles={getSelectDarkStyle("md")}
                          defaultOptions
                          defaultValue={{
                            label: `[${this.props.baseGpn}] ${this.props.baseDesc}`,
                            value: this.props.baseGpnValue,
                          }}
                          onChange={(el) =>
                            this.setState({ baseGpn: el.value })
                          }
                          placeholder="Type to search GPN"
                        />
                      )
                    ) : (
                      <AsyncSelect
                        cacheOptions
                        size="sm"
                        loadOptions={this.loadOptions}
                        classNamePrefix="lp-copy-sel"
                        styles={getSelectDarkStyle("md")}
                        defaultOptions
                        onChange={(el) => this.setState({ baseGpn: el.value })}
                        placeholder="Type to search GPN"
                      />
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="3">
                    Alternative GPN
                  </Form.Label>
                  <Col lg={"8"} sm={"10"} className="px-4">
                    {!addPage ? (
                      this.props.altGpn && (
                        <AsyncSelect
                          cacheOptions
                          size="sm"
                          classNamePrefix="lp-copy-sel"
                          defaultValue={{
                            label: `[${this.props.altGpn}] ${this.props.altDesc}`,
                            value: this.props.altGpnValue,
                          }}
                          loadOptions={this.loadOptions}
                          styles={getSelectDarkStyle("md")}
                          defaultOptions
                          //   onInputChange={this.handleInputChange}
                          onChange={(el) => this.setState({ altGpn: el.value })}
                          placeholder="Type to search GPN"
                        />
                      )
                    ) : (
                      <AsyncSelect
                        cacheOptions
                        size="sm"
                        classNamePrefix="lp-copy-sel"
                        loadOptions={this.loadOptions}
                        styles={getSelectDarkStyle("md")}
                        defaultOptions
                        onChange={(el) => this.setState({ altGpn: el.value })}
                        placeholder="Type to search GPN"
                      />
                    )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="3">
                    Alternative Class
                  </Form.Label>
                  <Col lg={"8"} sm={"10"} className="px-4">
                    {!addPage ? (
                      this.props.altClass && (
                        <Select
                          size="sm"
                          options={options}
                          styles={getSelectDarkStyle("md")}
                          defaultValue={{
                            label: this.props.altClass,
                            value: this.props.altClassValue,
                          }}
                          onChange={(el) => this.setState({ class: el.value })}
                          placeholder="Type to search Alternative Class"
                        />
                      )
                    ) : (
                      <Select
                        size="sm"
                        options={options}
                        styles={getSelectDarkStyle("md")}
                        onChange={(el) => this.setState({ class: el.value })}
                        placeholder="Type to search Alternative Class"
                      />
                    )}
                  </Col>
                  <Col>
                    <OverlayTrigger
                      placement={"right"}
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          {this.state.altClasses.map((el) => (
                            <p key={el.id}>
                              <b>{el.name}:</b> {el.description}
                            </p>
                          ))}
                        </Tooltip>
                      }
                    >
                      <Badge bg="secondary">?</Badge>
                    </OverlayTrigger>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintext">
                  <Col sm={"3"}>
                    <Form.Label>Note</Form.Label>
                  </Col>
                  <Col lg={"8"} sm={"10"} className="px-4">
                    {!addPage ? (
                      this.props.note && (
                        <Form.Control
                          as="textarea"
                          type="text"
                          defaultValue={this.state.note}
                          placeholder=""
                          onChange={(e) =>
                            this.setState({ note: e.target.value })
                          }
                        />
                      )
                    ) : (
                      <Form.Control
                        as="textarea"
                        type="text"
                        placeholder=""
                        onChange={(e) =>
                          this.setState({ note: e.target.value })
                        }
                      />
                    )}
                    <Form.Group className="py-3" controlId="formBasicCheckbox">
                      <Col>
                        {!addPage ? (
                          this.props.active && (
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              label="Active"
                              defaultChecked={this.state.active}
                              onChange={() =>
                                this.setState({ active: !this.state.active })
                              }
                            />
                          )
                        ) : (
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Active"
                            defaultChecked={true}
                            onChange={() =>
                              this.setState({ active: !this.state.active })
                            }
                          />
                        )}
                      </Col>
                    </Form.Group>
                    {!addPage && this.props.priority && (
                      <Slider
                        className="span-style"
                        defaultValue={this.props.priority}
                        step={10}
                        track={false}
                        valueLabelDisplay="auto"
                        marks={altMarks}
                      />
                    )}
                    <Button
                      className="btn btn-block px-4 my-4"
                      size="md"
                      onClick={this.handleAddSubmit}
                      variant="outline-success"
                    >
                      Save
                    </Button>
                  </Col>
                </Form.Group>
                <Col lg={"8"} sm={"10"} className="ml-auto"></Col>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}
