import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, ProgressBar } from "react-bootstrap";

export default class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importStatus: [],
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.importStatus) !==
      JSON.stringify(this.props.importStatus)
    ) {
      this.setState({ importStatus: this.props.importStatus });
    }
  }
  render() {
    // console.log(this.props.importStatus);
    return (
      <div>
        <h2>Status</h2>
        {this.state.importStatus.map((el, ind) => {
          // let num = ind + 1
          return (
            <div className="py-2">
              <h4>
                {el.state === "FAILURE" && (
                  <>
                    <Button variant="outline-danger">
                      <i class="fa fa-times-circle" aria-hidden="true"></i>{" "}
                      Failure
                    </Button>
                    &nbsp;{el.title}
                  </>
                )}{" "}
              </h4>
              <h4>
                {el.state === "SUCCESS" && (
                  <>
                    <Button variant="outline-success">
                      <i class="fa fa-check-circle" aria-hidden="true"></i>{" "}
                      Success
                    </Button>
                    &nbsp; {el.title}
                  </>
                )}{" "}
              </h4>
              <h4>
                {el.state === "PROGRESS" && (
                  <>
                    <Button variant="outline-info">
                      <i class="fa fa-play-circle-o" aria-hidden="true"></i>{" "}
                      Progress
                    </Button>
                    &nbsp;{el.title}
                    <br />
                    <br />
                    <small>
                      {el.action_name}&nbsp;{el.action_at}&nbsp;out of&nbsp;
                      {el.action_total}
                      <ProgressBar animated now={40} />
                    </small>
                  </>
                )}{" "}
              </h4>
              <h4>
                {el.state === "PENDING" && (
                  <>
                    <Button variant="outline-warning">
                      <i class="fa fa-pause-circle-o" aria-hidden="true"></i>{" "}
                      Pending
                    </Button>
                    &nbsp; {el.title}
                  </>
                )}{" "}
              </h4>
            </div>
          );
        })}
      </div>
    );
  }
}
