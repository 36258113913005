import React, { Component, Fragment } from "react";
import { getGPNListAction } from "../../../actions/inventory/gpn-action";
import Header from "../../../components/header/header";
import AddEditGPN from "../../../components/inventory/gpn/add-edit-gpn";
import Search from "../../../components/shared/search/search";
import { stringToInt, updateQueryParams } from "../../../helpers/utility";
import store from "../../../store";

export default class AddGPNContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: {},
    };

    this.headerSearch = this.headerSearch.bind(this);
  }
  componentDidMount() {
    document.title = "Add GPN";
  }

  headerSearch(searchQuery) {
    // window.location.replace(window.location.pathname.split("/add")[0]);
    window.location.replace(`/gpn?search_query=${searchQuery}`);
    // this.setState(
    //   {
    //     requestContent: {
    //       ...this.state.requestContent,
    //       search_query: searchQuery,
    //     },
    //   },
    //   () => {
    //     updateQueryParams(this.state.requestContent);
    //     this.getData(this.state.requestContent);
    //   }
    // );
  }
  getData(requestContent = {}, type = "list") {
    requestContent = stringToInt(requestContent);
    console.log("getData: ", requestContent);
    store.dispatch(
      getGPNListAction({ request_content: requestContent, request_type: type })
    );
  }

  render() {
    // console.log(window.location.pathname.split("/add"));
    return (
      <Fragment>
        <Header>
          <Search
            search={this.headerSearch}
            queryParams={this.state.queryParams}
          />
        </Header>
        <AddEditGPN />
      </Fragment>
    );
  }
}
