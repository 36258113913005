import { getAbout } from "../../api/about/about-api";
import { init } from "../../api/http";
import { GET_ABOUT, GET_ABOUT_LOADING } from "../../helpers/constants";
import { errorToast } from "../../helpers/utility";
import store from "../../store";

export async function aboutAction() {
  init();
  store.dispatch({
    type: GET_ABOUT_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GET_ABOUT,
    payload: {},
  });
  try {
    const res = await getAbout();

    if (res.data) {
      store.dispatch({
        type: GET_ABOUT,
        payload: res.data,
      });
      store.dispatch({
        type: GET_ABOUT_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: GET_ABOUT_LOADING,
      payload: false,
    });
  }
}
