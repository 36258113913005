// "Files" view presented on the BOM page

import "../../bom/bom.scss";

import React, { Component } from "react";

import { Button, Modal, ProgressBar } from "react-bootstrap";
import Dropzone from "react-dropzone";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import getIconNameFromMimetype from "../../../helpers/maps";
import { bytesToSize } from "../../../helpers/utility";
import Loading from "../loader/spinner";
import { updateFileAction } from "../../../actions/bom/bom-action";

// One file item on the list
class FileItem extends Component {
  constructor(props) {
    super(props);

    const fileDescription =
      this.props.file.description != null ? this.props.file.description : "";

    const fileId = this.props.file.uuid != null ? this.props.file.uuid : "";

    this.state = {
      modalIsOpen: false,
      uuid: fileId,
      fileDescription: fileDescription,
    };
  }

  setDescription = (evt) => {
    const val = evt.target.value;
    this.setState({
      fileDescription: val,
    });
  };

  render() {
    let date = this.props.file.time_created;
    const day = date.split("T")[0];
    const time = date.split("T")[1].split(".")[0];

    return (
      <>
        <div
          className="d-flex bom-file-item justify-content-between w-100 mx-auto px-3 pt-2 my-2"
          key={this.props.index}
        >
          {/* File name */}
          <div>
            <div className="bom-file-name">
              <FontAwesomeIcon
                icon={getIconNameFromMimetype(this.props.file.mimetype)}
                style={{ color: "#bbb" }}
              />
              &nbsp; &nbsp;
              <b>{this.props.file.name}</b>
              &nbsp;
              <small style={{ color: "#aaa" }}>
                {bytesToSize(this.props.file.size)}
              </small>
              &nbsp;
              <i>
                <font size={1}>
                  Created {day + " " + time} by{" "}
                  {this.props.file.updated_by_name}
                </font>
              </i>
            </div>

            {/* File description */}
            <div className="bom-file-description">
              <input
                value={this.state.fileDescription}
                onChange={this.setDescription}
                onBlur={() => {
                  console.log("Saved!");
                  updateFileAction(this.state.uuid, {
                    description: this.state.fileDescription,
                  });
                }}
                size="sm"
                className="file-description-form-field"
              />
            </div>
          </div>

          {/* Download */}
          <div className="d-flex align-items-center">
            <div className="px-1">
              <i
                className="fa fa-download text-info cursor-pointer font-weight-bold"
                style={{ fontSize: "1.2rem" }}
                onClick={() =>
                  this.props.downloadFileAction(
                    this.props.file.uuid,
                    this.props.file.name
                  )
                }
              ></i>
            </div>
            &nbsp;&nbsp;
            {/* Delete */}
            <div className="px-1">
              <i
                className="fa fa-times text-light cursor-pointer"
                style={{ fontSize: "1.2rem" }}
                onClick={() => this.setState({ modalIsOpen: true })}
              ></i>
            </div>
          </div>
        </div>

        {/*             Modal           */}

        <Modal
          show={this.state.modalIsOpen}
          onHide={() => this.setState({ modalIsOpen: false })}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete <b>{this.props.file.name}</b>?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                this.props.deleteFileAction(
                  this.props.file.uuid,
                  this.props.index
                );
                this.setState({ modalIsOpen: false });
              }}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => this.setState({ modalIsOpen: false })}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>

        {/*            Modal            */}
      </>
    );
  }
}

// The list of files
export default class FileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filesLoading: false,
    };
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     JSON.stringify(prevProps.filesLoading) !==
  //     JSON.stringify(this.props.filesLoading)
  //   ) {
  //     console.log("Loading changed");
  //     this.setState({ filesLoading: this.props.filesLoading });
  //   }
  // }
  onDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
    this.props.uploader(acceptedFiles);
  };

  hoverCloudContent = (
    <div className="bom-upload-cloud">
      <div className="bom-cloud-message">
        <h1>
          {" "}
          <FontAwesomeIcon icon="cloud-upload-alt" />
        </h1>
        <em>Go ahead, leave that here</em>
      </div>
    </div>
  );

  render() {
    return (
      <>
        {this.props.filesLoading ? (
          <div className="text-center">
            <Loading />
          </div>
        ) : (
          <Dropzone
            onDrop={this.onDrop}
            minSize={0}
            maxSize={1024 * 1024 * 25}
            multiple={true}
            noClick={true}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div
                    className="bom-file-container"
                    style={{
                      height: this.props.height,
                    }}
                  >
                    {isDragActive ? this.hoverCloudContent : ""}

                    <div className="bom-file-scroll-window">
                      {this.props.files &&
                        this.props.files.map((el, index) => (
                          <FileItem
                            file={el}
                            key={el.uuid}
                            downloadFileAction={this.props.downloadFileAction}
                            deleteFileAction={this.props.deleteFileAction}
                          />
                        ))}
                    </div>
                  </div>
                  {this.props.addFileLoading ? (
                    <ProgressBar
                      className="mt-4"
                      animated
                      variant="info"
                      now={100}
                      label={"Uploading..."}
                    />
                  ): ""}
                </div>
              </section>
            )}
          </Dropzone>
        )}
      </>
    );
  }
}
