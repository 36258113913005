import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import GPNListContainer from "../containers/inventory/gpn/gpn-list-container";
import AddGPNContainer from "../containers/inventory/gpn/add-gpn-container";
import MPNListContainer from "../containers/inventory/mpn/mpn-list-container";
import AddMPNContainer from "../containers/inventory/mpn/add-mpn-container";
import UnauthenticatedRoute from "../helpers/UnauthenticatedRoute";
import LoginContainer from "../containers/user/login/Login";
import { getToken, toast } from "../helpers/utility";
import {
  TO_GPN,
  TO_MPN,
  ADD_GPN,
  ADD_MPN,
  ADD_BOM,
  TO_BOM,
  IMPORT_BOM,
  IMPORTS_BOM,
  IMPORT_DETAILS,
  EDIT_GPN,
  EDIT_BOM,
  EDIT_MPN,
  TO_BULK,
  TO_ALTER,
  EDIT_ALTER,
  ADD_ALTER,
  TO_ADMIN,
  TO_ABOUT,
  NOT_FOUND,
  TO_PART_TYPE,
  DOCS,
} from "../helpers/constants";
import AuthenticatedRoute from "../helpers/AuthenticatedRoute";
import AddBomContainer from "../containers/bom/add-bom-container";
import BomListContainer from "../containers/bom/bom-list-container";
import EditBomContainer from "../containers/bom/edit-bom-container";
import importListContainer from "../containers/bom/import-list-container";
import viewImportContainer from "../containers/bom/view-import-container";
import EditGPNContainer from "../containers/inventory/gpn/edit-gpn-container";
import EditMPNContainer from "../containers/inventory/mpn/edit-mpn-container";
import bulkContainer from "../containers/inventory/bulk/bulk-container";
import alternativeContainer from "../containers/inventory/alternatives/alternative-list-container";
import AddAlternativeContainer from "../containers/inventory/alternatives/add-alternative-container";
import EditAlternativeContainer from "../containers/inventory/alternatives/edit-alternative-container";
import AdminContainer from "../containers/user/login/Admin";
import AboutContainer from "../containers/about/about-container";
import NotFoundContainer from "../containers/404";
import Partype from "../components/shared/table/partype";
import docs from "../components/docs/docs";

export default () => {
  let isAuthenticated = false;
  try {
    const token = getToken();
    if (token) isAuthenticated = true;
    else throw "User not authorized";
  } catch (e) {
    console.info(e);
  }
  // console.log(window.location.search.replace('q','search_query'))

  return (
    <Switch>
      <Route exact path={"/"} render={() => <Redirect to={TO_GPN} />} />

      {/* <Route exact path={NOT_FOUND} component={NotFoundContainer} /> */}
      <Route
        path={"/search"}
        render={() => (
          // window.location.pathname.replace("/search", "/mpn")
          <Redirect
            to={"/mpn" + window.location.search.replace("q", "search_query")}
          />
        )}
      />
      <UnauthenticatedRoute
        exact
        path={"/login"}
        component={LoginContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={TO_ADMIN}
        component={AdminContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={TO_GPN}
        component={GPNListContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={ADD_GPN}
        component={AddGPNContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={EDIT_GPN}
        component={EditGPNContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={TO_MPN}
        component={MPNListContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={ADD_MPN}
        component={AddMPNContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={EDIT_MPN}
        component={EditMPNContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={TO_BOM}
        component={BomListContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={ADD_BOM}
        component={AddBomContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={EDIT_BOM}
        component={EditBomContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={IMPORTS_BOM}
        component={importListContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={IMPORT_DETAILS}
        component={viewImportContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={TO_BULK}
        component={bulkContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={TO_ALTER}
        component={alternativeContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={EDIT_ALTER}
        component={EditAlternativeContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={ADD_ALTER}
        component={AddAlternativeContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={TO_ABOUT}
        component={AboutContainer}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={TO_PART_TYPE}
        component={Partype}
        appProps={{ isAuthenticated }}
      />
      <AuthenticatedRoute
        exact
        path={DOCS}
        component={docs}
        appProps={{ isAuthenticated }}
      />
      <Route path={"*"} component={NotFoundContainer} />
    </Switch>
  );
};
