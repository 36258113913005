import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import ReactTimeAgo from "react-time-ago";
import { deleteCommentAction } from "../../../actions/comments/comments-action";
// import { SlackSelector } from 'react-reactions';

export default class CommentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentList: [],
      content: "Some **bold** and _italic_ text",
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.commentsList) !==
      JSON.stringify(this.props.commentsList)
    ) {
      this.setState({ commentList: this.props.commentsList });
    }
  }
  render() {
    //  console.log(this.props.commentsList);
    return (
      <div>
        <ListGroup>
          {this.props.commentsList &&
            this.props.commentsList.map((el) => {
              return (
                <>
                  <ListGroup.Item className="my-3 rounded">
                    {/* <SlackSelector /> */}
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <img
                          src={el.created_by?.image_url}
                          className="rounded-circle"
                          width={40}
                        />

                        <b className="px-2">{el.created_by_name}</b>
                        <small className="text-light">
                          <i>
                            @{el.created_by?.username} -{" "}
                            <ReactTimeAgo
                              date={el.time_created}
                              locale="en-US"
                            />
                          </i>
                        </small>
                      </div>
                      <div>
                        <i
                          class="fa fa-pencil"
                          style={{ fontSize: 20, cursor: "pointer" }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;&nbsp;
                        <i
                          class="fa fa-trash"
                          onClick={() => deleteCommentAction(el.id)}
                          style={{ fontSize: 20, cursor: "pointer" }}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <div className="px-4 my-4">
                      <ReactMarkdown children={el.comment} />
                    </div>
                    <small className="text-light px-4">
                      <i>
                        Edited by {el.updated_by_name}{" "}
                        <ReactTimeAgo date={el.time_updated} locale="en-US" />
                      </i>
                    </small>
                  </ListGroup.Item>
                  {/* <ListGroup.Item>
                  <ReactMarkdown children={this.state.content} />
                </ListGroup.Item> */}
                </>
              );
            })}
        </ListGroup>
      </div>
    );
  }
}
