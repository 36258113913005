import "./table.scss";

import React, { Component } from "react";

import classNames from "classnames";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
  Table,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import StickyTable from "react-sticky-table-thead";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { updateQueryParams } from "../../../helpers/utility";
import Loader from "../loader/loader";

export default class CustomTable extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    this.state = {
      activeRow: 1,
      queryParams: this.props.queryParams,
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.exportTable = this.exportTable.bind(this);
  }
  componentDidMount() {
    this.setState({
      queryParams: this.props.queryParams,
      // activeRow: this.props.data && Number(this.props.data[0].id),
    });

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeRow !== prevState.activeRow) {
      this.props.getId(this.state.activeRow);
    }
    if (
      JSON.stringify(this.props.queryParams) !==
      JSON.stringify(prevProps.queryParams)
    ) {
      this.setState({
        queryParams: this.props.queryParams,
        // activeRow: this.props.data[0].id,
      });
    }
    if (
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) &&
      this.props.data.length > 0
    ) {
      this.setState({
        activeRow: this.props.data && Number(this.props.data[0].id),
      });
    }
  }

  resetFilter = () => {
    this.loadData({});
  };

  handleRowClick = (id) => {
    this.setState({ activeRow: id });
    this.props.getId(id);
  };

  handleKeyDown(e) {
    const { cursor, result } = this.state;
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && cursor > 0) {
      this.setState((prevState) => ({
        cursor: prevState.cursor - 1,
      }));
    } else if (e.keyCode === 40 && cursor < result.length - 1) {
      this.setState((prevState) => ({
        cursor: prevState.cursor + 1,
      }));
    }
  }

  handlePageClick = (data) => {
    let queryParams = this.state.queryParams;
    queryParams.page_number = data.selected + 1; //ensureing data type
    this.loadData(queryParams);
  };

  onChange = (e, parent = null) => {
    let queryParams = this.state.queryParams;
    if (!parent) {
      queryParams[e.target.name] = e.target.value; //ensureing data type
    } else {
      let isFound = false;
      let parentarray = parent.split(":");
      const column = parentarray[0];
      parent = parentarray[1];
      if (!queryParams[parent]) queryParams[parent] = [];
      queryParams[parent].forEach((elem) => {
        if (elem.column === column) {
          elem[e.target.name] = e.target.value;
          isFound = true;
        }
      });
      if (!isFound)
        queryParams[parent].push({
          matching: "contains",
          query: "",
          column,
          ...{ [e.target.name]: e.target.value },
        });
    }
    this.loadData(queryParams);
  };

  updateSorting(column, direction) {
    console.log(column, direction);
    let queryParams = this.state.queryParams;
    let isFound = false;
    let removeIndex = -1;
    const parent = "sort_options";
    if (!queryParams[parent]) queryParams[parent] = [];
    queryParams[parent].forEach((elem, index) => {
      if (elem.column === column) {
        if (elem["direction"] === direction) removeIndex = index;
        elem["direction"] = direction;
        isFound = true;
      }
    });
    if (removeIndex > -1) {
      queryParams[parent].splice(removeIndex, 1);
    }
    if (!isFound)
      queryParams[parent].push({
        // priority: queryParams[parent].length + 1,
        direction,
        column,
      });
    this.loadData(queryParams);
  }

  loadData(queryParams) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      updateQueryParams(queryParams);
      this.setState({ queryParams });
      this.props.tableFilters(queryParams);
    }, 1000);
  }

  exportTable() {
    this.props.tableFilters(this.state.queryParams, "csv");
  }
  render() {
    const inventory = window.location.pathname;
    return (
      <div className="left-content">
        <Container>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Row className="d-flex justify-content-between">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className="d-flex justify-content-between mb-1 main-table-buttons">
                    <Button
                      variant="secondary"
                      className="reset-filters-btn btn-secondary btn-sm"
                      onClick={this.resetFilter}
                    >
                      Reset Filters &nbsp;&nbsp;
                      <FontAwesomeIcon icon="eraser" />
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={this.exportTable}
                      className="export-table btn-secondary btn-sm"
                    >
                      Export Table &nbsp;&nbsp;
                      <FontAwesomeIcon icon="file-export" />
                    </Button>
                    <div>
                      <Form.Select
                        className="btn-secondary btn-sm"
                        defaultValue={100}
                        variant="primary"
                        onChange={this.onChange}
                        value={this.state.queryParams.items_per_page}
                        name="items_per_page"
                        aria-label="Default select example"
                      >
                        <option value="10">Results per page (10)</option>
                        <option value="30">Results per page (30)</option>
                        <option value="60">Results per page (60)</option>
                        <option value="100">Results per page (100)</option>
                        <option value="500">Results per page (500)</option>
                      </Form.Select>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className="main-table-section">
                    {this.props.isLoading && <Loader></Loader>}
                    <StickyTable height={this.state.height - 160}>
                      <Table responsive variant="dark">
                        <thead>
                          <tr>
                            <th className="width-60"> </th>
                            {this.props.columns.map((column) => (
                              <th key={column.key}>
                                <div className="d-flex justify-content-between align-items-center my-n4">
                                  <span>{column.label}</span>
                                  <div className="filters-section">
                                    <OverlayTrigger
                                      trigger="click"
                                      placement="bottom"
                                      rootClose={true}
                                      overlay={
                                        <Popover>
                                          <Popover.Body>
                                            <Dropdown.Item>
                                              <Form.Select
                                                name="matching"
                                                value={
                                                  this.state.queryParams
                                                    .filter_options &&
                                                  this.state.queryParams.filter_options.find(
                                                    (elem) =>
                                                      elem.column === column.key
                                                  )
                                                    ? this.state.queryParams.filter_options.find(
                                                        (elem) =>
                                                          elem.column ===
                                                          column.key
                                                      ).matching
                                                    : ""
                                                }
                                                onChange={(e) =>
                                                  this.onChange(
                                                    e,
                                                    column.key +
                                                      ":filter_options"
                                                  )
                                                }
                                                aria-label="Default select example"
                                              >
                                                <option value="contains">
                                                  Contains
                                                </option>
                                                <option value="equals">
                                                  Equals
                                                </option>
                                                <option value="startswith">
                                                  Starts With
                                                </option>
                                                <option value="wildcard">
                                                  Wildcard
                                                </option>
                                                <option value="regex">
                                                  Regex
                                                </option>
                                                <option value="greaterthan">
                                                  Greater Than
                                                </option>
                                                <option value="lessthan">
                                                  Less Than
                                                </option>
                                              </Form.Select>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                              <Form>
                                                <Form.Control
                                                  type="text"
                                                  defaultValue={
                                                    this.state.queryParams
                                                      .filter_options &&
                                                    this.state.queryParams.filter_options.find(
                                                      (elem) =>
                                                        elem.column ===
                                                        column.key
                                                    )
                                                      ? this.state.queryParams.filter_options.find(
                                                          (elem) =>
                                                            elem.column ===
                                                            column.key
                                                        ).query
                                                      : ""
                                                  }
                                                  onChange={(e) =>
                                                    this.onChange(
                                                      e,
                                                      column.key +
                                                        ":filter_options"
                                                    )
                                                  }
                                                  name="query"
                                                  placeholder="Normal text"
                                                />
                                              </Form>
                                            </Dropdown.Item>
                                          </Popover.Body>
                                        </Popover>
                                      }
                                    >
                                      <div
                                        variant="secondary"
                                        title=""
                                        className="mt-1 mb-1 px-2 filter-dropdown"
                                      >
                                        <i className="fa fa-filter"></i>
                                      </div>
                                    </OverlayTrigger>
                                    <div className="sort-unsort px-1 mt-1 mb-1">
                                      <div
                                        onClick={(e) =>
                                          this.updateSorting(
                                            column.key,
                                            "ascending"
                                          )
                                        }
                                        name="direction"
                                        value="ascending"
                                        className={classNames(
                                          {
                                            active: this.state.queryParams
                                              .sort_options
                                              ? this.state.queryParams.sort_options.find(
                                                  (elem) =>
                                                    elem.column ===
                                                      column.key &&
                                                    elem.direction ===
                                                      "ascending"
                                                )
                                              : false,
                                          },
                                          "sort-icon"
                                        )}
                                      >
                                        <i
                                          className="fa fa-sort-asc"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                      <div
                                        onClick={(e) =>
                                          this.updateSorting(
                                            column.key,
                                            "descending"
                                          )
                                        }
                                        name="direction"
                                        value="descending"
                                        className={classNames(
                                          {
                                            active: this.state.queryParams
                                              .sort_options
                                              ? this.state.queryParams.sort_options.find(
                                                  (elem) =>
                                                    elem.column ===
                                                      column.key &&
                                                    elem.direction ===
                                                      "descending"
                                                )
                                              : false,
                                          },
                                          "sort-icon"
                                        )}
                                      >
                                        <i
                                          className="fa fa-sort-desc"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.data.map((row, ind) => (
                            <tr
                              key={ind}
                              className={`row-animation py-0 ${
                                this.state.activeRow === row.id ? "active" : ""
                              } ${row.active ? "" : "text-muted"}`}
                              onClick={() => this.handleRowClick(row.id)}
                            >
                              <td>
                                <Link to={`${inventory}/${row.id}`}>
                                  <Button
                                    size="sm"
                                    className=" btn btn-secondary py-0"
                                  >
                                    Edit
                                  </Button>
                                </Link>
                              </td>
                              {Object.keys(row).map((key, ind) => {
                                if (!isNaN(key))
                                  return <td key={ind}>{row[key]}</td>;
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </StickyTable>
                  </div>
                </Col>
              </Row>
              <Row className="d-flex align-items-center">
                <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                  <div>
                    <p className="mb-0">
                      Search returned {this.props.pagination?.total_items}{" "}
                      results
                    </p>
                  </div>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                  <div className="main-table-pagination pagination-sm pt-3">
                    <ReactPaginate
                      previousLabel={"‹"}
                      nextLabel={"›"}
                      breakLabel={"..."}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      initialPage={0}
                      // forcePage={this.state.queryParams.page_number}
                      pageCount={this.props.pagination?.total_pages}
                      disableInitialCallback={true}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={3}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link"}
                      pageClassName={"page-item"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      onPageChange={this.handlePageClick}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
