// add-mpn-container.js
// Our wrapper for the add IPN page

import React, { Component, Fragment } from "react";
import Header from "../../../components/header/header";
import AddEditMPN from "../../../components/inventory/mpn/add-edit-mpn";
import Search from "../../../components/shared/search/search";

export default class AddMPNContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: {},
    };
    this.headerSearch = this.headerSearch.bind(this);
  }
  componentDidMount() {
    document.title = "Add MPN";
  }
  headerSearch(searchQuery) {
    window.location.replace(`/mpn?search_query=${searchQuery}`);
  }
  render() {
    return (
      <Fragment>
        <Header>
          <Search
            search={this.headerSearch}
            queryParams={this.state.queryParams}
          />
        </Header>
        <AddEditMPN />
      </Fragment>
    );
  }
}
