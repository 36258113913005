import React from "react";
import Form from "react-validation/build/form";
import Button from "react-bootstrap/Button";
import Input from "react-validation/build/input";
import { connect } from "react-redux";
import { setToken, getToken, errorToast } from "../../helpers/utility";
import { required } from "../../helpers/form-validation";
import store from "../../store";
import { init } from "../../api/http";
import { googleLoginAction, LoginAction } from "../../actions/user/user-action";
import "./login-form.scss";
import { GoogleLogin } from "@react-oauth/google";
import { TOKEN_COOKIE } from "../../helpers/constants";
import cookie from "react-cookies";
// var qs = require('qs');

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.state = {
      user: {
        username: "",
        password: "",
      },
      redirect: "",
      params: "",
    };
  }
  componentDidMount() {
    if (cookie.load(TOKEN_COOKIE)) {
      window.location.replace(window.location.search.split("?redirect=")[1]);
    }
  }
  handleChange(e) {
    let user = this.state.user;
    user[e.target.name] = e.target.value;
    this.setState({ user });
  }
  handleOnSubmit(e) {
    e.preventDefault();
    this.form.validateAll();
    if (this.form.getChildContext()._errors.length === 0) {
      store.dispatch(LoginAction(this.state.user)); //set in session storage
    } else {
      console.log("form vali error", this.form.getChildContext()._errors);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props && prevProps.token !== this.props.token) {
      setToken(this.props.token);
      window.location.replace(window.location.search.split("?redirect=")[1]);
    }
    if (cookie.load(TOKEN_COOKIE)) {
      window.location.replace(window.location.search.split("?redirect=")[1]);
    }
  }
  responseGoogleSuccess = (response) => {
    console.log(response);
    store.dispatch(googleLoginAction(response.credential));
  };
  responseGoogleFailure = (response) => {
    errorToast(
      "Oauth failed. Clear your site cookies (with ctrl+shift+R) and retry."
    );
  };
  render() {
    // console.log(window.location.search.split('?redirect=')[1]);
    return (
      !getToken() && (
        <Form
          className="w-100 d-flex justify-content-center align-items-center flex-column"
          ref={(c) => {
            this.form = c;
          }}
          onSubmit={this.handleOnSubmit}
        >
          <div className="form-group mb-2">
            <label htmlFor="email">User Name</label>
            <Input
              id="email"
              validations={[required]}
              className="input singup_width form-control"
              type="text"
              value={this.state.user.username}
              name="username"
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="password">Password</label>
            <Input
              id="password"
              validations={[required]}
              className="input singup_width form-control"
              type="password"
              value={this.state.user.password}
              name="password"
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group sign-button d-flex">
            <Button type="submit" className="signUp_button" variant="primary">
              Sign In
            </Button>
            &nbsp;&nbsp;&nbsp;
            <GoogleLogin
              // clientId="841626067022-i8u9mdn6mf3a900m8ot01d63q6fqjilq.apps.googleusercontent.com"
              // buttonText="Login"
              // data-theme="dark"
              onSuccess={credentialResponse => {
                this.responseGoogleSuccess(credentialResponse)
              }}
              onError={this.responseGoogleFailure}
              // hostedDomain={"intrepidcs.com"}
              // cookiePolicy={"single_host_origin"}
            />
          </div>
        </Form>
      )
    );
  }
}
// toast(((message = this.props.loginMessage), (type = "success")))
const stateMap = (state) => {
  return {
    token: state.user.token,
    loginMessage: state.user.loginMessage,
    refreshError: state.user.refreshError,
  };
};
export default connect(stateMap)(LoginForm);
