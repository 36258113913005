import { BASE_URL, BOMS_API, FILES_API, GENERAL_API } from "../constants";
import { http, init } from "../http";
import { bomList, lineItems } from "../exmple.json";

export const getBomList = async (data = {}) => {
  return http.get(BOMS_API, {
    params: { ...bomList, ...data },
  });
};

//     add bom screen
export const addBom = async (data) => {
  return http.post(BOMS_API, data);
};
export const getBomclasses = async () => {
  return http.get(BOMS_API + "/classes");
};

//  view/edit screen

export const getBomById = async (id) => {
  return http.get(BOMS_API + "/" + id);
};
export const getBomLineItems = async (id, data) => {
  init();
  return http.get(BOMS_API + "/revisions/" + id + "/lineitems", {
    params: {
      ...lineItems,
      ...data,
    },
  });
};
//   delete all lineItems
export const deleteAllLineItems = async (uuid) => {
  return http.delete(BOMS_API + `/revisions/${uuid}/lineitems`);
};

//   delete lineItems
export const deleteLineItem = async (id) => {
  return http.delete(BOMS_API + `/lineitems/${id}`);
};

export const getBomfiles = async (id) => {
  return http.get(BOMS_API + "/" + id + "/files");
};
export const addFile = async (id, data, progress) => {
  return http.post(BOMS_API + `/${id}/files`, data);
};
export const updateFile = async (uuid, data) => {
  return http.put(FILES_API + `/${uuid}`, data);
};
export const deleteFile = async (uuid) => {
  return http.delete(FILES_API + `/${uuid}`);
};
export const downloadFile = async (uuid) => {
  return http.get(FILES_API + `/${uuid}`, { responseType: "blob" });
};
export const downloadAllFiles = async (id) => {
  return http.get(BOMS_API + "/" + id + "/files?download=all", {
    responseType: "stream",
  });
};
export const generateFile = async (id) => {
  return http.post(BOMS_API + `/revisions/${id}/exports`, {
    run_export_groups: ["bom_standard"],
  });
};
export const updateBom = async (id, data) => {
  return http.put(BOMS_API + `/${id}`, data);
};
const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/merge-patch+json",
    Accept: "application/merge-patch+json",
  },
};
export const validateBom = async (id, data) => {
  return http.patch(BOMS_API + `/${id}`, data, config);
};
export const approveBom = async (id, data) => {
  return http.patch(BOMS_API + `/${id}`, data, config);
};

//    import screen

export const getImports = async (id) => {
  return http.get(BOMS_API + `/${id}/imports`);
};
export const getImportTable = async (uuid, data) => {
  return http.get(FILES_API + `/${uuid}/preview`, data);
};
export const addImport = async (id, data) => {
  data.columns = data.columns.filter((d) => d.type);
  return http.post(BOMS_API + `/revisions/${id}/imports`, data);
};
export const getImport = async (id) => {
  return http.get(BOMS_API + `/imports/${id}`);
};
export const deleteImport = async (id) => {
  return http.delete(BOMS_API + `/imports/${id}`);
};
export const validateImport = async (id, data) => {
  return http.patch(BOMS_API + `/imports/${id}`, data);
};
export const executeImport = async (id, data) => {
  return http.patch(BOMS_API + `/imports/${id}`, data);
};
export const deactivateBom = async (id, data) => {
  return http.patch(BOMS_API + `/${id}`, data, config);
};
export const importStatus = async (id) => {
  return http.get(GENERAL_API + `/tasks/${id}/status`);
};

export const newVersion = async (name, data) => {
  return http.post(BOMS_API + `/${name}/revisions`, data);
};

export const managedAlts = async (id) => {
  return http.get(BOMS_API + "/" + id + "/managedalts");
};

export const managePendingAlt = async (id) => {
  return http.get(BOMS_API + "/" + id + "/managedalts", {
    params: { source: "pending" },
  });
};
export const manageAcceptedAlt = async (id) => {
  return http.get(BOMS_API + "/" + id + "/managedalts", {
    params: { source: "accepted" },
  });
};
export const manageRejectedAlt = async (id) => {
  return http.get(BOMS_API + "/" + id + "/managedalts", {
    params: { source: "rejected" },
  });
};

export const manageAdditionAlt = async (id) => {
  return http.get(BOMS_API + "/" + id + "/managedalts", {
    params: { source: "suggested-addition" },
  });
};

export const manageDeletionAlt = async (id) => {
  return http.get(BOMS_API + "/" + id + "/managedalts", {
    params: { source: "suggested-deletion" },
  });
};

export const synchronizeBOM = async (id) => {
  return http.post(BOMS_API + "/" + id + "/managedalts/synchronize");
};

export const addAltUpdate = async (id, data) => {
  return http.post(BOMS_API + "/" + id + "/managedalts", data);
};

export const updateAltStatus = async (uuid, data) => {
  return http.patch(BOMS_API + "/managedalts/" + uuid, data, config);
};

export const lastSync = async (id) => {
  return http.get(BOMS_API + "/" + id + "/managedalts");
};

export const synchronizeBom = async (id) => {
  return http.post(BOMS_API + "/" + id + "/managedalts/synchronize");
};

//                 Comments

export const getAllBOMComments = async (id) => {
  return http.get(BOMS_API + `/${id}/comments`);
};

export const addBOMComment = async (id, data) => {
  return http.post(BOMS_API + `/${id}/comments`, data);
};

export const updateBomRev = async (id, data) => {
  return http.post(BOMS_API + `/revisions/${id}/update`, data);
};

export const errorCounterLineItems = async (uuid) => {
  return http.get(BOMS_API + `/revisions/${uuid}`);
};
