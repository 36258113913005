import React from 'react';
import validator from 'validator';

export const required = (value, props, components) => {
    if(!value)
        return <span className="error text-danger">Oops! You missed a field.</span>
    if (!value.toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return <span className="error text-danger">Oops! You missed a field.</span>
    }
};
export const email = (value) => {
    if(!value)
        return <span className="error text-danger">You missed inputting your email, silly.</span>
    if (!validator.isEmail(value)) {
        return <span className="error text-danger">`{value}` is not a valid email.</span>
    }
};

export const onlyAlpha = (value) => {
    if(!value)
        return <span className="error text-danger">This field is required.</span>
    if (!value.match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
        return <span className="error text-danger">Only alphabets allowed.</span>
    }
};

export const max20 = (value, props) => {
    if(!value)
        return <span className="error text-danger">This field is required.</span>
    if (value.toString().trim().length > 20) {
        return <span className="error text-danger">Field is too long.</span>
    }
};
export const max2 = (value, props) => {
    if(!value)
        return <span className="error text-danger">This field is required.</span>
    if (value.toString().trim().length > 2) {
        return <span className="error text-danger">Field is too long.</span>
    }
};
export const min3 = (value, props) => {
    if(!value)
        return <span className="error text-danger">This field is required.</span>
    if (value.toString().trim().length < 3) {
        return <span className="error text-danger">Field is too short.</span>
    }
};
export const min2 = (value, props) => {
    if(!value)
        return <span className="error text-danger">This field is required.</span>
    if (value.toString().trim().length < 2) {
        return <span className="error text-danger">Field is too short.</span>
    }
};
export const min8 = (value, props) => {
    if(!value)
        return <span className="error text-danger">You forgot to set a password!</span>
    if (value.toString().trim().length < 8) {
        return <span className="error text-danger">Password must contain a minimum of 8 characters.</span>
    }
};

export const password = (value, props, components) => {
    if (!components['password'][0].value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)) { // components['password'][0].value !== components['confirm'][0].value
        return <span className="error text-danger p-1">Password must include at least one uppercase, one lowercase, and one number.</span>
    }
};

export const match = (value, props, components) => {
    if (components['password'][0].value !== components['confirm'][0].value) { // components['password'][0].value !== components['confirm'][0].value
        return <span className="error text-danger p-1">Uh oh. Looks like the passwords don't match. Try again.</span>
    }
};
