import React, { Component } from "react";
import Header from "../../../components/header/header";
import AddEditGPN from "../../../components/inventory/gpn/add-edit-gpn";
import { useParams, withRouter } from "react-router-dom";
import Search from "../../../components/shared/search/search";

class EditGPNContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: {},
    };
    this.headerSearch = this.headerSearch.bind(this);
  }
  headerSearch(searchQuery) {
    window.location.replace(`/gpn?search_query=${searchQuery}`);
  }

  render() {
    const id = this.props.match.params.id;
    return (
      <div>
        <Header>
          <Search
            search={this.headerSearch}
            queryParams={this.state.queryParams}
          />
        </Header>
        <AddEditGPN gpn_id={id} />
      </div>
    );
  }
}

export default withRouter(EditGPNContainer);
