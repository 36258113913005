// import store from './store';
import "./App.scss";

import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { tokenWatcher } from "./helpers/utility";
import Routes from "./routes/Routes";

library.add(far, fas);

//basic compnent of which holds all routes
class App extends Component {
  componentDidMount() {
    tokenWatcher();
    document.addEventListener("keyup", (event) => {
      let charCode = String.fromCharCode(event.which).toLowerCase();
      if (
        (event.ctrlKey || event.metaKey) &&
        (charCode === "b") ^ (event.key === "Enter")
      ) {
        document.getElementsByClassName("search-bar")[1].focus();
      }
    });
  }

  render() {
    return (
      <div id="all" history={this.props.history}>
        <Routes socket={this.socket} />
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    // user: state.user.user,
  };
};
export default connect(stateMap)(withRouter(App));
