// A map of mimetypes to fontawesome icons
const mimetypeIconMap = {
    "text/csv": "file-csv",
    "application/vnd.ms-excel": "file-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "file-excel",
    "application/zip": "file-archive",
    "application/pdf": "file-pdf",
    "image/png": "file-image",
    "image/jpeg": "file-image",
    "default": "file-alt",
}

export default function getIconNameFromMimetype(mimetype) {
    const result = mimetypeIconMap[mimetype];
    return (result != null) ? result : mimetypeIconMap['default'];
}
