import { TO_DOC } from "../helpers/constants";

export default function docs(
    state = {
        doc: ''
    },
    action
) {
    switch (action.type) {
        case TO_DOC: {
            return { ...state, doc: action.payload };
        }
    }
    return state
}