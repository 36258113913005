import React, { Component, Fragment } from "react";
import { Accordion, Offcanvas, Table } from "react-bootstrap";
import Loading from "../../shared/loader/spinner";

class GpnMembership extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Fragment>
        <Table bordered responsive variant="dark" className="my-5">
          <thead>
            <tr>
              {this.props.module1Headings.map((el, ind) => {
                return <th key={ind}>{el.label}</th>;
              })}
            </tr>
          </thead>

          {this.props.module1Loading ? (
            <div className="text-center py-3">
              <Loading />
            </div>
          ) : (
            <tbody>
              {this.props.module1.map((elem, index) => {
                return (
                  <tr key={index}>
                    {Object.values(elem).map((el, ind) => {
                      return <td key={ind}>{el}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      </Fragment>
    );
  }
}

export default GpnMembership;
