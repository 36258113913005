import React, {Component} from 'react';
import { getDocByKey } from '../../actions/docs/docs-action';
import { connect } from "react-redux";
import parse from 'html-react-parser'

class Docs extends Component {
    constructor(props){
        super(props)
        this.state = {
            doc: ''
        }
    }

    componentDidMount(){
        const page_key = this.props.match.params.page_key
        getDocByKey(page_key)
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.doc !== this.props.doc){
            this.setState({
                doc: this.props.doc,
            })
        }
    }

    render(){
        return parse(this.state.doc)
    }
}

const stateMap = (state) => {
    return {
        doc: state.docs.doc
    }
}

export default connect(stateMap)(Docs);