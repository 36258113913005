import axios from "axios";
import { getRefreshToken, getToken } from "../helpers/utility";
export const init = () => {
  const token = getToken();
  axios.defaults.headers.get["authorization"] = `Bearer ${token}`;
  axios.defaults.headers.post["authorization"] = `Bearer ${token}`;
  axios.defaults.headers.put["authorization"] = `Bearer ${token}`;
  axios.defaults.headers.delete["authorization"] = `Bearer ${token}`;
  axios.defaults.headers.patch["authorization"] = `Bearer ${token}`;
  axios.defaults.headers.patch["Content-Type"] = "application/merge-patch+json";
  axios.defaults.headers.delete[
    "Content-Type"
  ] = `application/json;charset=UTF-8`;
};
// axios.defaults.headers.get["Content-Type"] = "application/zip";

export const refreshinit = () => {
  const token = getRefreshToken();
  axios.defaults.headers.post["authorization"] = `Bearer ${token}`;
  axios.defaults.headers.get["authorization"] = `Bearer ${token}`;
};
export const http = axios;
