import React, { Component, Fragment } from "react";
import {
  getGPNListAction,
  getGPNAction,
} from "../../../actions/inventory/gpn-action";
import Header from "../../../components/header/header";
import Search from "../../../components/shared/search/search";
import CustomTable from "../../../components/shared/table/table";
import store from "../../../store";
import { connect } from "react-redux";
import qs from "qs";
import { stringToInt, updateQueryParams } from "../../../helpers/utility";
import Sidebar from "../../../components/sidebar/sidebar";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class GPNListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gpnList: [],
      columnName: [
        { label: "GPN", key: "group_pn" },
        { label: "Description", key: "description" },
        { label: "Item Type", key: "part_type_name" },
        { label: "Stock", key: "total_stock" },
      ],
      queryParams: {},
      requestContent: {
        search_query: "",
      },
      pagination: {},
      mpnMembers: [],
      mpnMembersLabels: [
        { label: "IPN" },
        { label: "Desciption" },
        { label: "Item Type" },
        { label: "Manufacturer" },
        { label: "MPN" },
      ],
      stockLocations: [],
      stockLocationsLabels: [{ label: "Unit Name" }, { label: "Quantity" }],
      totalStock: {},
      bomusage: [],
      bomusageLabels: [
        { label: "BOM Name" },
        { label: "BOM Label" },
        { label: "Quantity" },
        { label: "Active" },
      ],
      history: [],
      historyLabels: [
        { label: "Field" },
        { label: "Message" },
        { label: "New Value" },
        { label: "Old Value" },
        { label: "Time" },
      ],
      row: ""
    };
    this.tableFilters = this.tableFilters.bind(this);
    this.headerSearch = this.headerSearch.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    document.title = "GPN List";
    this.setState(
      {
        queryParams: stringToInt(
          qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        ),
      },
      () => {
        this.getData(this.state.queryParams);
      }
    );
    getGPNAction(1);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.gpnList) !== JSON.stringify(this.props.gpnList)
    ) {
      this.setState({
        gpnList: this.props.gpnList.map((elem) => {
          return {
            0: elem.group_pn,
            1: elem.description,
            2: elem.part_type_name,
            3: elem.stock.total_stock,
            id: elem.id,
            active: elem.active,
          };
        }),
      });
    }
    if (
      JSON.stringify(prevProps.gpnListRaw) !==
      JSON.stringify(this.props.gpnListRaw)
    ) {
      // PageCount
      this.setState({ pagination: this.props.gpnListRaw.pagination });
    }
    //  gpn detail api's
    if (
      JSON.stringify(prevProps.mpnMembers) !==
      JSON.stringify(this.props.mpnMembers)
    ) {
      this.setState({
        mpnMembers: this.props.mpnMembers.map((elem) => {
          return {
            value1: (
              <Link
                to={`/mpn?filter_options%5B0%5D%5Bmatching%5D=equals&filter_options%5B0%5D%5Bquery%5D=${elem.ics_pn}&filter_options%5B0%5D%5Bcolumn%5D=ics_pn`}
              >
                {elem.ics_pn}
              </Link>
            ),
            value2: elem.description,
            value3: elem.part_type_name ? elem.part_type_name : "N/A",
            value4: elem.manufacturer,
            value5: elem.manufacturer_pn,
          };
        }),
      });
    }
    if (
      JSON.stringify(prevProps.stockLocations) !==
      JSON.stringify(this.props.stockLocations)
    ) {
      this.setState({
        stockLocations: this.props.stockLocations.map((elem) => {
          return {
            value1: elem.unit_name ? elem.unit_name : "N/A",
            value2: elem.quantity,
          };
        }),
      });
    }
    if (prevProps.totalstock !== this.props.totalstock) {
      this.setState({ totalStock: this.props.totalstock });
    }
    if (
      JSON.stringify(prevProps.bomusage) !== JSON.stringify(this.props.bomusage)
    ) {
      this.setState({
        bomusage: this.props.bomusage.map((elem) => {
          return {
            value1: elem.bom_name,
            value2: elem.bom_label,
            value3: elem.quantity,
            value4:
              elem.active == true ? (
                <i class="fa fa-check" aria-hidden="true"></i>
              ) : (
                <i class="fa fa-times" aria-hidden="true"></i>
              ),
          };
        }),
      });
    }
    if (
      JSON.stringify(prevProps.history) !== JSON.stringify(this.props.history)
    ) {
      this.setState({
        history: this.props.history.map((elem) => {
          return {
            value1: elem.field,
            value2: elem.message,
            value3: elem.new_value,
            value4: elem.old_value,
            value5: elem.time,
          };
        }),
      });
    }

    //
  }
  headerSearch(searchQuery) {
    this.setState(
      {
        requestContent: {
          ...this.state.requestContent,
          search_query: searchQuery,
        },
      },
      () => {
        updateQueryParams(this.state.requestContent);
        this.getData(this.state.requestContent);
      }
    );
  }

  tableFilters(requestContent = {}, type = "list") {
    let { search_query } = requestContent;
    this.setState(
      { requestContent: { ...requestContent, search_query } },
      () => {
        this.getData(this.state.requestContent, type);
      }
    );
  }
  handleRowClick = (id) => {
    getGPNAction(id);
    this.setState({row: id})

  };
  getData(requestContent = {}, type = "list") {
    requestContent = stringToInt(requestContent);
    console.log("getData: ", requestContent);
    store.dispatch(
      getGPNListAction({ request_content: requestContent, request_type: type })
    );
  }

  render() {
    return (
      <Fragment>
        <Header>
          <Search
            search={this.headerSearch}
            queryParams={this.state.queryParams}
          />
        </Header>
        <div className="main-section">
          <CustomTable
            queryParams={this.state.queryParams}
            columns={this.state.columnName}
            getId={this.handleRowClick}
            isLoading={this.props.gpnListLoading}
            tableFilters={this.tableFilters}
            data={this.state.gpnList}
            pagination={this.state.pagination}
          />
          <Sidebar
            row={this.state.row}
            module1name={"MPN Members"}
            module1={this.state.mpnMembers}
            module1Headings={this.state.mpnMembersLabels}
            module1Loading={this.props.mpnMembersLoading}
            module2name={"Stock Locations"}
            module2={this.state.stockLocations}
            module2Headings={this.state.stockLocationsLabels}
            module2Loading={this.props.stockLocationsLoading}
            module3name={"Bom Usage"}
            module3={this.state.bomusage}
            module3Headings={this.state.bomusageLabels}
            module3Loading={this.props.bomusageLoading}
            module4name={"History"}
            module4={this.state.history}
            module4Headings={this.state.historyLabels}
            module4Loading={this.props.historyLoading}
            module5={this.props.totalstock}
            module5Loading={this.props.stockLocationsLoading}
            module6={this.props.gpnbyId.group_pn}
            module6Loading={this.props.gpnbyIdLoading}
            module7={this.props.gpnbyId.description}
            module7Loading={this.props.gpnbyIdLoading}
          />
        </div>
      </Fragment>
    );
  }
}
const stateMap = (state) => {
  return {
    gpnList: state.inventory.gpnList,
    gpnListRaw: state.inventory.gpnListRaw,
    gpnListLoading: state.inventory.gpnListLoading,
    gpnbyId: state.inventory.gpnbyId,
    gpnbyIdLoading: state.inventory.gpnbyIdLoading,
    mpnMembers: state.inventory.mpnMembers,
    mpnMembersLoading: state.inventory.mpnMembersLoading,
    stockLocations: state.inventory.stockLocations,
    stockLocationsLoading: state.inventory.stockLocationsLoading,
    totalstock: state.inventory.totalstock,
    totalstockLoading: state.inventory.totalstockLoading,
    bomusage: state.inventory.bomusage,
    bomusageLoading: state.inventory.bomusageLoading,
    history: state.inventory.history,
    historyLoading: state.inventory.historyLoading,
  };
};
export default connect(stateMap)(GPNListContainer);
