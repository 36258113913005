// inventory-api.js
// Common to both MPN and GPN
import { http, init } from "../http";
import { PARTTYPES_API } from "../constants";
import { part_type_fields } from "../exmple.json";

// Get a list of part types.
export const getPartTypes = async () => {
    return http.get(PARTTYPES_API);
};

// Get a list of part types with include[]=fields.
export const getPartTypesWithFields = async () => {
    return http.get(PARTTYPES_API, {
        params: part_type_fields,
    });
};
