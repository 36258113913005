import { init } from "../../api/http";
import { getPartTypes } from "../../api/inventory/inventory-api";
import {
  getGPNList,
  getGPN_MPNmembers,
  getGPN_stockLocations,
  getGPN_totalStock,
  getGPN_bomUsage,
  getGPN_history,
  getGPNSimilar,
  getGPNSuggest,
  addGPN,
  getGPNById,
  editGPN,
  deactivateGpn,
  gpnAutoComplete,
  getGpnfiles,
  addGpnFile,
  deleteGpnFile,
  downloadGpnFile,
  downloadAllGpnFiles,
  getAllGpnComments,
  addGpnComment,
  getGPN_altTo,
  getGPN_altFrom,
} from "../../api/inventory/gpn-api";
import { login } from "../../api/user/user-api";
import fileDownload from "js-file-download";
import {
  GPN_LIST,
  GPN_LIST_RAW,
  GPN_LIST_LOADING,
  INVENTORY_ERROR,
  GPN_MPN_MEMBERS,
  GPN_MPN_MEMBERS_LOADING,
  GPN_STOCK_LOCATIONS,
  GPN_STOCK_LOCATIONS_LOADING,
  GPN_TOTAL_STOCK,
  GPN_TOTAL_STOCK_LOADING,
  GPN_BOM_USAGE,
  GPN_BOM_USAGE_LOADING,
  GPN_HISTORY,
  GPN_HISTORY_LOADING,
  PART_TYPE,
  PART_TYPE_LOADING,
  GPN_SIMILAR,
  GPN_SIMILAR_LOADING,
  GPN_SUGGEST,
  GPN_SUGGEST_LOADING,
  ADD_GPN_ITEM,
  ADD_GPN_LOADING,
  GPN_BY_ID,
  GPN_BY_ID_LOADING,
  EDIT_GPN_LOADING,
  EDIT_GPN_ITEM,
  TO_GPN,
  ACTIVATE_GPN_LOADING,
  ACTIVATE_GPN,
  GPN_AUTOCOMPLETE,
  GPN_AUTOCOMPLETE_LOADING,
  GPN_FILES_LOADING,
  GPN_FILES,
  ADD_GPN_FILE,
  ADD_GPN_FILE_LOADING,
  DELETE_GPN_FILE_LOADING,
  DELETE_GPN_FILE,
  DOWNLOAD_GPN_FILE,
  DOWNLOAD_GPN_FILE_LOADING,
  DOWNLOAD_ALL_GPN_FILES_LOADING,
  DOWNLOAD_ALL_GPN_FILES,
  GPN_COMMENT_LIST_LOADING,
  GPN_COMMENT_LIST,
  GPN_COMMENT_LIST_RAW,
  ADD_GPN_COMMENT_LOADING,
  ADD_GPN_COMMENT,
  ALT_TO_LOADING,
  ALT_TO,
  ALT_FROM_LOADING,
  ALT_FROM,
} from "../../helpers/constants";
import store from "../../store";
import { successToast, errorToast, warningToast } from "../../helpers/utility";
import { addBOMAction } from "../bom/bom-action";

require("es6-promise").polyfill();
export function getGPNListAction(data) {
  init();
  store.dispatch({
    type: GPN_LIST_LOADING,
    payload: true,
  });
  return async function (dispatch) {
    try {
      const res = await getGPNList(data);

      if (data.request_type === "csv") {
        fileDownload(res.data, "gpn_data.csv");
      }

      dispatch({
        type: GPN_LIST_LOADING,
        payload: false,
      });
      store.dispatch({
        type: GPN_LIST,
        payload: [],
      });
      if (res.data.gpn_list) {
        dispatch({
          type: GPN_LIST,
          payload: res.data.gpn_list,
        });
        dispatch({
          type: GPN_LIST_RAW,
          payload: res.data,
        });
        dispatch({
          type: GPN_LIST_LOADING,
          payload: false,
        });
      }
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch({
        type: GPN_LIST_LOADING,
        payload: false,
      });
    }
  };
}

///////////////////////////////////////////////////////////////////////////////////////////
//               Get GPN Details By ID             //

export function getGPNAction(id) {
  getGPNByIdAction(id);
  getGPN_mpnAction(id);
  getGPN_StockLocationAction(id);
  getGPN_TotalStockAction(id);
  getGPN_BomUsageAction(id);
  getGPN_HistoryAction(id);
  getAltToAction(id);
  getAltFromAction(id);
}
async function getGPNByIdAction(id) {
  init();
  store.dispatch({
    type: GPN_BY_ID_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GPN_BY_ID,
    payload: {},
  });

  try {
    const res = await getGPNById(id);

    if (res.data) {
      store.dispatch({
        type: GPN_BY_ID,
        payload: res.data,
      });
      store.dispatch({
        type: GPN_BY_ID_LOADING,
        payload: false,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: GPN_BY_ID_LOADING,
      payload: false,
    });
  }
}

async function getGPN_mpnAction(id) {
  init();
  store.dispatch({
    type: GPN_MPN_MEMBERS_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GPN_MPN_MEMBERS,
    payload: [],
  });
  try {
    const res = await getGPN_MPNmembers(id);
    if (res.data.mpn_members) {
      store.dispatch({
        type: GPN_MPN_MEMBERS,
        payload: res.data.mpn_members,
      });
      store.dispatch({
        type: GPN_MPN_MEMBERS_LOADING,
        payload: false,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
  } catch (error) {
    store.dispatch({
      type: GPN_MPN_MEMBERS_LOADING,
      payload: false,
    });
  }
}

async function getGPN_StockLocationAction(id) {
  init();
  store.dispatch({
    type: GPN_STOCK_LOCATIONS_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GPN_STOCK_LOCATIONS,
    payload: [],
  });

  try {
    const res = await getGPN_stockLocations(id);
    if (res.data.stock_locations) {
      store.dispatch({
        type: GPN_STOCK_LOCATIONS,
        payload: res.data.stock_locations,
      });
      store.dispatch({
        type: GPN_STOCK_LOCATIONS_LOADING,
        payload: false,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
      store.dispatch({
        type: GPN_STOCK_LOCATIONS_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    store.dispatch({
      type: GPN_STOCK_LOCATIONS_LOADING,
      payload: false,
    });
  }
}

async function getGPN_TotalStockAction(id) {
  init();
  store.dispatch({
    type: GPN_TOTAL_STOCK_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GPN_TOTAL_STOCK,
    payload: {},
  });

  try {
    const res = await getGPN_totalStock(id);

    if (res.data.stock) {
      store.dispatch({
        type: GPN_TOTAL_STOCK,
        payload: res.data.stock,
      });
      store.dispatch({
        type: GPN_TOTAL_STOCK_LOADING,
        payload: false,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
  } catch (error) {
    store.dispatch({
      type: GPN_TOTAL_STOCK_LOADING,
      payload: false,
    });
  }
}

async function getGPN_BomUsageAction(id) {
  init();
  store.dispatch({
    type: GPN_BOM_USAGE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GPN_BOM_USAGE,
    payload: [],
  });
  try {
    const res = await getGPN_bomUsage(id);
    if (res.data.bom_usage) {
      store.dispatch({
        type: GPN_BOM_USAGE,
        payload: res.data.bom_usage,
      });
      store.dispatch({
        type: GPN_BOM_USAGE_LOADING,
        payload: false,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
  } catch (error) {
    store.dispatch({
      type: GPN_BOM_USAGE_LOADING,
      payload: false,
    });
  }
}

async function getGPN_HistoryAction(id) {
  init();
  store.dispatch({
    type: GPN_HISTORY_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GPN_HISTORY,
    payload: [],
  });
  try {
    const res = await getGPN_history(id);
    if (res.data.history) {
      store.dispatch({
        type: GPN_HISTORY,
        payload: res.data.history,
      });
      store.dispatch({
        type: GPN_HISTORY_LOADING,
        payload: false,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
  } catch (error) {
    store.dispatch({
      type: GPN_HISTORY_LOADING,
      payload: false,
    });
  }
}

//            alt to
async function getAltToAction(id) {
  init();
  store.dispatch({
    type: ALT_TO_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ALT_TO,
    payload: [],
  });
  try {
    const res = await getGPN_altTo(id);
    if (res.data.alternatives_to) {
      store.dispatch({
        type: ALT_TO,
        payload: res.data.alternatives_to,
      });
      store.dispatch({
        type: ALT_TO_LOADING,
        payload: false,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
  } catch (error) {
    store.dispatch({
      type: ALT_TO_LOADING,
      payload: false,
    });
  }
}
//            alt from
async function getAltFromAction(id) {
  init();
  store.dispatch({
    type: ALT_FROM_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ALT_FROM,
    payload: [],
  });
  try {
    const res = await getGPN_altFrom(id);
    if (res.data.alternatives_from) {
      store.dispatch({
        type: ALT_FROM,
        payload: res.data.alternatives_from,
      });
      store.dispatch({
        type: ALT_FROM_LOADING,
        payload: false,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
  } catch (error) {
    store.dispatch({
      type: ALT_FROM_LOADING,
      payload: false,
    });
  }
}
////          get gpn parttype inventory

export function getGPNPartTypeAction() {
  init();
  store.dispatch({
    type: PART_TYPE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: PART_TYPE,
    payload: [],
  });
  return async function (dispatch) {
    const res = await getPartTypes();
    if (res.data.part_type_list) {
      dispatch({
        type: PART_TYPE,
        payload: res.data.part_type_list,
      });
    } else {
      dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    dispatch({
      type: PART_TYPE_LOADING,
      payload: false,
    });
  };
}

export function getGPNSimilarAction(data) {
  init();
  store.dispatch({
    type: GPN_SIMILAR_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GPN_SIMILAR,
    payload: [],
  });
  return async function (dispatch) {
    const res = await getGPNSimilar(data);
    if (res.data.similar_gpns) {
      dispatch({
        type: GPN_SIMILAR,
        payload: res.data.similar_gpns,
      });
    } else {
      dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    dispatch({
      type: GPN_SIMILAR_LOADING,
      payload: false,
    });
  };
}

export function getGPNSuggestAction(data) {
  init();
  store.dispatch({
    type: GPN_SUGGEST_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GPN_SUGGEST,
    payload: [],
  });
  return async function (dispatch) {
    const res = await getGPNSuggest(data);
    if (res.data.suggested_mpns) {
      dispatch({
        type: GPN_SUGGEST,
        payload: res.data.suggested_mpns,
      });
    } else {
      dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    dispatch({
      type: GPN_SUGGEST_LOADING,
      payload: false,
    });
  };
}

//                add gpn

export function addGPNAction(data) {
  init();
  store.dispatch({
    type: ADD_GPN_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ADD_GPN_ITEM,
    payload: {},
  });
  return async function (dispatch) {
    try {
      let dat = {
        part_type_id: data.part_type_id,
        gpn_class: "identical",
        description: data.description,
        active: true,
      };

      const res = await addGPN(data.bom ? dat : data);
      if (res && data.bom) {
        let bomData = {
          product_group_pn_id: res.data.id,
          label: data.label,
          bom_class_id: data.bom_class_id,
          description: data.bomDesc,
        };
        addBOMAction(bomData);
      }

      if (res.data) {
        const gpn = res.data?.group_pn;
        successToast(`GPN ${gpn} added successfully`);
        if (window.location.pathname.includes("gpn")) {
          window.location.replace(TO_GPN + `/${res.data.id}`);
        }

        dispatch({
          type: ADD_GPN_ITEM,
          payload: res.data,
        });
      } else {
        dispatch({
          type: INVENTORY_ERROR,
          payload: res.data.message,
        });
      }

      dispatch({
        type: ADD_GPN_LOADING,
        payload: false,
      });
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };
}

//     edit gpn

export function editGPNAction(id, data) {
  init();
  store.dispatch({
    type: EDIT_GPN_LOADING,
    payload: true,
  });
  store.dispatch({
    type: EDIT_GPN_ITEM,
    payload: {},
  });
  return async function (dispatch) {
    const res = await editGPN(id, data);
    if (res) {
      dispatch({
        type: EDIT_GPN_ITEM,
        payload: res.data,
      });
    } else {
      dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    if (res.status == 204) {
      successToast(`GPN edited successfully`);
    }
    dispatch({
      type: EDIT_GPN_LOADING,
      payload: false,
    });
  };
}
//     deactivate gpn

export function deactivateGPNAction(id, data) {
  init();
  store.dispatch({
    type: ACTIVATE_GPN_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ACTIVATE_GPN,
    payload: {},
  });
  return async function (dispatch) {
    const res = await deactivateGpn(id, data);
    if (res) {
      dispatch({
        type: ACTIVATE_GPN,
        payload: res.data,
      });
    } else {
      dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    successToast(
      `GPN ${data.active == false ? "deactivated" : "activated"} successfully`
    );
    window.location.reload();
    dispatch({
      type: ACTIVATE_GPN_LOADING,
      payload: false,
    });
  };
}

//    Files

// get files of a gpn

export async function getGPNfilesAction(id) {
  init();
  store.dispatch({
    type: GPN_FILES_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GPN_FILES,
    payload: [],
  });

  const res = await getGpnfiles(id);
  if (res.data.files) {
    store.dispatch({
      type: GPN_FILES,
      payload: res.data.files,
    });
  } else {
    store.dispatch({
      type: INVENTORY_ERROR,
      payload: res.data.message,
    });
  }
  store.dispatch({
    type: GPN_FILES_LOADING,
    payload: false,
  });
}

// Add File

export async function addGpnFileAction(id, data) {
  init();
  store.dispatch({
    type: ADD_GPN_FILE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ADD_GPN_FILE,
    payload: {},
  });

  try {
    const res = await addGpnFile(id, data);
    if (res.data) {
      store.dispatch({
        type: ADD_GPN_FILE_LOADING,
        payload: true,
      });
      store.dispatch({
        type: ADD_GPN_FILE,
        payload: res.data,
      });
      getGPNfilesAction(id);
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    store.dispatch({
      type: ADD_GPN_FILE_LOADING,
      payload: false,
    });
  } catch (error) {
    warningToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: ADD_GPN_FILE_LOADING,
      payload: false,
    });
  }
}

//   delete a file

export async function deleteGpnFileAction(uuid) {
  init();
  store.dispatch({
    type: DELETE_GPN_FILE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: DELETE_GPN_FILE,
    payload: {},
  });
  const res = await deleteGpnFile(uuid);
  if (res) {
    // const id = window.location.pathname.split("/bom/edit/")[1];
    // getBomfiles(id);
    store.dispatch({
      type: DELETE_GPN_FILE,
      payload: res.data,
    });
  } else {
    store.dispatch({
      type: INVENTORY_ERROR,
      payload: res.data.message,
    });
  }
  store.dispatch({
    type: DELETE_GPN_FILE_LOADING,
    payload: false,
  });
}

export async function downloadGpnFileAction(uuid, name) {
  init();
  store.dispatch({
    type: DOWNLOAD_GPN_FILE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: DOWNLOAD_GPN_FILE,
    payload: "",
  });
  const res = await downloadGpnFile(uuid);
  if (res) {
    fileDownload(res.data, name);
  }
  if (res) {
    store.dispatch({
      type: DOWNLOAD_GPN_FILE,
      payload: res.data,
    });
  } else {
    store.dispatch({
      type: INVENTORY_ERROR,
      payload: res.data.message,
    });
  }
  store.dispatch({
    type: DOWNLOAD_GPN_FILE_LOADING,
    payload: false,
  });
}

//           download all file

export async function downloadAllGpnFilesAction(id) {
  init();
  store.dispatch({
    type: DOWNLOAD_ALL_GPN_FILES_LOADING,
    payload: true,
  });
  store.dispatch({
    type: DOWNLOAD_ALL_GPN_FILES,
    payload: "",
  });
  try {
    const res = await downloadAllGpnFiles(id);

    if (res) {
      // fileDownload(res.data, "data.zip");
      let headerLine = res.headers;
      console.log(headerLine);
      saveAs(new Blob([res.data], { type: "application/octet-stream" }));
    }

    store.dispatch({
      type: DOWNLOAD_ALL_GPN_FILES_LOADING,
      payload: false,
    });
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: DOWNLOAD_ALL_GPN_FILES_LOADING,
      payload: false,
    });
  }
}
export async function gpnAutoCompleteAction(data) {
  init();
  store.dispatch({
    type: GPN_AUTOCOMPLETE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GPN_AUTOCOMPLETE,
    payload: [],
  });
  try {
    const res = await gpnAutoComplete(data);
    if (res.data.suggestions) {
      store.dispatch({
        type: GPN_AUTOCOMPLETE,
        payload: res.data.suggestions,
      });
      store.dispatch({
        type: GPN_AUTOCOMPLETE_LOADING,
        payload: false,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
  } catch (error) {
    store.dispatch({
      type: GPN_AUTOCOMPLETE_LOADING,
      payload: false,
    });
  }
}
//                       Gpn  Comments

export function gpnCommentsListAction(id) {
  init();
  store.dispatch({
    type: GPN_COMMENT_LIST_LOADING,
    payload: true,
  });
  return async function (dispatch) {
    try {
      const res = await getAllGpnComments(id);

      dispatch({
        type: GPN_COMMENT_LIST_LOADING,
        payload: false,
      });
      store.dispatch({
        type: GPN_COMMENT_LIST,
        payload: [],
      });
      if (res.data.comments) {
        dispatch({
          type: GPN_COMMENT_LIST,
          payload: res.data.comments,
        });
        dispatch({
          type: GPN_COMMENT_LIST_RAW,
          payload: res.data,
        });
        dispatch({
          type: GPN_COMMENT_LIST_LOADING,
          payload: false,
        });
      }
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch({
        type: GPN_COMMENT_LIST_LOADING,
        payload: false,
      });
    }
  };
}
//  gpn add comment
export function addGpnCommentAction(id, data) {
  init();
  store.dispatch({
    type: ADD_GPN_COMMENT_LOADING,
    payload: true,
  });
  return async function (dispatch) {
    try {
      const res = await addGpnComment(id, data);
      if (res.data) {
        store.dispatch(gpnCommentsListAction(id));
        dispatch({
          type: ADD_GPN_COMMENT,
          payload: res.data,
        });
        dispatch({
          type: ADD_GPN_COMMENT_LOADING,
          payload: false,
        });
      }
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch({
        type: ADD_GPN_COMMENT_LOADING,
        payload: false,
      });
    }
  };
}
