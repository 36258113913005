import React, { Component } from "react";
import { Modal, Button} from "react-bootstrap"

export default class BOMErrorCheckModal extends Component{
    constructor(props){
        super(props);
    }
    render(){
        return (
            <Modal
                {...this.props} 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Title className="ms-3 mt-2" id="contained-modal-title-vcenter">
                    Are you sure you want to export files?
                </Modal.Title>
                <Modal.Body>
                    <p>This BOM currently contains {this.props.errors.CRITICAL ? <><span class="px-1"><i class="fa fa-crosshairs text-danger"></i> {this.props.errors.CRITICAL}</span> critical items</> : ""} {this.props.errors.ERROR ? <><span class="px-1"><i class="fa fa-times-circle text-danger"></i> {this.props.errors.ERROR}</span> errors</> : ""} {this.props.errors.WARNING ? <><span class="px-1"><i class="fa fa-exclamation-circle text-warning"></i> {this.props.errors.WARNING}</span> warnings</> : ""} which means that the output file will be incorrect.</p>
                    <p>
                    If you need to export this file for temporary use, it is recommended
                    to immediately remove it from the files list so as not to confuse
                    future users.
                    </p>
                    <p>Do you wish to continue?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-primary" onClick={this.props.onHide}>Return to safety</Button>
                    <Button className="btn-danger" onClick={this.props.onProceed}>Proceed</Button>
                </Modal.Footer>   
            </Modal>
        )
    }
}