import {
  addReaction,
  addReply,
  deleteComment,
  deleteSingleReaction,
  getComment,
  getListReactions,
  getListReplies,
  getSingleReaction,
  updateComment,
} from "../../api/comments/comments-api";
import { init } from "../../api/http";
import {
  ADD_REACTION,
  ADD_REACTION_LOADING,
  ADD_REPLY,
  ADD_REPLY_LOADING,
  DELETE_COMMENT,
  DELETE_COMMENT_LOADING,
  DELETE_REACTION,
  DELETE_REACTION_LOADING,
  GET_COMMENT,
  GET_COMMENT_LOADING,
  GET_REACTION,
  GET_REACTION_LIST,
  GET_REACTION_LIST_LOADING,
  GET_REACTION_LOADING,
  GET_REPLY_LIST,
  GET_REPLY_LIST_LOADING,
  UPDATE_COMMENT,
  UPDATE_COMMENT_LOADING,
} from "../../helpers/constants";
import { errorToast, successToast } from "../../helpers/utility";
import store from "../../store";
import { bomCommentsListAction } from "../bom/bom-action";
import { gpnCommentsListAction } from "../inventory/gpn-action";
import { mpnCommentsListAction } from "../inventory/mpn-action";

export async function getCommentAction(id) {
  init();
  store.dispatch({
    type: GET_COMMENT_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GET_COMMENT,
    payload: {},
  });

  try {
    const res = await getComment(id);

    if (res.data) {
      store.dispatch({
        type: GET_COMMENT,
        payload: res.data,
      });
      store.dispatch({
        type: GET_COMMENT_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: GET_COMMENT_LOADING,
      payload: false,
    });
  }
}
//  update comment

export async function updateCommentAction(id, data) {
  init();
  store.dispatch({
    type: UPDATE_COMMENT_LOADING,
    payload: true,
  });
  store.dispatch({
    type: UPDATE_COMMENT,
    payload: {},
  });

  try {
    const res = await updateComment(id, data);

    if (res.data) {
      store.dispatch({
        type: UPDATE_COMMENT,
        payload: res.data,
      });
      store.dispatch({
        type: UPDATE_COMMENT_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: UPDATE_COMMENT_LOADING,
      payload: false,
    });
  }
}
//   delete comment

export async function deleteCommentAction(id) {
  init();
  store.dispatch({
    type: DELETE_COMMENT_LOADING,
    payload: true,
  });
  store.dispatch({
    type: DELETE_COMMENT,
    payload: {},
  });

  try {
    const res = await deleteComment(id);
    const pageId = window.location.pathname.split("/")[2];
    if (res) {
      console.log("Deleted successfullly....");
      successToast('Comment Deleted Successfully')
      if (window.location.pathname.includes("gpn")) {
        console.log("gpn page");
        store.dispatch(gpnCommentsListAction(pageId));
      } else if (window.location.pathname.includes("mpn")) {
        console.log("mpn page");
        store.dispatch(mpnCommentsListAction(pageId));
      } else {
        console.log("bom page");
        store.dispatch(bomCommentsListAction(pageId));
      }
      store.dispatch({
        type: DELETE_COMMENT,
        payload: res.data,
      });
      store.dispatch({
        type: DELETE_COMMENT_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: DELETE_COMMENT_LOADING,
      payload: false,
    });
  }
}
//     add Reply
export async function addReplyAction(id, data) {
  init();
  store.dispatch({
    type: ADD_REPLY_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ADD_REPLY,
    payload: {},
  });

  try {
    const res = await addReply(id, data);

    if (res.data) {
      store.dispatch({
        type: ADD_REPLY,
        payload: res.data,
      });
      store.dispatch({
        type: ADD_REPLY_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: ADD_REPLY_LOADING,
      payload: false,
    });
  }
}

//   get reply list
export async function getReplyListAction(id) {
  init();
  store.dispatch({
    type: GET_REPLY_LIST_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GET_REPLY_LIST,
    payload: {},
  });

  try {
    const res = await getListReplies(id);

    if (res.data) {
      store.dispatch({
        type: GET_REPLY_LIST,
        payload: res.data,
      });
      store.dispatch({
        type: GET_REPLY_LIST_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: GET_REPLY_LIST_LOADING,
      payload: false,
    });
  }
}

//     add Reply
export async function addReactionAction(id, data) {
  init();
  store.dispatch({
    type: ADD_REACTION_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ADD_REACTION,
    payload: {},
  });

  try {
    const res = await addReaction(id, data);

    if (res.data) {
      store.dispatch({
        type: ADD_REACTION,
        payload: res.data,
      });
      store.dispatch({
        type: ADD_REACTION_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: ADD_REACTION_LOADING,
      payload: false,
    });
  }
}
//   get reaction list
export async function getReactionListAction(id) {
  init();
  store.dispatch({
    type: GET_REACTION_LIST_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GET_REACTION_LIST,
    payload: {},
  });

  try {
    const res = await getListReactions(id);

    if (res.data) {
      store.dispatch({
        type: GET_REACTION_LIST,
        payload: res.data,
      });
      store.dispatch({
        type: GET_REACTION_LIST_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: GET_REACTION_LIST_LOADING,
      payload: false,
    });
  }
}

//    get reaction details
export async function getReactionAction(id) {
  init();
  store.dispatch({
    type: GET_REACTION_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GET_REACTION,
    payload: {},
  });

  try {
    const res = await getSingleReaction(id);

    if (res.data) {
      store.dispatch({
        type: GET_REACTION,
        payload: res.data,
      });
      store.dispatch({
        type: GET_REACTION_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: GET_REACTION_LOADING,
      payload: false,
    });
  }
}
//   delete Reaction

export async function deleteReactionAction(id) {
  init();
  store.dispatch({
    type: DELETE_REACTION_LOADING,
    payload: true,
  });
  store.dispatch({
    type: DELETE_REACTION,
    payload: {},
  });

  try {
    const res = await deleteSingleReaction(id);

    if (res.data) {
      store.dispatch({
        type: DELETE_REACTION,
        payload: res.data,
      });
      store.dispatch({
        type: DELETE_REACTION_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: DELETE_REACTION_LOADING,
      payload: false,
    });
  }
}
