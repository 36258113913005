//Base URL
export const BASE_URL = process.env.REACT_APP_API_SERVER;
const API_PREFIX = "/api";

// auth api URLs
export const LOGIN_API = BASE_URL + "/auth/apilogin";
export const REFRESH_API = BASE_URL + "/auth/apirefresh";
export const REVOKE_ACCESS = BASE_URL + "/auth/revoke_access";
export const REVOKE_REFRESH = BASE_URL + "/auth/revoke_refresh";
export const BACKEND_VERSION = BASE_URL + "/auth/about";

// inventory api URLs

export const PARTTYPES_API = BASE_URL + API_PREFIX + "/inventory/parttypes";

//gpn api URLs
export const GENERAL_API = BASE_URL + API_PREFIX
export const GPN_API = BASE_URL + API_PREFIX + "/inventory/gpn";
export const MPN_API = BASE_URL + API_PREFIX + "/inventory/mpn";
export const BOMS_API = BASE_URL + API_PREFIX + "/boms";
export const FILES_API = BASE_URL + API_PREFIX + "/files";
export const COMMENTS_API = BASE_URL + API_PREFIX + "/comments";
export const BULK_API = BASE_URL + API_PREFIX + "/inventory/bulk";
export const ALTERNATIVE_API = BASE_URL + API_PREFIX + "/inventory/alts";
export const DOCS_API = BASE_URL + API_PREFIX + "/docs";


export const REGISTER_API = BASE_URL + "/register";
