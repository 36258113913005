import { init } from "../../api/http";
import store from "../../store";
import fileDownload from "js-file-download";
import { saveAs } from "file-saver";

import {
  getBomList,
  getBomById,
  getBomLineItems,
  getBomfiles,
  getBomclasses,
  addBom,
  addFile,
  deleteFile,
  downloadFile,
  getImports,
  addImport,
  getImportTable,
  getImport,
  validateImport,
  executeImport,
  deleteImport,
  generateFile,
  updateBom,
  validateBom,
  approveBom,
  downloadAllFiles,
  deactivateBom,
  updateFile,
  importStatus,
  newVersion,
  addAltUpdate,
  managePendingAlt,
  manageAcceptedAlt,
  manageRejectedAlt,
  manageAdditionAlt,
  manageDeletionAlt,
  updateAltStatus,
  managedAlts,
  lastSync,
  synchronizeBom,
  addBOMComment,
  getAllBOMComments,
  updateBomRev,
  deleteLineItem,
  deleteAllLineItems,
  errorCounterLineItems,
} from "../../api/bom/bom-api";
import {
  BOM_LIST,
  BOM_LIST_RAW,
  BOM_LIST_LOADING,
  INVENTORY_ERROR,
  BOM_DETAILS,
  BOM_DETAILS_LOADING,
  BOM_LINEITEMS,
  BOM_LINEITEMS_RAW,
  BOM_LINEITEMS_LOADING,
  BOM_FILES,
  BOM_FILES_LOADING,
  BOM_CLASSES,
  BOM_CLASSES_LOADING,
  ADD_BOM_ITEM,
  ADD_BOM_LOADING,
  ADD_BOM_FILE,
  ADD_BOM_FILE_LOADING,
  DELETE_BOM_FILE,
  DELETE_BOM_FILE_LOADING,
  DOWNLOAD_BOM_FILE,
  DOWNLOAD_BOM_FILE_LOADING,
  GET_IMPORTS,
  GET_IMPORTS_LOADING,
  GET_IMPORT,
  GET_IMPORT_LOADING,
  ADD_IMPORT,
  ADD_IMPORT_LOADING,
  PRE_IMPORT,
  PRE_IMPORT_LOADING,
  VALIDATE_IMPORT_LOADING,
  VALIDATE_IMPORT,
  EXECUTE_IMPORT,
  EXECUTE_IMPORT_LOADING,
  DELETE_IMPORT_LOADING,
  DELETE_IMPORT,
  DOWNLOAD_ALL_FILES,
  DOWNLOAD_ALL_FILES_LOADING,
  IMPORT_STATUS_LOADING,
  IMPORT_STATUS,
  NEW_BOM_LOADING,
  NEW_BOM,
  ADD_MANAGE_ALTERNATIVE_LOADING,
  ADD_MANAGE_ALTERNATIVE,
  PENDING_ALTERNATIVE_LOADING,
  PENDING_ALTERNATIVE,
  ACCEPTED_ALTERNATIVE_LOADING,
  ACCEPTED_ALTERNATIVE,
  REJECTED_ALTERNATIVE_LOADING,
  REJECTED_ALTERNATIVE,
  ADDITION_ALTERNATIVE_LOADING,
  ADDITION_ALTERNATIVE,
  DELETION_ALTERNATIVE_LOADING,
  DELETION_ALTERNATIVE,
  UPDATE_STATUS_ALTERNATIVE_LOADING,
  UPDATE_STATUS_ALTERNATIVE,
  MANAGED_ALTS_LOADING,
  MANAGED_ALTS,
  TIME_SYNCED_LOADING,
  TIME_SYNCED,
  SYNC_BOM,
  SYNC_BOM_LOADING,
  BOM_COMMENT_LIST_LOADING,
  BOM_COMMENT_LIST_RAW,
  BOM_COMMENT_LIST,
  ADD_BOM_COMMENT,
  ADD_BOM_COMMENT_LOADING,
  REV_BOM_LOADING,
  REV_BOM,
  DELETE_ALL_LINEITEMS,
  DELETE_ALL_LINEITEMS_LOADING,
  DELETE_LINEITEMS,
  DELETE_LINEITEMS_LOADING,
  ERROR_COUNT_LOADING,
  ERROR_COUNT,
} from "../../helpers/constants";
import { errorToast, successToast, warningToast } from "../../helpers/utility";
import { deleteCommentAction } from "../comments/comments-action";

export function getBOMListAction(data) {
  init();
  store.dispatch({
    type: BOM_LIST_LOADING,
    payload: true,
  });
  return async function (dispatch) {
    try {
      const res = await getBomList(data);
      if (res) {
        if (data.request_type === "csv") {
          fileDownload(res.data, "bom_data.csv");
        }
      }
      dispatch({
        type: BOM_LIST_LOADING,
        payload: false,
      });
      store.dispatch({
        type: BOM_LIST,
        payload: [],
      });
      if (res.data.bom_list) {
        dispatch({
          type: BOM_LIST,
          payload: res.data.bom_list,
        });
        dispatch({
          type: BOM_LIST_RAW,
          payload: res.data,
        });
      } else {
        dispatch({
          type: INVENTORY_ERROR,
          payload: res.data.message,
        });
      }
      dispatch({
        type: BOM_LIST_LOADING,
        payload: false,
      });
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch({
        type: BOM_LIST_LOADING,
        payload: false,
      });
    }
  };
}

//
export const ViewEditBom = (id) => {
  getBOMDetailsAction(id);
  // getBOMLineItemsAction(id);
  getBOMfilesAction(id);
};

//  get a single bom details

export async function getBOMDetailsAction(id) {
  init();
  store.dispatch({
    type: BOM_DETAILS_LOADING,
    payload: true,
  });
  store.dispatch({
    type: BOM_DETAILS,
    payload: {},
  });

  const res = await getBomById(id);
  if (res.data) {
    let uuid = res.data.revisions[0].uuid;
    let pageNumber = 1;
    if (window.location.search) {
      pageNumber = window.location.search.split("=")[1];
    } else {
      pageNumber = 1;
    }
    let requestContent = {
      items_per_page: 100,
      page_number: parseInt(pageNumber),
    };
    getBOMLineItemsAction(uuid, {
      request_content: requestContent,
      request_type: "list",
    });
    store.dispatch({
      type: BOM_DETAILS,
      payload: res.data,
    });
  } else {
    store.dispatch({
      type: INVENTORY_ERROR,
      payload: res.data.message,
    });
  }
  store.dispatch({
    type: BOM_DETAILS_LOADING,
    payload: false,
  });
}

export async function getBOMLineItemsAction(id, data) {
  init();
  store.dispatch({
    type: BOM_LINEITEMS_LOADING,
    payload: true,
  });
  store.dispatch({
    type: BOM_LINEITEMS,
    payload: [],
  });

  store.dispatch({
    type: BOM_LINEITEMS_RAW,
    payload: {},
  });
  const res = await getBomLineItems(id, data);
  if (res.data) {
    errorsCountAction(id);
    store.dispatch({
      type: BOM_LINEITEMS,
      payload: res.data.bom_line_items,
    });
    store.dispatch({
      type: BOM_LINEITEMS_RAW,
      payload: res.data,
    });
  } else {
    store.dispatch({
      type: INVENTORY_ERROR,
      payload: res.data.message,
    });
  }
  store.dispatch({
    type: BOM_LINEITEMS_LOADING,
    payload: false,
  });
}

// get files of a bom

export async function getBOMfilesAction(id) {
  init();
  store.dispatch({
    type: BOM_FILES_LOADING,
    payload: true,
  });
  store.dispatch({
    type: BOM_FILES,
    payload: [],
  });

  const res = await getBomfiles(id);
  if (res.data.files) {
    store.dispatch({
      type: BOM_FILES,
      payload: res.data.files,
    });
  } else {
    store.dispatch({
      type: INVENTORY_ERROR,
      payload: res.data.message,
    });
  }
  store.dispatch({
    type: BOM_FILES_LOADING,
    payload: false,
  });
}
// /////////////////////////////////////////

// get bom classes

export async function getBOMClassesAction() {
  init();
  store.dispatch({
    type: BOM_CLASSES_LOADING,
    payload: true,
  });
  store.dispatch({
    type: BOM_CLASSES,
    payload: [],
  });

  const res = await getBomclasses();
  if (res.data.bom_class_list) {
    store.dispatch({
      type: BOM_CLASSES,
      payload: res.data.bom_class_list,
    });
  } else {
    store.dispatch({
      type: INVENTORY_ERROR,
      payload: res.data.message,
    });
  }
  store.dispatch({
    type: BOM_CLASSES_LOADING,
    payload: false,
  });
}

// get bom classes

export async function addBOMAction(data) {
  init();
  store.dispatch({
    type: ADD_BOM_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ADD_BOM_ITEM,
    payload: {},
  });

  const res = await addBom(data);
  console.log(res);
  if (res.data) {
    store.dispatch({
      type: ADD_BOM_ITEM,
      payload: res.data,
    });
  } else {
    store.dispatch({
      type: ADD_BOM_LOADING,
      payload: false,
    });
  }
}

// add file in bom

export async function addFileAction(id, data) {
  init();
  store.dispatch({
    type: ADD_BOM_FILE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ADD_BOM_FILE,
    payload: {},
  });

  try {
    const res = await addFile(id, data);
    if (res.data) {
      store.dispatch({
        type: ADD_BOM_FILE_LOADING,
        payload: false,
      });
      store.dispatch({
        type: ADD_BOM_FILE,
        payload: res.data,
      });
      getBOMfilesAction(id);
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
  } catch (error) {
    warningToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: ADD_BOM_FILE_LOADING,
      payload: false,
    });
  }
}

//   delete a file

export async function deleteFileAction(uuid) {
  init();
  store.dispatch({
    type: DELETE_BOM_FILE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: DELETE_BOM_FILE,
    payload: {},
  });
  const res = await deleteFile(uuid);
  if (res) {
    // const id = window.location.pathname.split("/bom/edit/")[1];
    // getBomfiles(id);
    store.dispatch({
      type: DELETE_BOM_FILE,
      payload: res.data,
    });
  } else {
    store.dispatch({
      type: INVENTORY_ERROR,
      payload: res.data.message,
    });
  }
  store.dispatch({
    type: DELETE_BOM_FILE_LOADING,
    payload: false,
  });
}
//      Update BOM File
export async function updateFileAction(id, data) {
  init();
  try {
    const res = await updateFile(id, data);
    if (res.status == 204) {
      successToast("File updated Successfully");
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
}
//           download a file

export async function downloadFileAction(uuid, name) {
  init();
  store.dispatch({
    type: DOWNLOAD_BOM_FILE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: DOWNLOAD_BOM_FILE,
    payload: "",
  });
  const res = await downloadFile(uuid);
  if (res) {
    fileDownload(res.data, name);
  }
  if (res) {
    store.dispatch({
      type: DOWNLOAD_BOM_FILE,
      payload: res.data,
    });
  } else {
    store.dispatch({
      type: INVENTORY_ERROR,
      payload: res.data.message,
    });
  }
  store.dispatch({
    type: DOWNLOAD_BOM_FILE_LOADING,
    payload: false,
  });
}
//           download all file

export async function downloadAllFilesAction(id) {
  init();
  store.dispatch({
    type: DOWNLOAD_ALL_FILES_LOADING,
    payload: true,
  });
  store.dispatch({
    type: DOWNLOAD_ALL_FILES,
    payload: "",
  });
  try {
    const res = await downloadAllFiles(id);

    if (res) {
      console.log(res);
      // fileDownload(res.data, "data.zip");
      let headerLine = res.headers;
      // let startFileNameIndex = headerLine.indexOf('"') + 1;
      // let endFileNameIndex = headerLine.lastIndexOf('"');
      // let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
      // res.data.pipe(fs.createWriteStream(filename));
      console.log(headerLine);
      saveAs(new Blob([res.data], { type: "application/octet-stream" }));
    }

    store.dispatch({
      type: DOWNLOAD_ALL_FILES_LOADING,
      payload: false,
    });
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: DOWNLOAD_ALL_FILES_LOADING,
      payload: false,
    });
  }
}
//     generate file

export async function generateFileAction(id) {
  init();

  const res = await generateFile(id);
  if (res) {
    if (res.status == 201) successToast("Generation started successfully");
    console.log(res.data);
    console.log(res);
  }
}

//            get bom imports

export async function getImportsAction(id) {
  init();
  store.dispatch({
    type: GET_IMPORTS_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GET_IMPORTS,
    payload: [],
  });

  const res = await getImports(id);
  if (res.data.bom_import_items) {
    store.dispatch({
      type: GET_IMPORTS,
      payload: res.data.bom_import_items,
    });
  } else {
    store.dispatch({
      type: INVENTORY_ERROR,
      payload: res.data.message,
    });
  }
  store.dispatch({
    type: GET_IMPORTS_LOADING,
    payload: false,
  });
}
//     pre import table

export async function addPreImportAction(id, data) {
  init();
  store.dispatch({
    type: PRE_IMPORT_LOADING,
    payload: true,
  });
  store.dispatch({
    type: PRE_IMPORT,
    payload: [],
  });

  try {
    const res = await getImportTable(id, data);

    if (res.data.documents?.[0]?.data) {
      store.dispatch({
        type: PRE_IMPORT,
        payload: res.data.documents[0].data,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    store.dispatch({
      type: PRE_IMPORT_LOADING,
      payload: false,
    });
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: PRE_IMPORT_LOADING,
      payload: false,
    });
  }
}

//         add import

export async function addImportAction(id, data) {
  init();
  store.dispatch({
    type: ADD_IMPORT_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ADD_IMPORT,
    payload: {},
  });

  try {
    const res = await addImport(id, data);
    if (res.data) {
      successToast("Import started!");
      // window.location.replace(`/bom/${id}/tasks`);
      store.dispatch({
        type: ADD_IMPORT_LOADING,
        payload: false,
      });
      store.dispatch({
        type: ADD_IMPORT,
        payload: res.data,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: ADD_IMPORT_LOADING,
      payload: false,
    });
  }
}
//    get import details

export async function getImportAction(id) {
  init();
  store.dispatch({
    type: GET_IMPORT_LOADING,
    payload: true,
  });
  store.dispatch({
    type: GET_IMPORT,
    payload: {},
  });
  const res = await getImport(id);
  if (res.data) {
    store.dispatch({
      type: GET_IMPORT,
      payload: res.data,
    });
  } else {
    store.dispatch({
      type: INVENTORY_ERROR,
      payload: res.data.message,
    });
  }
  store.dispatch({
    type: GET_IMPORT_LOADING,
    payload: false,
  });
}
//            delete import

export async function deleteImportAction(id) {
  init();
  store.dispatch({
    type: DELETE_IMPORT_LOADING,
    payload: true,
  });
  store.dispatch({
    type: DELETE_IMPORT,
    payload: {},
  });
  const res = await deleteImport(id);
  if (res.data) {
    console.log(res.data);
  }
  store.dispatch({
    type: DELETE_IMPORT_LOADING,
    payload: false,
  });
}

//          validate

export async function validateImportAction(id, data) {
  init();
  store.dispatch({
    type: VALIDATE_IMPORT_LOADING,
    payload: true,
  });
  store.dispatch({
    type: VALIDATE_IMPORT,
    payload: {},
  });
  const res = await validateImport(id, data);
  if (res.data.import_id == id) {
    successToast("Successfully started validation");
  }
  store.dispatch({
    type: VALIDATE_IMPORT_LOADING,
    payload: false,
  });
}

//          execute import

export async function executeImportAction(id, data) {
  init();
  store.dispatch({
    type: EXECUTE_IMPORT_LOADING,
    payload: true,
  });
  store.dispatch({
    type: EXECUTE_IMPORT,
    payload: {},
  });
  const res = await executeImport(id, data);
  if (res.data) {
    successToast("import successfully!");
    window.location.replace(`/bom/${data.bomId}/tasks`);
  }
  store.dispatch({
    type: EXECUTE_IMPORT_LOADING,
    payload: false,
  });
}
//    update Bom

export async function updateBomAction(id, data) {
  init();
  const res = await updateBom(id, data);

  try {
    successToast("Updated successfully");
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
}
//     validate bom

export async function validateBomAction(id, data) {
  init();
  const res = await validateBom(id, data);
  console.log(data);
  if (data.engineering_validation == true) {
    successToast("Successfully started validation");
  } else {
    successToast("Item invalidated successfully!");
  }
}
//     validate bom

export async function approveBomAction(id, data) {
  init();
  const res = await approveBom(id, data);
  console.log(res.data);
  if (res.data) {
    successToast("approve successfully!");
  }
}
//      Deactivate BOM
export async function deactivateBomAction(id, data) {
  init();

  const res = await deactivateBom(id, data);
  console.log(res.data);
  if (res.status == 204) {
    successToast(
      `BOM ${data.active == false ? "deactivated" : "activated"} successfully`
    );
    window.location.reload();
  }
}
//      Import status
export async function ImportStatusAction(id) {
  init();
  store.dispatch({
    type: IMPORT_STATUS_LOADING,
    payload: true,
  });
  store.dispatch({
    type: IMPORT_STATUS,
    payload: [],
  });
  try {
    const res = await importStatus(id);
    if (res.data) {
      store.dispatch({
        type: IMPORT_STATUS,
        payload: res.data.tasks,
      });
      store.dispatch({
        type: IMPORT_STATUS_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: IMPORT_STATUS_LOADING,
      payload: false,
    });
  }
}

//  New Bom Version

export async function newBomAction(name, data) {
  init();
  store.dispatch({
    type: NEW_BOM_LOADING,
    payload: true,
  });
  store.dispatch({
    type: NEW_BOM,
    payload: {},
  });
  try {
    const res = await newVersion(name, data);

    if (res.status == 201) {
      successToast("New Version created");
      ViewEditBom(window.location.pathname.split("/")[2]);

      // window.location.replace(`/bom/edit/${res.data.id}`);
      store.dispatch({
        type: NEW_BOM,
        payload: res.data,
      });
      store.dispatch({
        type: NEW_BOM_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: NEW_BOM_LOADING,
      payload: false,
    });
  }
}

// add manage Alts

export async function addManageAltAction(id, data) {
  init();
  store.dispatch({
    type: ADD_MANAGE_ALTERNATIVE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ADD_MANAGE_ALTERNATIVE,
    payload: {},
  });
  try {
    const res = await addAltUpdate(id, data);
    // console.log(res.data);

    if (res.data) {
      let bomId = window.location.pathname.split("/")[2];
      manageAltAction(bomId);
      store.dispatch({
        type: ADD_MANAGE_ALTERNATIVE,
        payload: res.data,
      });
      store.dispatch({
        type: ADD_MANAGE_ALTERNATIVE_LOADING,
        payload: false,
      });
      successToast("Alt created!");
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: ADD_MANAGE_ALTERNATIVE_LOADING,
      payload: false,
    });
  }
}

//       update status

export async function updateAltStatusAction(uuid, data) {
  init();
  store.dispatch({
    type: UPDATE_STATUS_ALTERNATIVE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: UPDATE_STATUS_ALTERNATIVE,
    payload: {},
  });
  try {
    const res = await updateAltStatus(uuid, data);

    const id = window.location.pathname.split("/")[2];
    manageAltAction(id);

    if (res.data) {
      store.dispatch({
        type: UPDATE_STATUS_ALTERNATIVE,
        payload: res.data,
      });
      store.dispatch({
        type: UPDATE_STATUS_ALTERNATIVE_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: UPDATE_STATUS_ALTERNATIVE_LOADING,
      payload: false,
    });
  }
}

// get manage Alts

export const manageAltAction = async (id) => {
  managedAltsAction(id);
  pendingAltAction(id);
  acceptedAltAction(id);
  rejectedAltAction(id);
  additionAltAction(id);
  deletionAltAction(id);
  syncDateAction(id);
};

export async function managedAltsAction(id) {
  init();
  store.dispatch({
    type: MANAGED_ALTS_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MANAGED_ALTS,
    payload: {},
  });
  try {
    const res = await managedAlts(id);
    if (res.data) {
      store.dispatch({
        type: MANAGED_ALTS,
        payload: res.data,
      });
      store.dispatch({
        type: MANAGED_ALTS_LOADING,
        payload: false,
      });
      // successToast("Updated successfully");
    }
  } catch (error) {
    store.dispatch({
      type: MANAGED_ALTS_LOADING,
      payload: false,
    });
  }
}

export async function pendingAltAction(id) {
  init();
  store.dispatch({
    type: PENDING_ALTERNATIVE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: PENDING_ALTERNATIVE,
    payload: {},
  });
  try {
    const res = await managePendingAlt(id);
    // console.log(res.data);

    if (res.data) {
      store.dispatch({
        type: PENDING_ALTERNATIVE,
        payload: res.data.managed_alts,
      });
      store.dispatch({
        type: PENDING_ALTERNATIVE_LOADING,
        payload: false,
      });
      // successToast("Updated successfully");
    }
  } catch (error) {
    store.dispatch({
      type: PENDING_ALTERNATIVE_LOADING,
      payload: false,
    });
  }
}

export async function acceptedAltAction(id) {
  init();
  store.dispatch({
    type: ACCEPTED_ALTERNATIVE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ACCEPTED_ALTERNATIVE,
    payload: {},
  });
  try {
    const res = await manageAcceptedAlt(id);
    // console.log(res.data);

    if (res.data) {
      store.dispatch({
        type: ACCEPTED_ALTERNATIVE,
        payload: res.data.managed_alts,
      });
      store.dispatch({
        type: ACCEPTED_ALTERNATIVE_LOADING,
        payload: false,
      });
      // successToast("Updated successfully");
    }
  } catch (error) {
    store.dispatch({
      type: ACCEPTED_ALTERNATIVE_LOADING,
      payload: false,
    });
  }
}

export async function rejectedAltAction(id) {
  init();
  store.dispatch({
    type: REJECTED_ALTERNATIVE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: REJECTED_ALTERNATIVE,
    payload: [],
  });
  try {
    const res = await manageRejectedAlt(id);

    if (res.data) {
      store.dispatch({
        type: REJECTED_ALTERNATIVE,
        payload: res.data.managed_alts,
      });
      store.dispatch({
        type: REJECTED_ALTERNATIVE_LOADING,
        payload: false,
      });
      // successToast("Updated successfully");
    }
  } catch (error) {
    store.dispatch({
      type: REJECTED_ALTERNATIVE_LOADING,
      payload: false,
    });
  }
}

export async function additionAltAction(id) {
  init();
  store.dispatch({
    type: ADDITION_ALTERNATIVE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ADDITION_ALTERNATIVE,
    payload: [],
  });
  try {
    const res = await manageAdditionAlt(id);
    // console.log(res.data);

    if (res.data) {
      store.dispatch({
        type: ADDITION_ALTERNATIVE,
        payload: res.data.managed_alts,
      });
      store.dispatch({
        type: ADDITION_ALTERNATIVE_LOADING,
        payload: false,
      });
      // successToast("Updated successfully");
    }
  } catch (error) {
    store.dispatch({
      type: ADDITION_ALTERNATIVE_LOADING,
      payload: false,
    });
  }
}

export async function deletionAltAction(id) {
  init();
  store.dispatch({
    type: DELETION_ALTERNATIVE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: DELETION_ALTERNATIVE,
    payload: [],
  });
  try {
    const res = await manageDeletionAlt(id);
    // console.log(res.data);

    if (res.data) {
      store.dispatch({
        type: DELETION_ALTERNATIVE,
        payload: res.data.managed_alts,
      });
      store.dispatch({
        type: DELETION_ALTERNATIVE_LOADING,
        payload: false,
      });
      // successToast("Updated successfully");
    }
  } catch (error) {
    store.dispatch({
      type: DELETION_ALTERNATIVE_LOADING,
      payload: false,
    });
  }
}

// last sync date

export async function syncDateAction(id) {
  init();
  store.dispatch({
    type: TIME_SYNCED_LOADING,
    payload: true,
  });
  store.dispatch({
    type: TIME_SYNCED,
    payload: {},
  });
  try {
    const res = await lastSync(id);
    // console.log(res.data);

    if (res.data) {
      store.dispatch({
        type: TIME_SYNCED,
        payload: {
          time_last_synced: res.data.time_last_synced,
          time_alts_updated: res.data.time_alts_updated,
        },
      });
      store.dispatch({
        type: TIME_SYNCED_LOADING,
        payload: false,
      });
      // successToast("Updated successfully");
    }
  } catch (error) {
    store.dispatch({
      type: TIME_SYNCED_LOADING,
      payload: false,
    });
  }
}

//     SYNCHRONIXE BOM

export async function syncBomAction(id) {
  init();
  store.dispatch({
    type: SYNC_BOM_LOADING,
    payload: true,
  });
  store.dispatch({
    type: SYNC_BOM,
    payload: {},
  });
  try {
    const res = await synchronizeBom(id);
    // console.log(res.data);

    if (res.data) {
      syncDateAction(id);
      store.dispatch({
        type: SYNC_BOM,
        payload: res.data,
      });
      store.dispatch({
        type: SYNC_BOM_LOADING,
        payload: false,
      });
      // successToast("Updated successfully");
    }
  } catch (error) {
    store.dispatch({
      type: SYNC_BOM_LOADING,
      payload: false,
    });
  }
}
//  bom comments

//   list of mpn comments
export function bomCommentsListAction(id) {
  init();
  store.dispatch({
    type: BOM_COMMENT_LIST_LOADING,
    payload: true,
  });
  return async function (dispatch) {
    try {
      const res = await getAllBOMComments(id);

      dispatch({
        type: BOM_COMMENT_LIST_LOADING,
        payload: false,
      });
      store.dispatch({
        type: BOM_COMMENT_LIST,
        payload: [],
      });
      if (res.data.comments) {
        dispatch({
          type: BOM_COMMENT_LIST,
          payload: res.data.comments,
        });
        dispatch({
          type: BOM_COMMENT_LIST_RAW,
          payload: res.data,
        });
        dispatch({
          type: BOM_COMMENT_LIST_LOADING,
          payload: false,
        });
      }
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch({
        type: BOM_COMMENT_LIST_LOADING,
        payload: false,
      });
    }
  };
}
//  Bom add comment
export function addBomCommentAction(id, data) {
  init();
  store.dispatch({
    type: ADD_BOM_COMMENT_LOADING,
    payload: true,
  });
  return async function (dispatch) {
    try {
      const res = await addBOMComment(id, data);
      console.log(res.data);
      if (res.data) {
        store.dispatch(bomCommentsListAction(id));
        dispatch({
          type: ADD_BOM_COMMENT,
          payload: res.data,
        });
        dispatch({
          type: ADD_BOM_COMMENT_LOADING,
          payload: false,
        });
      }
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch({
        type: ADD_BOM_COMMENT_LOADING,
        payload: false,
      });
    }
  };
}

export const bomRevUpdateAction = async (id, data) => {
  init();
  store.dispatch({
    type: REV_BOM_LOADING,
    payload: true,
  });

  try {
    const res = await updateBomRev(id, data);

    if (res.data) {
      successToast("updated successfull");
      store.dispatch({
        type: REV_BOM,
        payload: res.data,
      });
      store.dispatch({
        type: REV_BOM_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: REV_BOM_LOADING,
      payload: false,
    });
  }
};

//  delet LineItem

export const deleteLineItemAction = async (id, revId) => {
  init();
  store.dispatch({
    type: DELETE_LINEITEMS_LOADING,
    payload: true,
  });

  try {
    const res = await deleteLineItem(id);

    if (res.status == 204) {
      successToast("Line Item Deleted.");
      getBOMLineItemsAction(revId);
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: DELETE_LINEITEMS_LOADING,
      payload: false,
    });
  }
};

//   Delete All LineItems

export const deleteAllLineItemAction = async (id) => {
  init();
  store.dispatch({
    type: DELETE_ALL_LINEITEMS_LOADING,
    payload: true,
  });

  try {
    const res = await deleteAllLineItems(id);

    if (res.status == 204) {
      successToast("Line Item Deleted.");
      getBOMLineItemsAction(id);
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: DELETE_ALL_LINEITEMS_LOADING,
      payload: false,
    });
  }
};

export const errorsCountAction = async (uuid) => {
  init();
  store.dispatch({
    type: ERROR_COUNT_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ERROR_COUNT,
    payload: {},
  });
  try {
    const res = await errorCounterLineItems(uuid);
    if (res) {
      store.dispatch({
        type: ERROR_COUNT,
        payload: res.data.message_counts,
      });
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: ERROR_COUNT_LOADING,
      payload: false,
    });
  }
};
