import React, { Component } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { successToast } from "../../../helpers/utility";

export default class SymbolsTable extends Component {
  render() {
    return (
      <Row>
        <Col lg={6} className="mx-auto">
          <Table
            striped
            bordered
            hover
            variant="dark"
            className="mb-2 text-center"
          >
            <thead>
              <tr>
                <th>
                  <button
                    className="btn btn-outline-light btn-sm"
                    value={"°"}
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.value);
                      successToast("Copied from clipboard!");
                    }}
                  >
                    °
                  </button>
                </th>
                <th>
                  <button
                    className="btn btn-outline-light btn-sm"
                    value={"°C"}
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.value);
                      successToast("Copied from clipboard!");
                    }}
                  >
                    °C
                  </button>
                </th>
                <th>
                  <button
                    className="btn btn-outline-light btn-sm"
                    value={"μ"}
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.value);
                      successToast("Copied from clipboard!");
                    }}
                  >
                    μ
                  </button>
                </th>
                <th>
                  <button
                    className="btn btn-outline-light btn-sm"
                    value={"μA"}
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.value);
                      successToast("Copied from clipboard!");
                    }}
                  >
                    μA
                  </button>
                </th>
                <th>
                  <button
                    className="btn btn-outline-light btn-sm"
                    value={"μm"}
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.value);
                      successToast("Copied from clipboard!");
                    }}
                  >
                    μm
                  </button>
                </th>
                <th>
                  <button
                    className="btn btn-outline-light btn-sm"
                    value={"μF"}
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.value);
                      successToast("Copied from clipboard!");
                    }}
                  >
                    μF
                  </button>
                </th>
                <th>
                  <button
                    className="btn btn-outline-light btn-sm"
                    value={"μH"}
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.value);
                      successToast("Copied from clipboard!");
                    }}
                  >
                    μH
                  </button>
                </th>
                <th>
                  <button
                    className="btn btn-outline-light btn-sm"
                    value={"mΩ"}
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.value);
                      successToast("Copied from clipboard!");
                    }}
                  >
                    mΩ
                  </button>
                </th>
                <th>
                  <button
                    className="btn btn-outline-light btn-sm"
                    value={"Ω"}
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.value);
                      successToast("Copied from clipboard!");
                    }}
                  >
                    Ω
                  </button>
                </th>
                <th>
                  <button
                    className="btn btn-outline-light btn-sm"
                    value={"kΩ"}
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.value);
                      successToast("Copied from clipboard!");
                    }}
                  >
                    kΩ
                  </button>
                </th>
                <th>
                  <button
                    className="btn btn-outline-light btn-sm"
                    value={"MΩ"}
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.value);
                      successToast("Copied from clipboard!");
                    }}
                  >
                    MΩ
                  </button>
                </th>
                <th>
                  <button
                    className="btn btn-outline-light btn-sm"
                    value={"±"}
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.value);
                      successToast("Copied from clipboard!");
                    }}
                  >
                    ±
                  </button>
                </th>
              </tr>
            </thead>
          </Table>
        </Col>
      </Row>
    );
  }
}
