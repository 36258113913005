import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoginForm from "../../../components/user/login-form";
// import "./Login.scss";

export default class LoginContainer extends React.Component {
    constructor(props) {
        super(props);
        // this.handleChange = this.handleChange.bind(this);
    }
    render() {
        // window.analytics.page('login-form'); // send tracking info to Segment that user is on login-form page (SANI)
        return (
            <div>
                {/* <Header href={TO_SIGN_UP} text={"Sign up"} /> */}
                <div className="login-form">
                    <Container>
                        <Row>
                            <Col className="text-center mx-auto" xs={5}>
                                <h1 className="text-left pt-4">Sign In</h1>
                                <LoginForm {...this.props} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* <Footer /> */}
            </div>
        )
    }
}
