import { getAlternativeList } from "../../api/inventory/alternative-api";

let timeoutId = null;
export const loadOptions = (inputValue, callback) => {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(async () => {
    // console.log("alt list", inputValue);
    const list = await getAlternativeList({
      request_content: { search_query: inputValue },
    });
    // console.log(list);
    callback(
        list.data.alternative_list.map((el) => ({
            id: el.id,
            value: el.id,
            description: el.base_group_pn?.description,
            // ics_pn: el.ics_pn,
            // manufacturer_pn: el.manufacturer_pn,
            label: `[${el.base_group_pn?.group_pn}] ${el.base_group_pn?.description}`,
          }))
    );
  }, 1000);

  // setTimeout(() => {
  //   callback(filterColors(inputValue));
  // }, 1000);
};
