import { http } from "../http";
import { ALTERNATIVE_API, BOMS_API } from "../constants";
import { altertnativeList } from "../exmple.json";

export const getAlternativeList = async (data = {}) => {
  return http.get(ALTERNATIVE_API, {
    params: { ...altertnativeList, ...data },
  });
};

export const getAlternativeClasses = async () => {
  return http.get(ALTERNATIVE_API + "/classes");
};

export const createAlternative = async (data) => {
  return http.post(ALTERNATIVE_API, data);
};

export const getAlternative = async (id) => {
  return http.get(ALTERNATIVE_API + "/" + id);
};

export const updateAlternative = async (id, data) => {
  return http.put(ALTERNATIVE_API + "/" + id, data);
};
