import { React, Component, Fragment } from "react";
import Header from "../components/header/header";

export default class NotFoundContainer extends Component {
  constructor(props) {
    super(props);
    // this.handleChange = this.handleChange.bind(this);
  }
  render() {
    return (
      <Fragment>
        <Header />
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="w-50">
            <h1>404: Not Found</h1>
            <p>
              The requested URL was not found on the server. If you entered the
              URL manually please check your spelling and try again. If you
              didn't enter the URL manually, sorry about the bad luck.
            </p>
          </div>
        </div>
      </Fragment>
    );
  }
}
