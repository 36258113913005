import React, { Component, Fragment } from "react";
import Header from "../../components/header/header";
import { connect } from "react-redux";
import store from "../../store";
import { getGPNListAction } from "../../actions/inventory/gpn-action";
import { stringToInt } from "../../helpers/utility";
import qs from "qs";
import { updateQueryParams } from "../../helpers/utility";
import { getBOMClassesAction } from "../../actions/bom/bom-action";
import AddEditBom from "../../components/bom/add-edit-bom";

class AddBomContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gpnList: [],
      queryParams: {},
      requestContent: {
        search_query: "",
      },
      addBom: {},
    };
    this.headerSearch = this.headerSearch.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    document.title = "Add Bom";
    this.setState(
      {
        queryParams: stringToInt(
          qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        ),
      },
      () => {
        this.getData(this.state.queryParams);
      }
    );
    getBOMClassesAction();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.gpnList) !== JSON.stringify(this.props.gpnList)
    ) {
      this.setState({
        gpnList: this.props.gpnList,
      });
    }
    if (
      JSON.stringify(prevProps.addBom) !== JSON.stringify(this.props.addBom)
    ) {
      this.setState({
        addBom: this.props.addBom,
      });
    }
  }

  headerSearch(searchQuery) {
    this.setState(
      {
        requestContent: {
          ...this.state.requestContent,
          search_query: searchQuery,
        },
      },
      () => {
        updateQueryParams(this.state.requestContent);

        this.getData(this.state.requestContent);
      }
    );
  }

  // get gpn list
  getData(requestContent = {}, type = "list") {
    requestContent = stringToInt(requestContent);
    console.log("getData: ", requestContent);
    store.dispatch(
      getGPNListAction({
        request_content: { ...requestContent, items_per_page: 10000 },
        request_type: type,
      })
    );
  }
  render() {
    // console.log(this.state.gpnList);
    // console.log(this.state.addBom);
    return (
      <Fragment>
        <Header />
        <AddEditBom
          is_add={true}
          search={this.headerSearch}
          queryParams={this.state.queryParams}
          product={this.state.gpnList}
          bomClasses={this.props.bomClasses}
          newId={this.state.addBom}
        />
      </Fragment>
    );
  }
}
const stateMap = (state) => {
  return {
    gpnList: state.inventory.gpnList,
    gpnListRaw: state.inventory.gpnListRaw,
    gpnListLoading: state.inventory.gpnListLoading,
    bomClasses: state.bom.bomClasses,
    bomClassesLoading: state.bom.bomClassesLoading,
    addBom: state.bom.addBom,
    addBomLoading: state.bom.addBomLoading,
  };
};
export default connect(stateMap)(AddBomContainer);
