import { init } from "../../api/http";
import { getDoc } from "../../api/docs/docs-api";
import { errorToast } from "../../helpers/utility";
import store from "../../store";
import { TO_DOC } from "../../helpers/constants";

// export function docsAction() {
//     init();
//     store.dispatch({
//         type: TO_DOC,
//         payload: {}
//     })
// }

export const getDocByKey = async (page_key) => {
    init();
    store.dispatch({
        type: TO_DOC,
        payload: ''
    })
    try {
        const res = await getDoc(page_key);
        if(res.data){
            store.dispatch({
                type: TO_DOC,
                payload: res.data
            })
        }
    } catch (error) {
        errorToast(
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
    }
}
