import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";

export default class WarningModal extends Component {
  render() {
    return (
      <Modal show={true} backdrop="static" keyboard={false} onHide={() => this.props.callback(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-warning">Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This BOM revision already has line items associated with it. Importing
          this file will add to the items, not overwrite them. If you want to
          remove existing line items first, use the "Delete All" button at the
          top of the line items tab.<br />
          Are you sure you want to go ahead with this import as-is?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.props.callback(false)}>Cancel</Button>
          <Button variant="primary" onClick={() => this.props.callback(true)}>Continue</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
