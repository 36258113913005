import React, { Component } from 'react'
import Header from '../../../components/header/header'

export default class AdminContainer extends Component {
  render() {
    return (
      <div>
          <Header />
          <h2 className='text-center pt-5 mt-5'>Admin Console</h2>
      </div>
    )
  }
}
