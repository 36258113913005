import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Button,
  Modal,
} from "react-bootstrap";
import {
  addImportAction,
  addPreImportAction,
  ImportStatusAction,
  getBOMLineItemsAction,
} from "../../actions/bom/bom-action";
import Loading from "../shared/loader/spinner";
import Tasks from "./Tasks";
import WarningModal from "./warningModal";
import ImportDropdowns from "./importDropdowns";

// Get the default key to set for a dropdown based on the title row
// s is any string
function column_title_to_val(s) {
  // Make lowercase, remove spaces and special characters
  const lower = s.toLowerCase().replace(/[^a-z]/g, "");

  if (["fitted", "fit", "placed"].includes(lower)) {
    return "fitted";
  }
  if (["critical", "crit"].includes(lower)) {
    return "critical";
  }
  if (["manufacturer", "mfr", "mfgr"].includes(lower)) {
    return "manufacturer";
  }
  if (
    ["manufacturerpartnumber", "manufacturerpn", "mpn", "pn"].includes(lower)
  ) {
    return "manufacturer_pn";
  }
  if (["icspn", "ipn"].includes(lower)) {
    return "ics_pn";
  }
  if (["designator", "des", "reference"].includes(lower)) {
    return "designator";
  }
  if (["side", "layer"].includes(lower)) {
    return "side";
  }
  if (["quantity", "qty", "count"].includes(lower)) {
    return "quantity";
  }
  if (["xposmm", "refxmm", "xmm"].includes(lower)) {
    return "xpos_mm";
  }
  if (["yposmm", "refymm", "ymm"].includes(lower)) {
    return "ypos_mm";
  }
  if (["rotation", "rot", "rotationdeg"].includes(lower)) {
    return "rotation";
  }

  return "";
}

class PopulateBom extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props.match.params.id);
    this.state = {
      fileType: "csv",
      file: "",
      preImport: [],
      columns: [],
      row: "1",
      query: "",
      status: false,
      importStatus: [],
      time: 0,
      completed: false,
      showWarning: false,
      critical: "DEFAULT_FALSE",
      fitted: "DEFAULT_TRUE",
      designator: "ERROR",
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.state.status && !this.state.completed) {
        ImportStatusAction(this.props.addImport.task_id);
      }
    }, 2000);
  }
  //
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps, prevState) {
    // Handle the case where the file preview updates
    if (prevProps.preImport !== this.props.preImport) {
      this.setState({ preImport: this.props.preImport });
      let arr = [];
      this.props.preImport.length &&
        this.props.preImport[0].map((el, index) => {
          // This sets the initial values of the dropdowns based on the first
          // row of data displayed
          let data = {
            type: column_title_to_val(el),
            column: index,
          };
          arr.push(data);
        });
      this.setState({ columns: arr });
    }
    // this.props.bomFiles?.[0].uuid
    if (
      JSON.stringify(prevProps.bomFiles) !== JSON.stringify(this.props.bomFiles)
    ) {
      console.log("files incoming!");
      let url =
        window.location.protocol +
        window.location.pathname +
        `?type=${"csv"}&file=${this.props.bomFiles[0]?.uuid}`;
      window.history.pushState({ path: url }, "", url);
      if (window.location.search.includes("?")) {
        var type = window.location.search.split("&")[0].split("=")[1];
        this.setState({ fileType: type });
        var file = window.location.search.split("=")[2];
        this.setState({ file: file });
        setTimeout(() => {
          this.getPreImport();
        }, 1000);
      }
    }
    if (prevProps.addImport !== this.props.addImport) {
      if (this.props.addImport.task_id) {
        ImportStatusAction(this.props.addImport.task_id);
        this.setState({ status: true });
      }
    }
    //
    if (
      JSON.stringify(prevProps.importStatus) !==
      JSON.stringify(this.props.importStatus)
    ) {
      this.setState({ importStatus: this.props.importStatus });
      let status = [];
      this.props.importStatus.map((el) => {
        if (
          el.state == "SUCCESS" ||
          el.state == "FAILURE" ||
          el.state == "REVOKED"
        ) {
          status.push(true);
        } else {
          status.push(false);
        }
      });
      const total = status.every((el) => {
        return el == true;
      });
      if (total == true) {
        this.setState({ completed: true });
      }
    }
  }
  //
  getPreImport = () => {
    const data = {
      file_uuid: this.state.file,
      file_type: this.state.fileType,
    };
    const id = this.state.file;
    addPreImportAction(id, data);
  };
  filetypeChange = (e) => {
    //   let url =
    //     window.location.protocol +
    //     window.location.pathname +
    //     `?type=${e.target.value}&file=${this.state.file}`;
    //   window.history.pushState({ path: url }, "", url);
    if (
      this.state.file !== "" &&
      this.state.file !== "undefined" &&
      this.state.file !== null
    ) {
      this.setState({ fileType: e.target.value }, () => {
        this.getPreImport();
      });
    } else {
      this.setState({ fileType: e.target.value });
    }
  };
  fileChange = (e) => {
    // let url =
    //   window.location.protocol +
    //   window.location.pathname +
    //   `?type=${this.state.fileType}&file=${e.target.value}`;
    // window.history.pushState({ path: url }, "", url);
    if (
      this.state.fileType !== "" &&
      this.state.fileType !== "undefined" &&
      this.state.fileType !== null
    ) {
      this.setState({ file: e.target.value }, () => {
        this.getPreImport();
      });
    } else {
      this.setState({ file: e.target.value });
    }
  };

  // When column dropdowns are changed, update state to accurately reflect their
  // values
  columnChange = (event, ind) => {
    // Find the existing column with the same index
    const columnMatch = this.state.columns.find((el) => el.column == ind);

    if (columnMatch) {
      // If it exists, update it
      columnMatch.type = event.target.value;
    } else {
      // Otherwise, add a new item
      let data = {
        type: event.target.value,
        column: ind,
      };
      this.setState({ columns: [...this.state.columns, data] });
    }
  };

  handleAdd = (e) => {
    e.preventDefault();
    if (this.props.bomLineitems) {
      this.setState({ showWarning: true });
    } else {
      let data = {
        file_uuid: this.state.file,
        file_type: this.state.fileType,
        first_row: this.state.row,
        columns: this.state.columns,
        critical_handling: this.state.critical,
        fitted_handling: this.state.fitted,
        designator_handling: this.state.designator,
      };
      addImportAction(this.props.revId, data);
    }
  };

  handleCallback = (e) => {
    this.setState({ showWarning: false });
    if (e) {
      let data = {
        file_uuid: this.state.file,
        file_type: this.state.fileType,
        first_row: this.state.row,
        columns: this.state.columns,
        critical_handling: this.state.critical,
        fitted_handling: this.state.fitted,
        designator_handling: this.state.designator,
      };
      addImportAction(this.props.revId, data);
    } else {
      this.props.populateModal(false);
    }
  };

  render() {
    if (this.state.completed) {
      setTimeout(() => {
        this.props.populateModal(false);
        getBOMLineItemsAction(this.props.revId);
      }, 1500);
    }
    return (
      <div>
        {this.state.showWarning && (
          <WarningModal callback={this.handleCallback} />
        )}
        <Modal
          show={true}
          onHide={() => this.props.populateModal(false)}
          centered
          fullscreen
        >
          <Modal.Header closeButton>
            <Modal.Title>Load line items from file</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              {!this.state.status && (
                <>
                  <Row className="pb-5">
                    <Col md={3}>
                      <Form.Select
                        aria-label="type"
                        as="select"
                        value={this.state.fileType}
                        defaultValue={this.state.fileType}
                        onChange={this.filetypeChange}
                      >
                        <option value="csv">CSV</option>
                        <option value="tsv">TSV</option>
                        <option value="pnp">PNP</option>
                        <option value="xls">XLS</option>
                      </Form.Select>
                    </Col>
                    <Col md={3}>
                      <Form.Select
                        aria-label="type"
                        as="select"
                        value={this.state.file}
                        defaultValue={this.state.file}
                        defaultSele
                        onChange={this.fileChange}
                      >
                        <option value={""}>Select File</option>
                        {this.props.bomFiles.map((el) => (
                          <option key={el.uuid} value={el.uuid} name={el.name}>
                            {el.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md={3}></Col>
                    <Col md={3}>
                      {/* <Button onClick={this.handleAdd}>Start Import</Button> */}
                    </Col>
                  </Row>
                  {/*       section 2      */}
                  <Row className="mb-4" style={{ overflow: "scroll" }}>
                    {this.props.preImportLoading ? (
                      <h4 className="text-center py-4">
                        <Loading />
                      </h4>
                    ) : (
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            {this.state.preImport[0]?.map((element, index) => {
                              return (
                                <th key={index}>
                                  <Form.Select
                                    aria-label="type"
                                    defaultValue={column_title_to_val(element)}
                                    onChange={(e) =>
                                      this.columnChange(e, index)
                                    }
                                  >
                                    <option value="">none</option>
                                    <option value="fitted">Fitted</option>
                                    <option value="critical">Critical</option>
                                    <option value="manufacturer">
                                      Manufacturer
                                    </option>
                                    <option value="manufacturer_pn">MPN</option>
                                    <option value="ics_pn">IPN</option>
                                    <option value="designator">
                                      Designator
                                    </option>
                                    <option value="side">Side</option>
                                    <option value="quantity">Quantity</option>
                                    <option value="xpos_mm">X Position (mm)</option>
                                    <option value="ypos_mm">Y Position (mm)</option>
                                    <option value="rotation">Rotation</option>
                                  </Form.Select>
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.preImport.length > 0 &&
                            this.state.preImport?.map(
                              (el, index) => (
                                // el.map((elem, index2) => (
                                <tr key={index}>
                                  {el.map((element, index) => (
                                    <td
                                      key={index}
                                      style={{ minWidth: "200px" }}
                                    >
                                      {element}
                                    </td>
                                  ))}
                                </tr>
                              )
                              // ))
                            )}
                        </tbody>
                      </Table>
                    )}
                  </Row>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4" lg="2">
                      First Row of data
                    </Form.Label>
                    <br />
                    <Col md={3}>
                      <Form.Select
                        aria-label="type"
                        as="select"
                        defaultValue="1"
                        onChange={(e) => this.setState({ row: e.target.value })}
                      >
                        <option value="0">1</option>
                        <option value="1">2</option>
                        <option value="2">3</option>
                        <option value="3">4</option>
                        <option value="4">5</option>
                        <option value="5">6</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <ImportDropdowns
                    critical={this.state.critical}
                    fitted={this.state.fitted}
                    designator={this.state.designator}
                    set_critical={(e) => this.setState({ critical: e })}
                    set_fitted={(e) => this.setState({ fitted: e })}
                    set_designator={(e) => this.setState({ designator: e })}
                  />
                  <Form.Group as={Row} className="my-4">
                    <Col sm={"10"} lg={"4"}>
                      <Button onClick={this.handleAdd}>Start Import</Button>

                      {/* <Link to={`/bom/edit/${id}`}>
                <Button className='btn btn-danger'>Cancel</Button>
              </Link> */}
                    </Col>
                  </Form.Group>
                </>
              )}
              {this.state.status && (
                <>
                  <Tasks importStatus={this.state.importStatus} />
                </>
              )}
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    bomFiles: state.bom.bomFiles,
    bomFilesLoading: state.bom.bomFilesLoading,
    preImport: state.bom.preImport,
    preImportLoading: state.bom.preImportLoading,
    addImport: state.bom.addImport,
    addImportLoading: state.bom.addImportLoading,
    bomDetails: state.bom.bomDetails,
    bomDetailsLoading: state.bom.bomDetailsLoading,
    importStatus: state.bom.importStatus,
    importStatusLoading: state.bom.importStatusLoading,
    bomLineitems: state.bom.bomLineitems,
  };
};

export default connect(stateMap)(PopulateBom);
