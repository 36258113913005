/** mpn-parameter-table.js
 * Main table content for MPN add & edit
 */

import React, { Component } from "react";

import { Col, Form, FormControl, Row } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import { mpnAutocompleteAction } from "../../../actions/inventory/mpn-action";
import store from "../../../store";

// Standard column sizes to use
export const COL_W = {
  lab: { lg: 3, sm: 12 },
  field: { lg: 4, sm: 12 },
  suggest: { lg: 3, sm: 8 },
  btn: { lg: 2, sm: 4 },
};

function getAutocompleteOps(autocomplete, field_name) {
  if (autocomplete == null) return [];
  let tmp = autocomplete[field_name];
  if (tmp == null) return [];
  return tmp;
}

/**
 * This is a single row in the add/edit view that provides input and output
 * TODO:
 * Add type (string or bool) to fieldtypes
 * Add required partTypeFields
 * Disable add button until required partTypeFields are filled in
 */
class ParamRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autocompleteOpts: getAutocompleteOps(
        this.props.autocomplete,
        this.props.field.field_name
      ),
      value: this.props.value,
    };
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //     const ac1 = JSON.stringify(getAutocompleteOps(this.props.autocomplete, this.props.field.field_name));
  //     const ac2 = JSON.stringify(getAutocompleteOps(nextProps.autocomplete, nextProps.field.field_name));
  //     if (ac1 == ac2) return false;
  //     return true;
  // }

  fieldUpdateCallback = (event) => {
    const value = event;
    this.props.fieldCallback(this.props.field.field_name, value);
  };

  fieldUpdateCallbackAutocomplete = (event) => {
    const value = event;
    // this.setState({ inputValue: value });
    this.props.fieldCallback(this.props.field.field_name, value);
    store.dispatch(mpnAutocompleteAction(this.props.field.field_name, value));
    2;
  };

  handleSuggestionClick = () => {
    this.props.fieldCallback(
      this.props.field.field_name,
      this.props.suggestion
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.autocomplete) !==
      JSON.stringify(this.props.autocomplete)
    ) {
      const newopts = getAutocompleteOps(
        this.props.autocomplete,
        this.props.field.field_name
      );
      if (newopts != null) {
        this.setState({
          autocompleteOpts: newopts,
        });
      }
    }

    if (prevProps.value !== this.props.value) {
      // console.log("vvalue changed----");
      // console.log(this.props.value, "==============PROPS");
      this.setState({ value: this.props.value });
    }
    if (prevState.value !== this.state.value) {
      this.setState({ value: this.props.value });
    }
  }
  // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    // console.log(this.state.value, '===========STATE');
    const field = this.props.field;
    if (field.display_type === "longstring") {
      // console.log(field);
    }
    // console.log(this.props.autocomplete);
    const required = field.required ? "required" : "";
    let form_field = null;

    // Set up input type
    if (field.display_type.startsWith("enum")) {
      // Format enum('a',"b",'c,c,c'). First get rid of 'enum(' and ')'
      // const enum_content = str.split(/(\(|\))/)[2];
      const enum_values = field.display_type.split(/(\('|\("|','|","|'\)|"\))/);
      let opts = [];

      // Only extract the fields we care about. Skipping the first two
      // values and even results will get us that.
      for (var i = 2; i < enum_values.length - 1; i++) {
        if (i % 2 == 1) {
          continue;
        }
        opts.push(enum_values[i]);
      }

      const dropdown_options = opts.map((opt) => (
        <option value={opt} key={opt}>
          {opt}
        </option>
      ));

      form_field = (
        <Form.Select
          size="sm"
          className={`my-1 ${required}`}
          value={(this.props.value ? this.props.value:'')}
          onChange={(e) => this.fieldUpdateCallback(e.target.value)}
        >
          {dropdown_options}
        </Form.Select>
      );
    } else if (field.display_type === "bool") {
      form_field = (
        <Form.Check
          type="checkbox"
          className={required}
          value={(this.props.value ? this.props.value:'')}
          onChange={(e) => this.fieldUpdateCallback(e.target.value)}
        />
      );
    } else if (field.display_type === "longstring") {
      form_field = (
        <Form.Control
          value={(this.props.value ? this.props.value:'')}
          onChange={this.fieldUpdateCallback}
          as="textarea"
          rows={3}
        />
      );
    } else {
      form_field = (
        <AsyncTypeahead
          id="async-example"
          className="my-1 p-0"
          // isLoading={this.props.autocompleteLoading}
          labelKey=""
          size="sm"
          selected={[`${(this.props.value ? this.props.value:'')}`]}
          onInputChange={(e) => {
            this.fieldUpdateCallbackAutocomplete(e);
          }}
          onChange={(e) => {
            if (e[0] !== undefined) {
              this.fieldUpdateCallback(e[0]);
            }
          }}
          onSearch={(e) => {
            this.fieldUpdateCallbackAutocomplete(e);
          }}
          options={this.props.autocomplete}
          placeholder=""
        />
      );
    }

    return (
      <Form.Group
        as={Row}
        id={`formgrp - ${field.field_name}`}
        key={field.field_name}
        className="mb-1 align-items-center"
      >
        <Form.Label column lg={COL_W.lab.lg} sm={COL_W.lab.sm} className="">
          {field.display_name}
        </Form.Label>

        {/* Actual field */}
        <Col lg={COL_W.field.lg} sm={COL_W.field.sm} className="">
          {form_field}
        </Col>

        {/* Suggestion location */}
        <Col
          lg={COL_W.suggest.lg}
          sm={COL_W.suggest.sm}
          style={{ fontSize: "0.95rem", color: "#bbb" }}
        >
          {/* {suggestion} */}
          <div className="align-middle">{this.props.suggestion}</div>
        </Col>

        {/* Suggestion button */}
        <Col lg={COL_W.btn.lg} sm={COL_W.btn.sm} className="float-end">
          <button
            className="btn btn-outline-light btn-sm suggest-button"
            type="button"
            onClick={this.handleSuggestionClick}
          >
            Use Suggestion
          </button>
        </Col>
      </Form.Group>
    );
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export class ParameterTable extends Component {
  render() {
    const suggestions = this.props.suggestions;
    const parameters = this.props.parameters;
    const autocomplete = this.props.autocomplete;
    const autocompleteLoading = this.props.autocompleteLoading;
    const rows = [];
    let lastGroup = null;

    this.props.partTypeFields.forEach((field) => {
      if (field.display_type === "_hidden_") {
        // Note; this breaks out of forEach, isn't a 'real' return
        return;
      }

      const suggestion = suggestions[field.field_name];

      const paramItem = parameters.find(
        (param) => param.field_name === field.field_name
      );
      const paramValue = paramItem?.value;

      const groupIndex = Math.floor(field.visibility_sort_order / 100);

      if (groupIndex != lastGroup && lastGroup != null) {
        rows.push(<Row className="mb-4" key={`spacer - ${groupIndex}`}></Row>);
      }

      rows.push(
        <ParamRow
          field={field}
          key={field.field_name}
          suggestion={suggestion}
          value={paramValue}
          fieldCallback={this.props.fieldCallback}
          autocomplete={autocomplete}
          autocompleteLoading={autocompleteLoading}
          activePartType={this.props.activePartType}
        />
      );

      lastGroup = groupIndex;
    });

    return <fieldset>{rows}</fieldset>;
  }
}
