import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import { connect } from "react-redux";
import { bulkItemLookupAction } from "../../../actions/inventory/bulk-action";
import Header from "../../../components/header/header";
import { errorToast } from "../../../helpers/utility";
import store from "../../../store";
import "./bulk-container.scss";

class bulkContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: { items: [], type: "" },
      columnNames: [
        "group_pn",
        "ics_pn",
        "manufacturer_pn",
        "description",
        "gpn_class",
        "available_stock",
        "total_stock",
      ],
      columnNamesHeading: [
        { key: "GPN", width: "10%" },
        { key: "IPN", width: "10%" },
        { key: "MPN", width: "10%" },
        { key: "description", width: "40%" },
        { key: "Class", width: "10%" },
        { key: "Available", width: "10%" },
        { key: "Total", width: "5%" },
      ],
      grid1: [
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
        [{ value: "" }],
      ],
      grid2: [
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
        [{ value: "" }, { value: " " }, { value: "" }, { value: " " }],
      ],
    };
    this.onGridValueChanged = this.onGridValueChanged.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    document.title = "Bulk Process";
  }
  onGridValueChanged(values) {
    console.log(values);
    this.setState({ grid1: values });
    let items = [].concat(...values);
    items = items.filter((elem) => elem.value).map((elem) => elem.value);
    this.setState({ apiData: { ...this.state.apiData, items } });
  }
  handleInputChange(e) {
    this.setState({ apiData: { ...this.state.apiData, type: e.target.value } });
  }
  handleSubmit(e) {
    console.log(this.state.apiData);
    if (!this.state.apiData.type || !this.state.apiData.items.length) {
      errorToast("Inventory type and atleast 1 field is required");
      return;
    }
    store.dispatch(bulkItemLookupAction(this.state.apiData));
  }
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.bulkItemLookup) !==
      JSON.stringify(prevProps.bulkItemLookup)
    ) {
      this.props.bulkItemLookup.map((el) => {
        el.available_stock = el.gpn_stock?.available_stock;
        el.total_stock = el.gpn_stock?.total_stock;
      });

      let rows = [];
      this.props.bulkItemLookup.forEach((item, index) => {
        console.log(item);
        let row = [];
        if (index === 0) {
          this.state.columnNamesHeading.forEach((elem) => {
            row.push({ value: elem.key, width: elem.width });
          });
          rows.push(row);
          row = [];
        }
        this.state.columnNames.forEach((elem) => {
          row.push({ value: item[elem] });
        });
        // for (const key in item) {
        // }
        rows.push(row);
      });
      this.setState({ grid2: rows });
    }
  }
  render() {
    // console.log(this.props.bulkItemLookup);

    return (
      <div className="main-section bulk-items">
        <Header />

        <Container className="my-4">
          <h3>Bulk Lookup Tool</h3>
          <p className="text-muted">
            Note: the bulk lookup tool is good for quick reference, but has
            numerous limitations. Be sure to import a BOM if you need to match
            multiple GPNs, alternatives, error checking, etc.
          </p>
          <Row className="my-4 mt-4">
            <Col md={12}>
              <label>Lookup Type :</label>
            </Col>
            <Col md={3}>
              <select
                className="form-control"
                name="inventory"
                onChange={this.handleInputChange}
              >
                <option defaultValue>Select an option</option>
                <option value="gpn">GPN</option>
                <option value="mpn">MPN</option>
                <option value="ipn">IPN</option>
              </select>
            </Col>
            <Col md={1}>
              <Button
                type="button"
                onClick={this.handleSubmit}
                className="btn btn-block"
                variant="outline-success"
              >
                Search
              </Button>
            </Col>
          </Row>
          {/* </div> */}
          <Row className="my-4">
            <Col md={4}>
              <ReactDataSheet
                className="w-100"
                data={this.state.grid1}
                valueRenderer={(cell) => {
                  // cell.className = "py-2";
                  return cell.value;
                }}
                onCellsChanged={(changes, addition = []) => {
                  const grid1 = this.state.grid1.map((row) => [...row]);
                  changes.forEach(({ cell, row, col, value }) => {
                    grid1[row][col] = { ...grid1[row][col], value };
                  });
                  addition.forEach(({ value }) => grid1.push([{ value }]));
                  this.onGridValueChanged(grid1);
                }}
              />
            </Col>
            <Col md={8}>
              {" "}
              <ReactDataSheet
                className="w-100 data-table"
                data={this.state.grid2}
                valueRenderer={(cell) => {
                  cell.readOnly = true;
                  return cell.value;
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    bulkItemLookup: state.inventory.bulkItemLookup,
    bulkItemLookupLoading: state.inventory.bulkItemLookupLoading,
  };
};
export default connect(stateMap)(bulkContainer);
