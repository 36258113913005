import { getMPNList } from "../../../api/inventory/mpn-api";

let timeoutId = null;
export const loadOptions = (inputValue, callback) => {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(async () => {
    const list = await getMPNList({
      request_content: { search_query: inputValue },
    });

    callback(
      list.data.mpn_list.map((el) => ({
        id: el.id,
        value: el.id,
        description: el.description,
        ics_pn: el.ics_pn,
        manufacturer_pn: el.manufacturer_pn,
        label: `[${el.ics_pn}] ${el.description}`,
      }))
    );
  }, 1000);
};
