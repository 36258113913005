import React, { Component, Fragment } from "react";
import { getBOMListAction } from "../../actions/bom/bom-action";
import Header from "../../components/header/header";
import Search from "../../components/shared/search/search";
import CustomTable from "../../components/shared/table/table";
import store from "../../store";
import { connect } from "react-redux";
import qs from "qs";
import { stringToInt, updateQueryParams } from "../../helpers/utility";
import { TO_BOM, TO_GPN } from "../../helpers/constants";
import { Button } from "react-bootstrap";

class BOMListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bomList: [],
      columnName: [
        { label: "BOM", key: "name" },
        { label: "Produced GPN", key: "group_pn" },
        { label: "GPN Description", key: "description" },
        { label: "BOM Label", key: "label" },
        { label: "Class", key: "bom_class" },
        { label: "Updated", key: "last_updated" },
        { label: "", key: "" },
      ],
      queryParams: {},
      requestContent: {
        search_query: "",
      },
      pagination: {},
    };
    this.tableFilters = this.tableFilters.bind(this);
    this.headerSearch = this.headerSearch.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    document.title = "Bom List";
    this.setState(
      {
        queryParams: stringToInt(
          qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        ),
      },
      () => {
        this.getData(this.state.queryParams);
      }
    );

    getBOMListAction();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.bomList) !== JSON.stringify(this.props.bomList)
    ) {
      this.setState({
        bomList: this.props.bomList.map((elem) => {
          let highestDate = new Date(elem.time_updated);
          let currentDate = elem.time_updated
          elem.revisions.forEach(rev => {
            if(new Date(rev.time_updated) > highestDate) {
              highestDate = new Date(rev.time_updated)
              currentDate = rev.time_updated
            }
          })
          return {
            0: elem.name,
            1: <p style={{maxWidth: "120px"}}>{elem.product_group_pn ? elem.product_group_pn.group_pn : "N/A"}</p>,
            2: elem.product_group_pn ? elem.product_group_pn.description : "N/A",
            3: <p style={{maxWidth: "100px"}}>{elem.label}</p>,
            4: elem.bom_class ? elem.bom_class.name : "N/A",
            5: <p>{currentDate.split('T')[0]} {currentDate.split('T')[1].substring(0, 8)}</p>,
            6: (
              <>
                <Button
                  className="rounded-circle"
                  size="sm"
                  variant={
                    elem.engineering_validation == true
                      ? "outline-success"
                      : "outline-light"
                  }
                >
                  {elem.engineering_validation == true ? (
                    <i className="fa fa-check" />
                  ) : (
                    <i className="fa fa-check" />
                  )}
                </Button>
                &nbsp;
                <Button
                  className="rounded-circle"
                  size="sm"
                  variant={
                    elem.manufacturing_approval == true
                      ? "outline-success"
                      : "outline-light"
                  }
                >
                  {elem.manufacturing_approval == true ? (
                    <i className="fa fa-check" />
                  ) : (
                    <i className="fa fa-check" />
                  )}
                </Button>
                &nbsp;
                <Button
                  className="rounded-circle"
                  size="sm"
                  variant={
                    elem.buildable == true ? "outline-success" : "outline-light"
                  }
                >
                  {elem.buildable == true ? (
                    <i className="fa fa-check" />
                  ) : (
                    <i className="fa fa-check" />
                  )}
                </Button>
              </>
            ),
            id: elem.id,
            active: elem.active,
          };
        }),
      });
    }
    if (
      JSON.stringify(prevProps.bomListRaw) !==
      JSON.stringify(this.props.bomListRaw)
    ) {
      // PageCount
      this.setState({ pagination: this.props.bomListRaw.pagination });
    }
  }
  headerSearch(searchQuery) {
    this.setState(
      {
        requestContent: {
          ...this.state.requestContent,
          search_query: searchQuery,
        },
      },
      () => {
        updateQueryParams(this.state.requestContent);
        this.getData(this.state.requestContent);
      }
    );
  }

  tableFilters(requestContent = {}, type = "list") {
    let { search_query } = this.state.requestContent;
    this.setState(
      { requestContent: { ...requestContent, search_query } },
      () => {
        this.getData(this.state.requestContent, type);
      }
    );
  }
  handleRowClick = (id) => {
    console.log(id);
  };
  getData(requestContent = {}, type = "list") {
    requestContent = stringToInt(requestContent);
    // console.log("getData: ", requestContent);
    store.dispatch(
      getBOMListAction({ request_content: requestContent, request_type: type })
    );
  }

  render() {
    return (
      <Fragment>
        <Header>
          <Search
            search={this.headerSearch}
            queryParams={this.state.queryParams}
          />
        </Header>
        <div className="bom-section">
          <CustomTable
            queryParams={this.state.queryParams}
            columns={this.state.columnName}
            getId={this.handleRowClick}
            isLoading={this.props.bomListLoading}
            tableFilters={this.tableFilters}
            data={this.state.bomList}
            pagination={this.props.bomListRaw?.pagination}
          />
        </div>
      </Fragment>
    );
  }
}
const stateMap = (state) => {
  return {
    bomList: state.bom.bomList,
    bomListRaw: state.bom.bomListRaw,
    bomListLoading: state.bom.bomListLoading,
  };
};
export default connect(stateMap)(BOMListContainer);
