import cogoToast from 'cogo-toast';
import qs from 'qs';
import cookie from 'react-cookies';
import { notify } from 'react-notify-toast';

import {
  RefreshAction,
  revokeTokenAction,
} from '../actions/user/user-action';
import store from '../store';
import {
  IS_LOGIN,
  PATH_COOKIE,
  REFRESH_TOKEN_COOKIE,
  TOAST_DURATION,
  TOKEN_COOKIE,
  USER_COOKIE,
  USERNAME_COOKIE,
} from './constants';

// import { init } from '../api/http';

export const toast = (message = "Success", type = "success") => {
  notify.show(message, type, TOAST_DURATION);
};

export const errorToast = (msg) => {
  cogoToast.error(msg, {
    position: "top-center",
  });
};
export const successToast = (msg) => {
  cogoToast.success(msg, {
    position: "top-center",
  });
};
export const warningToast = (msg) => {
  cogoToast.warn(msg, {
    position: "top-center",
  });
};

export const setUser = (data) => {
  cookie.save(USER_COOKIE, data, { path: PATH_COOKIE });
  // window.location.reload()
};
export const getUser = () => {
  return cookie.load(USER_COOKIE);
};
export const setOrganization = (data) => {
  let user = cookie.load(USER_COOKIE);
  user.Organizations.push(data);
  cookie.save(USER_COOKIE, user, { path: PATH_COOKIE });
};

export const setToken = (data) => {
  cookie.save(TOKEN_COOKIE, data.access_token, {
    path: PATH_COOKIE,
    expires: new Date(data.access_expiry),
  });
  cookie.save(REFRESH_TOKEN_COOKIE, data.refresh_token, {
    path: PATH_COOKIE,
    expires: new Date(data.refresh_expiry),
  });
  cookie.save(USERNAME_COOKIE, data.username, {
    path: PATH_COOKIE,
    expires: new Date(data.refresh_expiry),
  });
};
export const getToken = () => {
  return cookie.load(TOKEN_COOKIE);
};
export const getRefreshToken = () => {
  return cookie.load(REFRESH_TOKEN_COOKIE);
};
export const getuserName = () => {
  return cookie.load(USERNAME_COOKIE);
};
//

export const tokenWatcher = () => {
  setInterval(() => {
    if (cookie.load(REFRESH_TOKEN_COOKIE)) {
      if (!cookie.load(TOKEN_COOKIE)) {
        RefreshAction();
      }
    }
  }, 500);
};

//

export const getAuthHeader = () => {
  return { authorization: `Bearer ${cookie.load(TOKEN_COOKIE)}` };
};

export const logout = () => {
  revokeTokenAction();
  cookie.remove(REFRESH_TOKEN_COOKIE, { path: PATH_COOKIE });
  cookie.remove(TOKEN_COOKIE, { path: PATH_COOKIE });
  cookie.remove(USER_COOKIE, { path: PATH_COOKIE });
  cookie.remove(USERNAME_COOKIE, { path: PATH_COOKIE });

  store.dispatch({ type: IS_LOGIN, payload: false });
  window.location.reload();
  // init()
};

export const updateQueryParams = (search) => {
  if (window.history.pushState) {
    for (const key in search) {
      if (!search[key]) delete search[key];
    }
    const query = qs.stringify(search);
    let newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      query;
    window.history.pushState({ path: newurl }, "", newurl);
  }
};

export const stringToInt = (object) => {
  for (const key in object) {
    if (!isNaN(object[key]) && object[key] != "")
      object[key] = parseInt(object[key]);
  }
  return object;
};

export const bytesToSize = (bytes) => {
  var sizes = ['B', 'kiB', 'MiB', 'GiB', 'TiB'];
  if (bytes == 0) return '0 B';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + '\u00A0' + sizes[i];
}
