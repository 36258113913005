import React, { Component } from "react";
import { connect } from "react-redux";
import {
  executeImportAction,
  getBOMDetailsAction,
  getImportsAction,
  ImportStatusAction,
} from "../../actions/bom/bom-action";
import {
  Container,
  ListGroup,
  Row,
  Badge,
  Button,
  Col,
  ProgressBar,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Loading from "../../components/shared/loader/spinner";
import Header from "../../components/header/header";

class importListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.title = "Bom Imports List";
    const id = this.props.match.params.id;
    getImportsAction(id);
    getBOMDetailsAction(id);
  }
  render() {
    const imports = this.props.getImports.sort(function (a, b) {
      return b.id - a.id;
    });
    const id = this.props.match.params.id;
    console.log(this.props.bomDetails?.name);
    return (
      <div>
        <Header />
        <Container>
          <Row>
            <div className="d-flex justify-content-start align-items-center mt-4">
              <Button
                variant="link text-white"
                onClick={() => this.props.history.goBack()}
              >
                <h3>{this.props.bomDetails?.name}</h3>
              </Button>
              <h3>: Task View</h3>
            </div>

            <ListGroup as="ul">
              {this.props.getImportsLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "100vh" }}
                >
                  <h4>
                    <Loading />
                  </h4>
                </div>
              ) : (
                this.props.getImports &&
                imports.map((el) => (
                  <Link
                    to={`/bom/import/${el.id}`}
                    style={{ textDecoration: "none" }}
                    className="my-2 rounded"
                    key={el.id}
                  >
                    <ListGroup.Item as="li">
                      <div className="d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">
                            Task {el.id}: &nbsp;
                            {el.type == "bom_import" ? "Import" : "Export"}
                          </div>
                          <small className="text-info">
                            <i>{el.status}</i>
                          </small>
                        </div>
                        <Badge variant="primary" pill>
                          {el.active == true ? "Active" : "Inactive"}
                        </Badge>
                      </div>
                      <ProgressBar now={60} />
                    </ListGroup.Item>
                  </Link>
                ))
              )}
            </ListGroup>
          </Row>
        </Container>
      </div>
    );
  }
}
const stateMap = (state) => {
  return {
    getImports: state.bom.getImports,
    getImportsLoading: state.bom.getImportsLoading,
    bomDetails: state.bom.bomDetails,
    bomDetailsLoading: state.bom.bomDetailsLoading,
  };
};

export default connect(stateMap)(importListContainer);
