import React, { Component } from "react";
import Button from "@restart/ui/esm/Button";
import { connect } from "react-redux";
import {
  deleteImportAction,
  executeImportAction,
  getImportAction,
  validateImportAction,
} from "../../actions/bom/bom-action";
import { Container, Row, Col, Table } from "react-bootstrap";
import Header from "../../components/header/header";
import Loading from "../../components/shared/loader/spinner";

class viewImportContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getImport: [],
    };
  }
  componentDidMount() {
    document.title = "Import Details";
    const id = window.location.pathname.split("/bom/import/")[1];
    getImportAction(id);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.getImport !== this.props.getImport) {
      this.setState({
        getImport:
          this.props.getImport.result !== null && this.props.getImport.result,
      });
    }
  }
  handleValidate = (id) => {
    validateImportAction(id, { validate: true });
    window.history.back();
  };
  handleImport = (id) => {
    const bomId =
      this.props.getImport.bom_id !== undefined
        ? this.props.getImport.bom_id
        : null;
    executeImportAction(id, { execute_import: true, bomId: bomId });
  };
  handleDelete = (id) => {
    deleteImportAction(id);
    window.history.back();
  };

  render() {
    const id = window.location.pathname.split("/bom/import/")[1];
    console.log(this.state.getImport);
    return (
      <div>
        <Header />
        <Container>
          {/* <p className="pt-5 text-warning">
            Fix the below errors and retry the import. All errors need to be
            fixed, warnings can optionally be ignored but it is not recommended.
          </p> */}

          <div style={{ minHeight: "100vh" }}>
            {this.props.getImportLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Loading />
              </div>
            ) : (
              <>
                <div className="mt-5">
                  <b>Info: </b>
                  &nbsp;<b>{this.state.getImport?.counts?.info}</b> &nbsp;&nbsp;
                  <b>Warnings: </b>
                  &nbsp;<b>{this.state.getImport?.counts?.warning}</b>{" "}
                  &nbsp;&nbsp;
                  <b>Errors: </b>
                  &nbsp;<b>{this.state.getImport?.counts?.error}</b>{" "}
                  &nbsp;&nbsp;
                </div>

                <Table striped bordered hover className="my-5">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>MPN</th>
                      <th>IPN</th>
                      <th>Description</th>
                      <th>Designator</th>
                      <th>Critical</th>
                      <th>Fitted</th>
                      <th>Primary GPN Match</th>
                      <th>Message</th>
                      <th>Recommended Resolution</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.getImport.import_messages !== undefined &&
                    this.state.getImport.import_messages !== null ? (
                      this.state.getImport.import_messages.map((el, ind) => (
                        <tr key={ind}>
                          <td>
                            {el.level == "info" && (
                              <b className="text-center">
                                <i className="fa fa-info text-primary fs-4"></i>
                              </b>
                            )}
                            {el.level == "warning" && (
                              <b className="text-center">
                                <i className="fa fa-exclamation-triangle text-warning fs-4"></i>
                              </b>
                            )}
                            {el.level == "error" && (
                              <div className="text-center">
                                <i className="fa fa-times-circle text-danger fs-4"></i>
                              </div>
                            )}
                          </td>
                          <td>{el.level}</td>
                          <td>{el.manufacturer_pn}</td>
                          <td>{el.ics_pn}</td>
                          <td></td>
                          <td>{el.designators}</td>
                          <td>{el.critical}</td>
                          <td>{el.fitted}</td>
                          <td>
                            {el.group_pn_match_list.map((el) => `${el},${" "}`)}
                          </td>
                          <td>{el.message}</td>
                          <td>{el.resolution}</td>
                        </tr>
                      ))
                    ) : (
                      <div className="w-100">
                        <h2 className="text-center">No Data</h2>
                      </div>
                    )}
                  </tbody>
                </Table>
              </>
            )}
          </div>
          <Row>
            <Col lg="8" sm="4">
              <Button
                className="btn btn-outline-danger"
                onClick={() => {
                  this.handleDelete(id);
                }}
              >
                Cancel Import
              </Button>
            </Col>
            <Col lg="2" sm="4">
              <Button
                className="btn btn-secondary float-end"
                styles=""
                onClick={() => this.handleValidate(id)}
              >
                Revalidate
              </Button>
            </Col>
            <Col lg="2" sm="4">
              <Button
                className="btn btn-outline-info float-end"
                onClick={() => this.handleImport(id)}
              >
                Run Import
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const stateMap = (state) => {
  return {
    getImport: state.bom.getImport,
    getImportLoading: state.bom.getImportLoading,
  };
};

export default connect(stateMap)(viewImportContainer);
