// add-mpn-container.js
// Our wrapper for the add IPN page

import React, { Component, Fragment } from "react";
import Header from "../../../components/header/header";
import AddEditMPN from "../../../components/inventory/mpn/add-edit-mpn";
import { useParams, withRouter } from "react-router-dom";
import Search from "../../../components/shared/search/search";
import { connect } from "react-redux";
import { gpnMembersAction } from "../../../actions/inventory/mpn-action";

class EditMPNContainer extends Component {
  constructor(props) {
    super(props);
    const mpn_id = this.props.match.params.id
    this.state = {
      queryParams: {},
      gpnMembers: [],
      gpnMembersLabels: [
        { label: "GPN" },
        { label: "GPN Class" },
        { label: "Type" },
        { label: "Desciption" },
      ],
      mpn_id: mpn_id
    };
    this.headerSearch = this.headerSearch.bind(this);
  }
  componentDidMount() {
    gpnMembersAction(this.state.mpn_id);
  }
  headerSearch(searchQuery) {
    window.location.replace(`/mpn?search_query=${searchQuery}`);
  }
  // //////////////////////////////////////////////////////////////////////
  componentDidUpdate(prevProps, prevState) {
    //  sidebar api's
    if (
      JSON.stringify(prevProps.gpnMembers) !==
      JSON.stringify(this.props.gpnMembers)
    ) {
      this.setState({
        gpnMembers: this.props.gpnMembers.map((elem) => {
          return {
            value1: elem.group_pn,
            value2: elem.gpn_class,
            value3: elem.part_type_name,
            value4: elem.description,
          };
        }),
      });
    }
    //
  }
  // /////////////////////////////////////////////////////////////////////
  render() {
    const id = this.props.match.params.id;

    return (
      <Fragment>
        <Header>
          <Search
            search={this.headerSearch}
            queryParams={this.state.queryParams}
          />
        </Header>
        <AddEditMPN
          mpn_id={id}
          module1={this.state.gpnMembers}
          module1Headings={this.state.gpnMembersLabels}
          module1Loading={this.props.gpnMembersLoading}
        />
      </Fragment>
    );
  }
}
const stateMap = (state) => {
  return {
    mpnbyId: state.inventory.mpnbyId,
    mpnbyIdLoading: state.inventory.mpnbyIdLoading,
    gpnMembers: state.inventory.gpnMembers,
    gpnMembersLoading: state.inventory.gpnMembersLoading,
  };
};

export default withRouter(connect(stateMap)(EditMPNContainer));
