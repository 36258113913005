import {
  LOGIN_RES,
  IS_LOGIN,
  LOGIN_MESSAGE,
  REFRESH_ERROR,
  REFRESH_TOKEN_COOKIE,
} from "../helpers/constants";
import cookie from "react-cookies";

export default function user(
  state = {
    isMute: false,
    data: {},
    token: {},
    loginMessage: "",
  },
  action
) {
  switch (action.type) {
    case LOGIN_RES: {
      return { ...state, token: action.payload };
    }

    case IS_LOGIN: {
      return { ...state, isLoggedIn: action.payload };
    }
    case LOGIN_MESSAGE: {
      return { ...state, loginMessage: action.payload };
    }
    default: {
      if (action.type.indexOf("@@") === -1) {
      }
    }
  }
  return state;
}
