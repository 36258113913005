import React from "react"
import "./loader.scss";

const Loader = (props) => {
    return (
        <div className="loader-container">
            <div className="dual-ring"></div>
        </div>
    )
};
export default Loader
