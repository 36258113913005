import { GET_ABOUT, GET_ABOUT_LOADING } from "../helpers/constants";

export default function about(
  state = {
    about: {},
    aboutLoading: false,
  },
  action
) {
  switch (action.type) {
    case GET_ABOUT: {
      return { ...state, about: action.payload };
    }
    case GET_ABOUT_LOADING: {
      return { ...state, aboutLoading: action.payload };
    }
  }
  return state;
}
