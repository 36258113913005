import React, { Component, Fragment } from "react";
import { Container, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { getGPNPartTypeAction } from "../../../actions/inventory/gpn-action";
import store from "../../../store";
import Header from "../../header/header";

class Partype extends Component {
  componentDidMount() {
    store.dispatch(getGPNPartTypeAction());
  }
  render() {
    console.log(this.props.partTypes);
    return (
      <Fragment>
        <Header />
        <Container className="my-4">
          <h4 className="py-2">Part Type List</h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Group PN Prefix</th>
                <th>ICS PN Prefix</th>
                <th>Name</th>
                <th>Short Description</th>
                <th>Part Description Format</th>
              </tr>
            </thead>
            <tbody>
              {this.props.partTypes.length &&
                this.props.partTypes.map((el, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{el.group_pn_prefix}</td>
                      <td>{el.ics_pn_prefix}</td>
                      <td>{el.name}</td>
                      <td>{el.description_short}</td>
                      <td>{el.part_description_format}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Container>
      </Fragment>
    );
  }
}

const stateMap = (state) => {
  return {
    partTypes: state.inventory.partType,
    partTypeRaw: state.inventory.partTypeRaw,
    partTypeLoading: state.inventory.partTypeLoading,
  };
};
export default connect(stateMap)(Partype);
