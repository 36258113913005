import React, { Component } from "react";
import { Button, Form, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class AltFrom extends Component {
  render() {
    return (
      <div>
        <Link to={"/alternatives/add"}>
          <Button>Create New Alt</Button>
        </Link>
        {/*                   List                      */}
        <h3 className="py-2 pt-4">Alternate GPN</h3>
        <ListGroup className="my-4">
          {this.props.altList.length ? (
            this.props.altList.map((el) => (
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-center my-2"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold d-flex">
                    {el.name}
                    <p className="px-4 text-muted">
                      <i>alternative [ {el.alternative_class_name} ]</i>
                    </p>
                    <p>
                      Alternate GPN :{" "}
                      <Link to={`/gpn?search_query=${el.base_group_pn_name}`}>
                        {el.alternative_group_pn_name}
                      </Link>
                    </p>
                  </div>

                  <p>
                    <i>
                      Updated on {el.time_updated.split("T")[0]} by{" "}
                      <b className="text-light"> &nbsp;{el.updated_by_name}</b>
                    </i>
                  </p>
                </div>
                <Form.Check
                  type={"checkbox"}
                  checked={el.active ? true : false}
                  label={`Active`}
                />
              </ListGroup.Item>
            ))
          ) : (
            <h5 className="text-light">N/A</h5>
          )}
        </ListGroup>
      </div>
    );
  }
}
