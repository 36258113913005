import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getAltClassesAction } from "../../../actions/inventory/alternative-action";
import Header from "../../../components/header/header";
import AddEditAlternative from "../../../components/inventory/alternative/add-edit-alternative";

class AddAlternativeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      altClasses: [],
    };
  }
  componentDidMount() {
    getAltClassesAction();
    this.setState({ altClasses: this.props.altClasses });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.altClasses) !==
      JSON.stringify(this.props.altClasses)
    ) {
      this.setState({ altClasses: this.props.altClasses });
    }
  }

  render() {
    console.log(this.props.altClasses);
    return (
      <Fragment>
        {/* <Header /> */}
        <AddEditAlternative altClasses={this.state.altClasses} is_add={true} />
      </Fragment>
    );
  }
}
const stateMap = (state) => {
  return {
    altClasses: state.inventory.altClasses,
    altClassesLoading: state.inventory.altClassesLoading,
  };
};
export default connect(stateMap)(AddAlternativeContainer);
