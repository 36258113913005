import { init } from "../../api/http";
import { getPartTypesWithFields } from "../../api/inventory/inventory-api";
import {
  addMPN,
  editMPN,
  getMPN_bomusage,
  getMPN_bomUsageSimilar,
  getMPN_GPNmembers,
  getMPN_history,
  getMPN_parameters,
  getMPNById,
  getMPNList,
  mpnAutocomplete,
  mpnLoadSuggestions,
  deactivateMpn,
  getMpnfiles,
  addMpnFile,
  downloadAllMpnFiles,
  getMPN_stocks,
  getAllMpnComments,
  addMpnComment,
} from "../../api/inventory/mpn-api";
import {
  INVENTORY_ERROR,
  MPN_AUTOCOMPLETE,
  MPN_AUTOCOMPLETE_LOADING,
  MPN_PARAM_SUGGESTIONS,
  MPN_PARAM_SUGGESTIONS_LOADING,
  MPN_BOM_USAGE,
  MPN_BOM_USAGE_LOADING,
  MPN_BOM_USAGE_SIMILAR,
  MPN_BOM_USAGE_SIMILAR_LOADING,
  MPN_BY_ID,
  MPN_BY_ID_LOADING,
  MPN_GPN_MEMBERS,
  MPN_GPN_MEMBERS_LOADING,
  MPN_HISTORY,
  MPN_HISTORY_LOADING,
  MPN_ITEM_ADD,
  MPN_ITEM_ADD_LOADING,
  MPN_ITEM_EDIT,
  MPN_ITEM_EDIT_LOADING,
  MPN_LIST,
  MPN_LIST_LOADING,
  MPN_LIST_RAW,
  MPN_WITH_PARAMETERS,
  MPN_WITH_PARAMETERS_LOADING,
  PART_TYPE,
  PART_TYPE_LOADING,
  TO_GPN,
  TO_MPN,
  ACTIVATE_MPN_LOADING,
  ACTIVATE_MPN,
  MPN_FILES_LOADING,
  MPN_FILES,
  ADD_MPN_FILE_LOADING,
  ADD_MPN_FILE,
  MPN_AUTOCOMPLETE_RAW,
  MPN_STOCK_LOADING,
  MPN_STOCK,
  MPN_COMMENT_LIST_LOADING,
  MPN_COMMENT_LIST,
  ADD_MPN_COMMENT_LOADING,
  ADD_MPN_COMMENT,
  MPN_COMMENT_LIST_RAW,
} from "../../helpers/constants";
import store from "../../store";
import { errorToast, successToast, warningToast } from "../../helpers/utility";
import fileDownload from "js-file-download";

// import fileDownload  from 'js-file-download';

require("es6-promise").polyfill();

// GET the list of MPNs
export function getMPNListAction(data) {
  init();
  store.dispatch({
    type: MPN_LIST_LOADING,
    payload: true,
  });
  return async function (dispatch) {
    try {
      const res = await getMPNList(data);
      if (data.request_type === "csv") {
        fileDownload(res.data, "mpn_data.csv");
      }
      dispatch({
        type: MPN_LIST_LOADING,
        payload: false,
      });
      store.dispatch({
        type: MPN_LIST,
        payload: [],
      });
      if (res.data.mpn_list) {
        dispatch({
          type: MPN_LIST,
          payload: res.data.mpn_list,
        });
        dispatch({
          type: MPN_LIST_RAW,
          payload: res.data,
        });
        dispatch({
          type: MPN_LIST_LOADING,
          payload: false,
        });
      } else {
        dispatch({
          type: INVENTORY_ERROR,
          payload: res.data.message,
        });
      }
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch({
        type: MPN_LIST_LOADING,
        payload: false,
      });
    }
  };
}
//////////////////

// GET a MPN and everything needed for the sidebar
export function getMPNAction(id) {
  getMPNByIdAction(id);
  gpnMembersAction(id);
  mpnParametersAction(id);
  mpnBomUsageAction(id);
  mpnBomUsageSimilarAction(id);
  mpnHistoryAction(id);
  mpnStockAction(id)
}

// GET a MPN, include
export function getMPNWithFieldsAction(id) {
  init();
  store.dispatch({
    type: MPN_WITH_PARAMETERS_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MPN_WITH_PARAMETERS,
    payload: [],
  });

  // Get a MPN with parameters
  return async function (dispatch) {
    try {
      const res = await getMPN_parameters(id);
      if (res.data) {
        store.dispatch({
          type: MPN_WITH_PARAMETERS,
          payload: res.data,
        });
        store.dispatch({
          type: MPN_WITH_PARAMETERS_LOADING,
          payload: false,
        });
      } else {
        store.dispatch({
          type: INVENTORY_ERROR,
          payload: res.data.message,
        });
      }
    } catch (error) {
      store.dispatch({
        type: MPN_WITH_PARAMETERS_LOADING,
        payload: false,
      });
    }
  };
}

// GET MPN with only basic info
async function getMPNByIdAction(id) {
  init();
  store.dispatch({
    type: MPN_BY_ID_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MPN_BY_ID,
    payload: {},
  });

  try {
    const res = await getMPNById(id);
    if (res.data) {
      store.dispatch({
        type: MPN_BY_ID,
        payload: res.data,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    store.dispatch({
      type: MPN_BY_ID_LOADING,
      payload: false,
    });
  } catch (error) {
    store.dispatch({
      type: MPN_BY_ID_LOADING,
      payload: false,
    });
  }
}

// GET MPN, include GPN members
export async function gpnMembersAction(id) {
  init();
  store.dispatch({
    type: MPN_GPN_MEMBERS_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MPN_GPN_MEMBERS,
    payload: [],
  });

  try {
    const res = await getMPN_GPNmembers(id);
    if (res.data.gpn_membership) {
      store.dispatch({
        type: MPN_GPN_MEMBERS,
        payload: res.data.gpn_membership,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    store.dispatch({
      type: MPN_GPN_MEMBERS_LOADING,
      payload: false,
    });
  } catch (error) {
    store.dispatch({
      type: MPN_GPN_MEMBERS_LOADING,
      payload: false,
    });
  }
}

// GET MPN, include parameters
async function mpnParametersAction(id) {
  init();
  store.dispatch({
    type: MPN_WITH_PARAMETERS_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MPN_WITH_PARAMETERS,
    payload: [],
  });

  try {
    const res = await getMPN_parameters(id);
    if (res.data) {
      store.dispatch({
        type: MPN_WITH_PARAMETERS,
        payload: res.data.parameters,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    store.dispatch({
      type: MPN_WITH_PARAMETERS_LOADING,
      payload: false,
    });
  } catch (error) {
    store.dispatch({
      type: MPN_WITH_PARAMETERS_LOADING,
      payload: false,
    });
  }
}

// GET MPN, include BOM usage
async function mpnBomUsageAction(id) {
  init();
  store.dispatch({
    type: MPN_BOM_USAGE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MPN_BOM_USAGE,
    payload: [],
  });

  try {
    const res = await getMPN_bomusage(id);
    if (res.data.bom_usage) {
      store.dispatch({
        type: MPN_BOM_USAGE,
        payload: res.data.bom_usage,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    store.dispatch({
      type: MPN_BOM_USAGE_LOADING,
      payload: false,
    });
  } catch (error) {
    store.dispatch({
      type: MPN_BOM_USAGE_LOADING,
      payload: false,
    });
  }
}

// GET MPN, include similar BOM usage
async function mpnBomUsageSimilarAction(id) {
  init();
  store.dispatch({
    type: MPN_BOM_USAGE_SIMILAR_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MPN_BOM_USAGE_SIMILAR,
    payload: [],
  });

  try {
    const res = await getMPN_bomUsageSimilar(id);
    if (res.data.bom_usage_similar) {
      store.dispatch({
        type: MPN_BOM_USAGE_SIMILAR,
        payload: res.data.bom_usage_similar,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    store.dispatch({
      type: MPN_BOM_USAGE_SIMILAR_LOADING,
      payload: false,
    });
  } catch (error) {
    store.dispatch({
      type: MPN_BOM_USAGE_SIMILAR_LOADING,
      payload: false,
    });
  }
}

// GET MPN, include history
async function mpnHistoryAction(id) {
  init();
  store.dispatch({
    type: MPN_HISTORY_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MPN_HISTORY,
    payload: [],
  });

  try {
    const res = await getMPN_history(id);
    if (res.data.history) {
      store.dispatch({
        type: MPN_HISTORY,
        payload: res.data.history,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    store.dispatch({
      type: MPN_HISTORY_LOADING,
      payload: false,
    });
  } catch (error) {
    store.dispatch({
      type: MPN_HISTORY_LOADING,
      payload: false,
    });
  }
}
//     MPN Stock
async function mpnStockAction(id) {
  init();
  store.dispatch({
    type: MPN_STOCK_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MPN_STOCK,
    payload: {},
  });

  try {
    const res = await getMPN_stocks(id);
    if (res.data) {
      store.dispatch({
        type: MPN_STOCK,
        payload: res.data.stock,
      });
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    store.dispatch({
      type: MPN_STOCK_LOADING,
      payload: false,
    });
  } catch (error) {
    store.dispatch({
      type: MPN_STOCK_LOADING,
      payload: false,
    });
  }
}

// POST to add a MPN
export function addMPNAction(data) {
  init();
  store.dispatch({
    type: MPN_ITEM_ADD_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MPN_ITEM_ADD,
    payload: {},
  });
  return async function (dispatch) {
    try {
      const res = await addMPN(data);
      if (res.data) {
        window.location.replace(`/mpn/${res.data.id}`);
      }
      if (res) {
        dispatch({
          type: MPN_ITEM_ADD,
          payload: res.data,
        });
      } else {
        dispatch({
          type: INVENTORY_ERROR,
          payload: res.data.message,
        });
      }
      successToast("Successfully added MPN");
      dispatch({
        type: MPN_ITEM_ADD_LOADING,
        payload: false,
      });
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch({
        type: MPN_ITEM_ADD_LOADING,
        payload: false,
      });
    }
  };
}

// PUT to edit an existing MPN
export function editMPNAction(id, data) {
  init();
  store.dispatch({
    type: MPN_ITEM_EDIT_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MPN_ITEM_EDIT,
    payload: {},
  });
  return async function (dispatch) {
    const res = await editMPN(id, data);
    if (res) {
      // window.location.replace(TO_MPN);

      if (data.request_type === "csv") {
        fileDownload(res.data, "mpn_data.csv");
      }
      dispatch({
        type: MPN_ITEM_EDIT,
        payload: res.data,
      });
    } else {
      dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    successToast("Successfully edited MPN");
    dispatch({
      type: MPN_ITEM_EDIT_LOADING,
      payload: false,
    });
  };
}

// GET autocomplete options
export function mpnAutocompleteAction(fieldName, query, partTypeId) {
  init();
  store.dispatch({
    type: MPN_AUTOCOMPLETE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MPN_AUTOCOMPLETE,
    payload: {},
  });
  return async function (dispatch) {
    const res = await mpnAutocomplete(fieldName, query, partTypeId);
    if (res) {
      dispatch({
        type: MPN_AUTOCOMPLETE,
        fieldName: fieldName,
        payload: res.data,
      });
      dispatch({
        type: MPN_AUTOCOMPLETE_RAW,
        payload: res.data.suggestions,
      });
    } else {
      dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    dispatch({
      type: MPN_AUTOCOMPLETE_LOADING,
      payload: false,
    });
  };
}

// GET suggestions options
export function mpnLoadSuggestionsAction(mpn, partTypeId) {
  init();
  store.dispatch({
    type: MPN_PARAM_SUGGESTIONS_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MPN_PARAM_SUGGESTIONS,
    payload: {},
  });
  return async function (dispatch) {
    const res = await mpnLoadSuggestions(mpn, partTypeId);
    if (res) {
      dispatch({
        type: MPN_PARAM_SUGGESTIONS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    dispatch({
      type: MPN_PARAM_SUGGESTIONS_LOADING,
      payload: false,
    });
  };
}

// GET a list of part types and include fields
export function getPartTypesWithFieldsAction() {
  init();
  store.dispatch({
    type: PART_TYPE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: PART_TYPE,
    payload: [],
  });
  return async function (dispatch) {
    const res = await getPartTypesWithFields();
    if (res.data.part_type_list) {
      dispatch({
        type: PART_TYPE,
        payload: res.data.part_type_list,
      });
    } else {
      dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    dispatch({
      type: PART_TYPE_LOADING,
      payload: false,
    });
  };
}

//      Deactivate Mpn

export function deactivateMPNAction(id, data) {
  init();
  store.dispatch({
    type: ACTIVATE_MPN_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ACTIVATE_MPN,
    payload: {},
  });
  return async function (dispatch) {
    const res = await deactivateMpn(id, data);
    if (res) {
      dispatch({
        type: ACTIVATE_MPN,
        payload: res.data,
      });
    } else {
      dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
    successToast(
      `MPN ${data.active == false ? "deactivated" : "activated"} successfully`
    );
    window.location.reload();
    dispatch({
      type: ACTIVATE_MPN_LOADING,
      payload: false,
    });
  };
}

//    Files

// get files of a gpn

export async function getMPNfilesAction(id) {
  init();
  store.dispatch({
    type: MPN_FILES_LOADING,
    payload: true,
  });
  store.dispatch({
    type: MPN_FILES,
    payload: [],
  });

  const res = await getMpnfiles(id);
  if (res.data.files) {
    store.dispatch({
      type: MPN_FILES,
      payload: res.data.files,
    });
  } else {
    store.dispatch({
      type: INVENTORY_ERROR,
      payload: res.data.message,
    });
  }
  store.dispatch({
    type: MPN_FILES_LOADING,
    payload: false,
  });
}

// Add File

export async function addMpnFileAction(id, data) {
  init();
  store.dispatch({
    type: ADD_MPN_FILE_LOADING,
    payload: true,
  });
  store.dispatch({
    type: ADD_MPN_FILE,
    payload: {},
  });

  try {
    const res = await addMpnFile(id, data);

    if (res.data) {
      store.dispatch({
        type: ADD_MPN_FILE_LOADING,
        payload: false,
      });
      store.dispatch({
        type: ADD_MPN_FILE,
        payload: res.data,
      });
      getMPNfilesAction(id);
    } else {
      store.dispatch({
        type: INVENTORY_ERROR,
        payload: res.data.message,
      });
    }
  } catch (error) {
    warningToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: ADD_MPN_FILE_LOADING,
      payload: false,
    });
  }
}

//           download all file

export async function downloadAllMpnFilesAction(id) {
  init();

  try {
    const res = await downloadAllMpnFiles(id);

    if (res) {
      // fileDownload(res.data, "data.zip");
      let headerLine = res.headers;
      console.log(headerLine);
      saveAs(new Blob([res.data], { type: "application/octet-stream" }));
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
}
//   list of mpn comments
export function mpnCommentsListAction(id) {
  init();
  store.dispatch({
    type: MPN_COMMENT_LIST_LOADING,
    payload: true,
  });
  return async function (dispatch) {
    try {
      const res = await getAllMpnComments(id);

      dispatch({
        type: MPN_COMMENT_LIST_LOADING,
        payload: false,
      });
      store.dispatch({
        type: MPN_COMMENT_LIST,
        payload: [],
      });
      if (res.data.comments) {
        dispatch({
          type: MPN_COMMENT_LIST,
          payload: res.data.comments,
        });
        dispatch({
          type: MPN_COMMENT_LIST_RAW,
          payload: res.data,
        });
        dispatch({
          type: MPN_COMMENT_LIST_LOADING,
          payload: false,
        });
      }
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch({
        type: MPN_COMMENT_LIST_LOADING,
        payload: false,
      });
    }
  };
}
//  gpn add comment
export function addMpnCommentAction(id, data) {
  init();
  store.dispatch({
    type: ADD_MPN_COMMENT_LOADING,
    payload: true,
  });
  return async function (dispatch) {
    try {
      const res = await addMpnComment(id, data);
      if (res.data) {
        store.dispatch(mpnCommentsListAction(id));
        dispatch({
          type: ADD_MPN_COMMENT,
          payload: res.data,
        });
        dispatch({
          type: ADD_MPN_COMMENT_LOADING,
          payload: false,
        });
      }
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch({
        type: ADD_MPN_COMMENT_LOADING,
        payload: false,
      });
    }
  };
}
