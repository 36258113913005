import { getBomList } from "../../api/bom/bom-api";

let timeoutId = null;
export const bomLoadOptions = (inputValue, callback) => {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(async () => {
    // console.log("bom list", inputValue);
    const list = await getBomList({
      request_content: { search_query: inputValue },
    });
    // console.log(list);
    callback(
      list.data.bom_list.map((el) => ({
        id: el.id,
        value: el.id,
        description: el.description,
        // ics_pn: el.ics_pn,
        // manufacturer_pn: el.manufacturer_pn,
        label: `[${el.name}] ${el.description}`,
      }))
    );
  }, 1000);
};
