import { MPN_API } from "../constants";
import {
  bom_usage_similar,
  gpn_membership,
  mpnbom_usage,
  mpnhistory,
  mpnList,
  parameters,
  total_stock
} from "../exmple.json";
// mpn-api.js
// Functions for the method and enedpoint of MPN-related functions
import { http } from "../http";

export const getMPNList = async (data = {}) => {
  return http.get(MPN_API, { params: { ...mpnList, ...data } });
};

export const getMPNById = async (id) => {
  return http.get(MPN_API + "/" + id);
};
export const getMPN_GPNmembers = async (id) => {
  return http.get(MPN_API + "/" + id, { params: gpn_membership });
};
export const getMPN_parameters = async (id) => {
  return http.get(MPN_API + "/" + id, { params: parameters });
};
export const getMPN_bomusage = async (id) => {
  return http.get(MPN_API + "/" + id, { params: mpnbom_usage });
};
export const getMPN_bomUsageSimilar = async (id) => {
  return http.get(MPN_API + "/" + id, { params: bom_usage_similar });
};
export const getMPN_history = async (id) => {
  return http.get(MPN_API + "/" + id, { params: mpnhistory });
};
export const getMPN_stocks = async (id) => {
  return http.get(MPN_API + "/" + id, { params: total_stock });
};
export const addMPN = async (data) => {
  return http.post(MPN_API, data);
};
export const editMPN = async (id, data) => {
  return http.put(MPN_API + `/${id}`, data);
};
export const mpnAutocomplete = async (fieldName, query, partTypeId) => {
  let data = {};
  if (partTypeId != null)
    data = { field_name: fieldName, query: query, part_type_id: partTypeId };
  else data = { field_name: fieldName, query: query };
  return http.get(MPN_API + `/autocomplete`, { params: data });
};

export const mpnLoadSuggestions = async (mpn, partTypeId) => {
  const data = { manufacturer_pn: mpn, part_type_id: partTypeId };
  return http.get(MPN_API + `/suggestions`, { params: data });
};
const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/merge-patch+json",
    Accept: "application/merge-patch+json",
  },
};
export const deactivateMpn = async (id, data) => {
  return http.patch(MPN_API + `/${id}`, data, config);
};

// Files

export const getMpnfiles = async (id) => {
  return http.get(MPN_API + `/${id}/files`);
};

export const addMpnFile = async (id, data) => {
  return http.post(MPN_API + `/${id}/files`, data);
};

export const downloadAllMpnFiles = async (id) => {
  return http.get(MPN_API + "/" + id + "/files?download=all", {
    responseType: "stream",
  });
};

//                 Comments

export const getAllMpnComments = async (id) => {
  return http.get(MPN_API + `/${id}/comments`);
};

export const addMpnComment = async (id, data) => {
  return http.post(MPN_API + `/${id}/comments`, data);
};
