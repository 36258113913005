import React, { Component } from "react";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  deleteLineItemAction,
  errorsCountAction,
  getBOMLineItemsAction,
} from "../../actions/bom/bom-action";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import ReactPaginate from "react-paginate";
import store from "../../store";
import Loading from "../shared/loader/spinner";
import { Link } from "react-router-dom";
import { ADD_MPN } from "../../helpers/constants";
import { updateQueryParams } from "../../helpers/utility";

export default class LineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      rawData: {},
      modalIsOpen: false,
      messages: [],
    };
  }
  componentDidMount() {
    this.setState({ data: this.props.lineItems });
    this.setState({ rawData: this.props.listRaw });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.lineItems !== prevProps.lineItems) {
      this.setState({
        data: this.props.lineItems,
      });
    }
    if (this.props.listRaw !== prevProps.listRaw) {
      this.setState({
        rawData: this.props.listRaw,
      });
    }
  }

  handlePageClick = (event) => {
    const id = this.props.lineItems[0]?.bom_revision_uuid;
    let url = window.location.protocol + window.location.pathname + `?page_number=${event.selected + 1}` + "#items";
    window.history.pushState({path: url}, "", url)
    // updateQueryParams({ page_number: `${event.selected + 1}` });
    let requestContent = {
      items_per_page: 100,
      page_number: event.selected + 1,
    };

    getBOMLineItemsAction(id, {
      request_content: requestContent,
      request_type: "list",
    });
  };

  render() {
   
    const gpns = this.state.data.map((el) => {
      return el?.child_group_pns?.[0]?.group_pn;
    });
    const mpns = this.state.data.map((el) => {
      return el?.main_manufacturer_pn?.manufacturer_pn;
    });
    const desc = this.state.data.map((el) => {
      return el?.main_manufacturer_pn?.description;
    });
    const sources = this.state.data.map((el) => {
      return el?.child_group_pn_maps?.[0]?.source;
    });

    const critical = this.state.data.map((el) => {
      return el.critical;
    });
    const fitted = this.state.data.map((el) => {
      return el.fitted;
    });

    var count = 0;
    const newData = this.state.data.map((el, ind) => {
      count++;
      const data = Object.assign(el, {
        group_pn: gpns[ind],
        manufacturer_pn: mpns[ind],
        description: desc[ind],
        quantity: count,
        tableId: ind,
        source: sources[ind],
        // critical: (
        //   <Form.Check
        //     checked={critical[ind] == true ? true : false}
        //     type={"checkbox"}
        //   />
        // ),
        // fitted: (
        //   <Form.Check
        //     checked={fitted[ind] == true ? true : false}
        //     type={"checkbox"}
        //   />
        // ),
      });
      if (data.child_group_pns.length)
        data.child_group_pns[0].source = sources[ind];
      return data;
    });
    // console.log(newData);
    // console.log(this.state.data.map((el) => el.child_group_pns));
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Check if you have entered a comma-separated list for each column that
        needs splitting into individual rows
      </Tooltip>
    );
    const columns = [
      {
        dataField: "messages",
        text: "",
        align: "center",
        editable: false,
        formatter: (cell, row) => {
          if (row) {
            const errors = row.messages.filter((el) => el.level == "error");
            const warnings = row.messages.filter((el) => el.level == "warning");
            const info = row.messages.filter((el) => el.level == "info");
            const ok = row.messages.filter((el) => el.level == "ok");
            const critical = row.messages.filter(
              (el) => el.level == "critical"
            );
            return (
              <>
                {errors.length == 0 &&
                ok.length == 0 &&
                info.length == 0 &&
                warnings.length == 0 &&
                critical.length == 0 ? (
                  <></>
                ) : (
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => {
                      this.setState({ modalIsOpen: true });
                      this.setState({ messages: row.messages });
                    }}
                  >
                    {errors.length !== 0 && (
                      <>
                        <b>
                          <i class="fa fa-times-circle text-danger"></i>{" "}
                        </b>
                        &nbsp;<b>{errors.length}</b> &nbsp;
                      </>
                    )}
                    {warnings.length !== 0 && (
                      <>
                        <b>
                          <i class="fa fa-exclamation-circle text-warning"></i>
                        </b>
                        &nbsp;<b>{warnings.length}</b> &nbsp;
                      </>
                    )}
                    {critical.length !== 0 && (
                      <>
                        <b>
                          {" "}
                          <i class="fa fa-crosshairs text-danger"></i>
                        </b>
                        &nbsp;<b>{critical.length}</b> &nbsp;
                      </>
                    )}
                    {info.length !== 0 && (
                      <>
                        <b>
                          <i class="fa fa-info-circle text-info"></i>
                        </b>
                        &nbsp;<b>{info.length}</b> &nbsp;
                      </>
                    )}
                    {ok.length !== 0 && (
                      <>
                        <b>
                          <i class="fa fa-check-circle text-info"></i>
                        </b>
                        &nbsp;<b>{ok.length}</b> &nbsp;
                      </>
                    )}
                  </Button>
                )}
              </>
            );
          }
          return null;
        },
      },
      ,
      {
        dataField: "group_pn",
        text: "Default GPN",
        editable: false,
      },
      {
        dataField: "manufacturer_pn",
        text: "Default MPN",
      },
      {
        dataField: "description",
        text: "Description",
        editable: false,
      },
      // { dataField: "quantity", text: "Quantity" },
      { dataField: "designator", text: "Designator" },
      {
        dataField: "critical",
        text: "Critical",
        editable: false,
        align: "center",
        editable: false,
        formatter: (cell, row) => {
          if (row) return <Form.Check checked={row.critical ? true : false} />;
          return null;
        },
      },
      {
        dataField: "fitted",
        text: "Fitted",
        editable: false,
        align: "center",
        editable: false,
        formatter: (cell, row) => {
          if (row) return <Form.Check checked={row.fitted ? true : false} />;
          return null;
        },
      },
      {
        dataField: "side",
        text: "Side",
        editable: false,
      },
      {
        dataField: "requires_split",
        text: (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <span>Requires Split</span>
          </OverlayTrigger>
        ),
        editable: false,
        formatter: (cell, row) => {
          if (row)
            return <Form.Check checked={row.requires_split ? true : false} />;
          return null;
        },
      },
      // here
      {
        dataField: "databasePkey",
        text: "",
        align: "center",
        editable: false,
        formatter: (cell, row) => {
          if (row)
            return (
              <Button
                className="btn btn-danger btn-xs rounded"
                size="sm"
                onClick={() => {
                  deleteLineItemAction(row.id, row.bom_revision_uuid);
                }}
              >
                <i className="fa fa-times"></i>
              </Button>
            );
          return null;
        },
      },
    ];
    if (this.props.showPosition) {
      let xpos = {
        dataField: "xpos_mm",
        text: "Xpos (mm)",
        editable: false,
      };
      let ypos = {
        dataField: "ypos_mm",
        text: "Ypos (mm)",
        editable: false,
      };
      let rot = {
        dataField: "rotation_deg",
        text: "Rotation",
        editable: false,
      };
      columns.splice(10, 0, xpos, ypos, rot);
    }
    //     child Columns
    const childcolumns = [
      { dataField: "group_pn", text: "GPN", editable: true },
      { dataField: "gpn_class", text: "Class" },
      { dataField: "description", text: "Description" },
      { dataField: "source", text: "Source" },

      {
        dataField: "databasePkey",
        text: "",
        align: "center",
        editable: false,
        formatter: (cell, row) => {
          if (row)
            return (
              <div className="mx-auto">
                <Button
                  onClick={() =>
                    deleteLineItemAction(row.id, row.bom_revision_uuid)
                  }
                  className="btn btn-danger btn-xs border-secondary rounded"
                  size="sm"
                >
                  <i className="fa fa-times"></i>
                </Button>
              </div>
            );
          return null;
        },
      },
    ];
    //
    const expandRow = {
      renderer: (row) => {
        return (
          <div>
            <BootstrapTable
              keyField="id"
              data={newData[row.tableId].child_group_pns}
              columns={childcolumns}
              rowStyle={{ backgroundColor: "#333" }}
              cellEdit={cellEditFactory({
                mode: "click",
                onStartEdit: (row, column, rowIndex, columnIndex) => {},
                beforeSaveCell: (oldValue, newValue, row, column) => {},
                afterSaveCell: (oldValue, newValue, row, column) => {},
              })}
            />
            <Button
              className="rounded"
              size="sm"
              // onClick={() => {
              //   let newRow = { id: this.state.childdata.length + 1 };
              //   this.setState({ childdata: [...this.state.childdata, newRow] });
              // }}
            >
              <i className="fa fa-plus"></i>
            </Button>
          </div>
        );
      },
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <Button
              size="sm"
              className="text-center bg-light rounded cursor-pointer"
            >
              -
            </Button>
          );
        }
        return (
          <Button
            size="sm"
            className="text-center bg-light rounded cursor-pointer"
          >
            +
          </Button>
        );
      },
    };
    let totalErrors = 0;

    this.state.data.length &&
      this.state.data.map((el) => {
        if (el.messages.length) {
          el.messages.map((e) => {
            if (e.level === "error") {
              totalErrors = totalErrors + 1;
            }
          });
        }
      });
    return (
      <div className="mb-5">
        {this.props.errorCounts && (
          <>
            <span className="px-1">
              <i class="fa fa-times-circle text-danger"></i>{" "}
              {this.props.errorCounts?.ERROR}
            </span>
            <span className="px-1">
              <i class="fa fa-crosshairs text-danger"></i>{" "}
              {this.props.errorCounts?.CRITICAL}
            </span>
            <span className="px-1">
              <i class="fa fa-exclamation-circle text-warning"></i>{" "}
              {this.props.errorCounts?.WARNING}
            </span>
            <span className="px-1">
              <i class="fa fa-info-circle text-info"></i>{" "}
              {this.props.errorCounts?.INFO}
            </span>
            <span className="px-1">
              <i class="fa fa-check-circle text-info"></i>{" "}
              {this.props.errorCounts?.OK}
            </span>
          </>
        )}

        {this.props.lineitemsLoading ? (
          <div className="text-center py-5">
            <Loading />
          </div>
        ) : (
          <div className="w-100 scrolable-table" style={{ overflowX: "auto" }}>
            <BootstrapTable
              keyField="tableId"
              data={newData}
              columns={columns}
              expandRow={expandRow}
              cellEdit={cellEditFactory({
                mode: "click",
                // onStartEdit: (row, column, rowIndex, columnIndex) => {},
                // beforeSaveCell: (oldValue, newValue, row, column) => {},
                // afterSaveCell: (oldValue, newValue, row, column) => {},
              })}
              hover
            />
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center">
          <Button
            className="rounded"
            size="sm"
            // onClick={() => {
            //   let newRow = { id: this.state.data.length + 1 };
            //   this.setState({ data: [...this.state.data, newRow] });
            // }}
          >
            <i className="fa fa-plus"></i>
          </Button>
        </div>
        <div className="main-table-pagination pagination-sm pt-3">
          <ReactPaginate
            previousLabel={"‹"}
            nextLabel={"›"}
            breakLabel={"..."}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            initialPage={0}
            // forcePage={this.props.listRaw?.pagination?.current_page}
            pageCount={this.props.listRaw?.pagination?.total_pages}
            disableInitialCallback={true}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageLinkClassName={"page-link"}
            pageClassName={"page-item"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            onPageChange={this.handlePageClick}
          />
        </div>
        {/* Modal */}
        <Modal
          show={this.state.modalIsOpen}
          onHide={() => this.setState({ modalIsOpen: false })}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Line Items Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.messages.length ? (
              this.state.messages.map((el) => (
                <div>
                  <h4>
                    {el.level == "warning" && (
                      <i class="fa fa-exclamation-circle text-warning"></i>
                    )}
                    {el.level == "critical" && (
                      <i class="fa fa-crosshairs text-danger"></i>
                    )}
                    {el.level == "error" && (
                      <i class="fa fa-times-circle text-danger"></i>
                    )}
                    {el.level == "info" && (
                      <i class="fa fa-info-circle text-info"></i>
                    )}
                    {el.level == "ok" && (
                      <i class="fa fa-check-circle text-info"></i>
                    )}
                    &nbsp;
                    {el.level + " " + el.code}:{" "}
                  </h4>
                  <div>
                    <b>Message:</b> <small>{el.message}</small> &nbsp;{" "}
                    {el.message.includes("No active MPN") && (
                      <Link to={ADD_MPN}>
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                      </Link>
                    )}
                  </div>
                  <div>
                    {el.description && (
                      <>
                        <b>Description:</b> <small>{el.description}</small>
                      </>
                    )}
                  </div>
                  <div>
                    {el.resolution && (
                      <>
                        <b>Resolution:</b> <small>{el.resolution}</small>
                      </>
                    )}
                  </div>
                  <hr />
                </div>
              ))
            ) : (
              <h2>No Messages</h2>
            )}
          </Modal.Body>
        </Modal>
        {/* Modal */}
      </div>
    );
  }
}
