import React, { Component } from "react";
import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import { getSelectDarkStyle } from "../../helpers/filter-dropdown-dark";
import AsyncSelect from "react-select/async";
import { loadOptions } from "./altLoadOptions";
import { bomLoadOptions } from "./bomLoadOptions";
import { Link } from "react-router-dom";
import {
  additionAltAction,
  addManageAltAction,
  syncBomAction,
  updateAltStatusAction,
} from "../../actions/bom/bom-action";

export default class AltUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAlt: "",
      checked: false,
    };
    this.loadOptions = loadOptions.bind(this);
    this.bomLoadOptions = bomLoadOptions.bind(this);
  }
  handleAdd = () => {
    const id = this.props.rev_id
    addManageAltAction(id, {
      alternative_id: this.state.selectedAlt,
      status: this.state.checked ? "pending_review" : "accepted",
    });
  };

  render() {
    let id = this.props.rev_id
    let time_synced = new Date(this.props.timeSynced?.time_last_synced);
    let time_updated = new Date(this.props.timeSynced?.time_alts_updated);

    if (
      time_synced.getTime() > time_updated.getTime() &&
      this.props.additionAlt.length > 0
    ) {
      this.props.altStatus("fa-exclamation-circle text-warning");
    } else if (
      time_synced.getTime() <= time_updated.getTime() &&
      this.props.additionAlt.length > 0
    ) {
      this.props.altStatus("fa-times-circle text-danger");
    } else {
      this.props.altStatus("fa-check-circle text-success");
    }

    return (
      <div className="py-0">
        <div className="d-flex justify-content-start align-items-center">
          <Button onClick={() => syncBomAction(id)}>
            Synchronize BOM <i class="fa fa-refresh" aria-hidden="true"></i>
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <div>
            {time_synced.getTime() <= time_updated.getTime() ? (
              <i
                class="fa fa-exclamation-circle text-danger"
                aria-hidden="true"
                style={{ fontSize: "2rem" }}
              ></i>
            ) : (
              <i
                class="fa fa-check-circle text-success"
                aria-hidden="true"
                style={{ fontSize: "2rem" }}
              ></i>
            )}
          </div>
          <p className="pt-3 px-3">
            <i>
              Last synced{" "}
              {this.props.timeSynced?.time_last_synced ? (
                this.props.timeSynced?.time_last_synced.split("T")[0]
              ) : (
                <span className="text-muted">Never</span>
              )}
              . Alts updated{" "}
              {this.props.timeSynced?.time_alts_updated &&
                this.props.timeSynced?.time_alts_updated.split("T")[0]}
            </i>
          </p>
        </div>
        <Row className="my-1">
          <Col>
            <Row className="w-100">
              <Form.Group as={Row} controlId="formPlaintext">
                <Col lg={1}>
                  <Form.Label>Add alt:</Form.Label>
                </Col>
                <Col>
                  <AsyncSelect
                    cacheOptions
                    size="sm"
                    classNamePrefix="lp-copy-sel mx-4"
                    // isDisabled={this.state.gpnActive ? false : true}
                    loadOptions={this.loadOptions}
                    styles={getSelectDarkStyle("md")}
                    defaultOptions
                    // onInputChange={this.handleInputChange}
                    onChange={(el) => this.setState({ selectedAlt: el.id })}
                    placeholder="ALT0001 000-0000 to 999-9999"
                  />
                </Col>
                <Col lg={2}>
                  <Form.Check
                    type={"checkbox"}
                    label={`Request Approval`}
                    onChange={() =>
                      this.setState({ checked: !this.state.checked })
                    }
                  />
                </Col>
                <Col lg={5}>
                  <Button className="px-4" onClick={this.handleAdd}>
                    Add
                  </Button>
                </Col>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              {" "}
              <Form.Group as={Row}>
                <Col lg={1}>
                  <Form.Label>Source:</Form.Label>
                </Col>
                <Col>
                  <AsyncSelect
                    cacheOptions
                    size="sm"
                    classNamePrefix="lp-copy-sel mx-4"
                    // isDisabled={this.state.gpnActive ? false : true}
                    loadOptions={this.bomLoadOptions}
                    styles={getSelectDarkStyle("md")}
                    defaultOptions
                    // onInputChange={this.handleInputChange}
                    // onChange={(el) =>
                    //   this.setState({
                    //     mpnstoAdd: [...this.state.mpnstoAdd, el],
                    //   })
                    // }
                    placeholder="Search BOM"
                  />
                </Col>
                <Col lg={7} className="d-flex justify-content-end">
                  <Button>Accept All</Button>
                </Col>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        {/*                   List                      */}
        <h4 className="pt-4">Suggested Alts</h4>
        <ListGroup className="my-1">
          {this.props.additionAlt.length ? (
            this.props.additionAlt.map((el) => {
              let date = new Date(this.props.additionAlt[0]?.time_updated);
              let time = date.getHours() + ":" + date.getMinutes();

              return (
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start my-1"
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">
                      <Link
                        to={"/alternatives?search_query=" + el.alternative.name}
                      >
                        {el.alternative.name}
                      </Link>

                      <span className="px-4">
                        <Link
                          to={
                            "/gpn?search_query=" +
                            el.alternative.base_group_pn_name
                          }
                        >
                          {el.alternative.base_group_pn_name}
                        </Link>{" "}
                         ➜{" "}
                        <Link
                          to={
                            "/gpn?search=" +
                            el.alternative?.alternative_group_pn_name
                          }
                        >
                          {el.alternative.alternative_group_pn_name}
                        </Link>{" "}
                        
                      </span>
                      <span className="text-muted">
                        <i>{el.alternative.alternative_class_name} Alt</i>
                      </span>
                    </div>
                    <>
                    {el.alternative?.base_group_pn?.description} ➜{" "} {el.alternative.alternative_group_pn?.description}
                    </>
                    <br />
                    <>
                      <i>
                        Updated on{" "}
                        <span className="text-success">
                          {el.time_updated.split("T")[0]}
                        </span>{" "}
                        at <span className="text-success">{time}</span> by{" "}
                        {el.updated_by_name}
                      </i>
                    </>
                  </div>
                  <Button
                    variant="outline"
                    className="btn btn-outline-success"
                    pill
                    onClick={() => {
                      updateAltStatusAction(el.uuid, { status: "accepted" });
                    }}
                  >
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </Button>
                  &nbsp;
                  <Button
                    className="btn btn-danger"
                    onClick={() => {
                      updateAltStatusAction(el.uuid, { status: "rejected" });
                    }}
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </Button>
                </ListGroup.Item>
              );
            })
          ) : (
            <h5 className="text-light">N/A</h5>
          )}
        </ListGroup>
        <hr />
        {/* ////////////////////////////////PENDING ALTS//////////////////////////////////////////////// */}
        <h4 className="py-0">Pending Review</h4>
        <ListGroup className="my-1">
          {this.props.pendingAlt.length ? (
            this.props.pendingAlt.map((el) => {
              let date = new Date(this.props.pendingAlt[0]?.time_suggested);
              let time = date.getHours() + ":" + date.getMinutes();
              return (
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start my-1"
                >
                 <div className="ms-2 me-auto">
                    <div className="fw-bold">
                      <Link
                        to={"/alternatives?search_query=" + el.alternative.name}
                      >
                        {el.alternative.name}
                      </Link>

                      <span className="px-4">
                        <Link
                          to={
                            "/gpn?search_query=" +
                            el.alternative.base_group_pn_name
                          }
                        >
                          {el.alternative.base_group_pn_name}
                        </Link>{" "}
                         ➜{" "}
                        <Link
                          to={
                            "/gpn?search=" +
                            el.alternative?.alternative_group_pn_name
                          }
                        >
                          {el.alternative.alternative_group_pn_name}
                        </Link>{" "}
                        
                      </span>
                      <span className="text-muted">
                        <i>{el.alternative.alternative_class_name} Alt</i>
                      </span>
                    </div>
                    <>
                    {el.alternative?.base_group_pn?.description} ➜{" "} {el.alternative.alternative_group_pn?.description}
                    </>
                    <br />
                    <>
                      <i>
                        Updated on{" "}
                        <span className="text-success">
                          {el.time_updated.split("T")[0]}
                        </span>{" "}
                        at <span className="text-success">{time}</span> by{" "}
                        {el.updated_by_name}
                      </i>
                    </>
                  </div>
                  <Button
                    variant="outline"
                    className="btn btn-outline-success"
                    onClick={() => {
                      updateAltStatusAction(el.uuid, { status: "accepted" });
                    }}
                  >
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </Button>
                  &nbsp;
                  <Button
                    className="btn btn-danger"
                    onClick={() => {
                      updateAltStatusAction(el.uuid, { status: "rejected" });
                    }}
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </Button>
                </ListGroup.Item>
              );
            })
          ) : (
            <h5 className="text-light">N/A</h5>
          )}
        </ListGroup>
        <hr />
        {/* //////////////////////////////////////////////////////////////////////////////// */}
        <h4 className="py-0">Suggested for Deletion</h4>
        <ListGroup className="my-1">
          {this.props.deletionAlt.length ? (
            this.props.deletionAlt.map((el) => {
              let date = new Date(this.props.deletionAlt[0]?.time_updated);
              let time = date.getHours() + ":" + date.getMinutes();
              return (
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start my-1"
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">
                      <Link
                        to={"/alternatives?search_query=" + el.alternative.name}
                      >
                        {el.alternative.name}
                      </Link>

                      <span className="px-4">
                        <Link
                          to={
                            "/gpn?search_query=" +
                            el.alternative.base_group_pn_name
                          }
                        >
                          {el.alternative.base_group_pn_name}
                        </Link>{" "}
                         ➜{" "}
                        <Link
                          to={
                            "/gpn?search=" +
                            el.alternative?.alternative_group_pn_name
                          }
                        >
                          {el.alternative.alternative_group_pn_name}
                        </Link>{" "}
                        
                      </span>
                      <span className="text-muted">
                        <i>{el.alternative.alternative_class_name} Alt</i>
                      </span>
                    </div>
                    <>
                    {el.alternative?.base_group_pn?.description} ➜{" "} {el.alternative.alternative_group_pn?.description}
                    </>
                    <br />
                    <>
                      <i>
                        Updated on{" "}
                        <span className="text-success">
                          {el.time_updated.split("T")[0]}
                        </span>{" "}
                        at <span className="text-success">{time}</span> by{" "}
                        {el.updated_by_name}
                      </i>
                    </>
                  </div>

                  <Button
                    className="btn btn-danger"
                    onClick={() => {
                      updateAltStatusAction(el.uuid, { status: "rejected" });
                    }}
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </Button>
                </ListGroup.Item>
              );
            })
          ) : (
            <h5 className="text-light">N/A</h5>
          )}
        </ListGroup>
        <hr />
        {/* //////////////////////////////////////////////////////////////////////////////// */}
        <h4 className="py-0">Accepted</h4>
        <ListGroup className="my-1">
          {this.props.acceptedAlt.length ? (
            this.props.acceptedAlt.map((el) => {
              let date = new Date(this.props.acceptedAlt[0]?.time_accepted);
              let time = date.getHours() + ":" + date.getMinutes();
              return (
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start my-1"
                >
                 <div className="ms-2 me-auto">
                    <div className="fw-bold">
                      <Link
                        to={"/alternatives?search_query=" + el.alternative.name}
                      >
                        {el.alternative.name}
                      </Link>

                      <span className="px-4">
                        <Link
                          to={
                            "/gpn?search_query=" +
                            el.alternative.base_group_pn_name
                          }
                        >
                          {el.alternative.base_group_pn_name}
                        </Link>{" "}
                         ➜{" "}
                        <Link
                          to={
                            "/gpn?search=" +
                            el.alternative?.alternative_group_pn_name
                          }
                        >
                          {el.alternative.alternative_group_pn_name}
                        </Link>{" "}
                        
                      </span>
                      <span className="text-muted">
                        <i>{el.alternative.alternative_class_name} Alt</i>
                      </span>
                    </div>
                    <>
                    {el.alternative?.base_group_pn?.description} ➜{" "} {el.alternative.alternative_group_pn?.description}
                    </>
                    <br />
                    <>
                      <i>
                        Updated on{" "}
                        <span className="text-success">
                          {el.time_updated.split("T")[0]}
                        </span>{" "}
                        at <span className="text-success">{time}</span> by{" "}
                        {el.updated_by_name}
                      </i>
                    </>
                  </div>
                  <Button
                    className="btn btn-danger"
                    onClick={() => {
                      updateAltStatusAction(el.uuid, { status: "rejected" });
                    }}
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </Button>
                </ListGroup.Item>
              );
            })
          ) : (
            <h5 className="text-light">N/A</h5>
          )}
        </ListGroup>
        <hr />
        {/* ///////////////////////////////////////////////////////////////////////////////// */}
        <h4 className="py-0">Rejected</h4>
        <ListGroup className="my-1">
          {this.props.rejectedAlt.length ? (
            this.props.rejectedAlt.map((el) => {
              let date = new Date(this.props.rejectedAlt[0]?.time_rejected);
              let time = date.getHours() + ":" + date.getMinutes();
              return (
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start my-1"
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">
                      <Link
                        to={"/alternatives?search_query=" + el.alternative.name}
                      >
                        {el.alternative.name}
                      </Link>

                      <span className="px-4">
                        <Link
                          to={
                            "/gpn?search_query=" +
                            el.alternative.base_group_pn_name
                          }
                        >
                          {el.alternative.base_group_pn_name}
                        </Link>{" "}
                         ➜{" "}
                        <Link
                          to={
                            "/gpn?search=" +
                            el.alternative?.alternative_group_pn_name
                          }
                        >
                          {el.alternative.alternative_group_pn_name}
                        </Link>{" "}
                        
                      </span>
                      <span className="text-muted">
                        <i>{el.alternative.alternative_class_name} Alt</i>
                      </span>
                    </div>
                    <>
                    {el.alternative?.base_group_pn?.description} ➜{" "} {el.alternative.alternative_group_pn?.description}
                    </>
                    <br />
                    <>
                      <i>
                        Updated on{" "}
                        <span className="text-success">
                          {el.time_updated.split("T")[0]}
                        </span>{" "}
                        at <span className="text-success">{time}</span> by{" "}
                        {el.updated_by_name}
                      </i>
                    </>
                  </div>
                  <Button
                    variant="outline"
                    className="btn btn-outline-success"
                    onClick={() => {
                      updateAltStatusAction(el.uuid, { status: "accepted" });
                    }}
                  >
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </Button>
                </ListGroup.Item>
              );
            })
          ) : (
            <h5 className="text-light">N/A</h5>
          )}
        </ListGroup>
      </div>
    );
  }
}
