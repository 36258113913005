import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { aboutAction } from "../../actions/about/about-action";
import { BACKEND_VERSION } from "../../api/constants";
import { http } from "../../api/http";
import Header from "../../components/header/header";

class AboutContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backVersion: "",
    };
  }
  componentDidMount() {
    aboutAction();
    document.title = "About"
    // this.getBackVersion();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.about !== this.props.about) {
      this.setState({ backVersion: this.props.about.version });
    }
  }

  render() {
    console.log(this.state.backVersion);
    console.log(process.env);
    return (
      <div>
        <Header />
        <Container>
          <h2 className="py-4">About</h2>
          <h4>Incontrol Online</h4>
          <p className="pt-4">
            <strong>
              Frontend Version:{" "}
            </strong>
            <span className="text-info">
              {process.env.REACT_APP_GIT_DESCRIBE}
            </span>
          </p>
          <p>
            <strong>
              Backend Version:{" "}
            </strong>
            <span className="text-info">{this.state.backVersion}</span>
          </p>
        </Container>
      </div>
    );
  }
}

const stateMap = (state) => {
  return {
    about: state.about.about,
    gpnListRaw: state.about.aboutLoading,
  };
};

export default connect(stateMap)(AboutContainer);
