import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  FormLabel,
  Modal,
  OverlayTrigger,
  ProgressBar,
  Row,
  Tooltip,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  bomRevUpdateAction,
  getBOMLineItemsAction,
  ImportStatusAction,
} from "../../actions/bom/bom-action";
import ImportDropdowns from "./importDropdowns";
import Tasks from "./Tasks";

class RevModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      critical: "DEFAULT_FALSE",
      fitted: "DEFAULT_TRUE",
      designator: "ERROR",
      importStatus: [],
      status: false,
      completed: false,
    };
  }
  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.state.status && !this.state.completed) {
        ImportStatusAction(this.props.revId.task_id);
      }
    }, 2000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.revId !== this.props.revId) {
      if (this.props.revId.task_id) {
        ImportStatusAction(this.props.revId.task_id);
        this.setState({ status: true });
      }
    }
    if (
      JSON.stringify(prevProps.importStatus) !==
      JSON.stringify(this.props.importStatus)
    ) {
      this.setState({ importStatus: this.props.importStatus });
      let status = [];
      this.props.importStatus.map((el) => {
        if (
          el.state == "SUCCESS" ||
          el.state == "FAILURE" ||
          el.state == "REVOKED"
        ) {
          status.push(true);
        } else {
          status.push(false);
        }
      });
      const total = status.every((el) => {
        return el == true;
      });
      if (total == true) {
        this.setState({ completed: true });
      }
    }
  }

  render() {
    const rev_id = this.props.rev_id;
    if (this.state.completed) {
      setTimeout(() => {
        this.props.revModal(false);
        getBOMLineItemsAction(rev_id);
      }, 1500);
    }
    return (
      <Modal
        show={true}
        onHide={() => this.props.revModal(false)}
        centered
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Bom Revision</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!this.state.status && (
            <ImportDropdowns
              critical={this.state.critical}
              fitted={this.state.fitted}
              designator={this.state.designator}
              set_critical={(e) => this.setState({ critical: e })}
              set_fitted={(e) => this.setState({ fitted: e })}
              set_designator={(e) => this.setState({ designator: e })}
            />
          )}
          {this.state.status && (
            <Tasks importStatus={this.state.importStatus} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => this.props.revModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              bomRevUpdateAction(rev_id, {
                critical_handling: this.state.critical,
                fitted_handling: this.state.fitted,
                designator_handling: this.state.designator,
                action: this.props.revType,
              })
            }
          >
            {this.props.revType}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const stateMap = (state) => {
  return {
    revId: state.bom.revId,
    revIdLoading: state.bom.revIdLoading,
    importStatus: state.bom.importStatus,
    importStatusLoading: state.bom.importStatusLoading,
  };
};

export default connect(stateMap)(RevModal);
