import { http, init } from "../http";
import { COMMENTS_API, FILES_API, GPN_API } from "../constants";
import {
  gpnList,
  mpn_members,
  stock_locations,
  total_stock,
  bom_usage,
  history,
  alternatives_to,
  alternatives_from,
} from "../exmple.json";

//                   Get list of gpn
export const getGPNList = async (data = {}) => {
  return http.get(GPN_API, {
    params: { ...gpnList, ...data },
  });
};
//                 Get single gpn details api's
export const getGPNById = async (id) => {
  return http.get(GPN_API + "/" + id);
};
export const getGPN_MPNmembers = async (id) => {
  return http.get(GPN_API + "/" + id, { params: mpn_members });
};
export const getGPN_stockLocations = async (id) => {
  return http.get(GPN_API + "/" + id, { params: stock_locations });
};
export const getGPN_totalStock = async (id) => {
  return http.get(GPN_API + "/" + id, { params: total_stock });
};
export const getGPN_bomUsage = async (id) => {
  return http.get(GPN_API + "/" + id, { params: bom_usage });
};
export const getGPN_history = async (id) => {
  return http.get(GPN_API + "/" + id, { params: history });
};
export const getGPN_altTo = async (id) => {
  return http.get(GPN_API + "/" + id, { params: alternatives_to });
};
export const getGPN_altFrom = async (id) => {
  return http.get(GPN_API + "/" + id, { params: alternatives_from });
};
///                Add gpn api's

export const getGPNSimilar = async (data) => {
  return http.get(GPN_API + "/similar", {
    params: data,
  });
};
export const getGPNSuggest = async (data) => {
  return http.get(GPN_API + "/suggestmpn", {
    params: data,
  });
};
export const addGPN = async (data) => {
  return http.post(GPN_API, data);
};
export const editGPN = async (id, data) => {
  return http.put(GPN_API + `/${id}`, data);
};
export const gpnAutoComplete = async (data) => {
  return http.get(GPN_API + "/autocomplete", {
    params: data,
  });
};
//  Activate and  Deactivate
//  Activate and  Deactivate
const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/merge-patch+json",
    Accept: "application/merge-patch+json",
  },
};
export const deactivateGpn = async (id, data) => {
  return http.patch(GPN_API + `/${id}`, data, config);
};

// Files

export const getGpnfiles = async (id) => {
  return http.get(GPN_API + `/${id}/files`);
};

export const addGpnFile = async (id, data) => {
  return http.post(GPN_API + `/${id}/files`, data);
};
// export const updateGpnFile = async (uuid, data) => {
//   return http.put(FILES_API + `/${uuid}`, data);
// };
export const deleteGpnFile = async (uuid) => {
  return http.delete(FILES_API + `/${uuid}`);
};
export const downloadGpnFile = async (uuid) => {
  return http.get(FILES_API + `/${uuid}`, { responseType: "blob" });
};
export const downloadAllGpnFiles = async (id) => {
  return http.get(GPN_API + "/" + id + "/files?download=all", {
    responseType: "stream",
  });
};

//                 Comments

export const getAllGpnComments = async (id) => {
  return http.get(GPN_API + `/${id}/comments`);
};

export const addGpnComment = async (id, data) => {
  return http.post(GPN_API + `/${id}/comments`, data);
};
