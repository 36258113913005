import React, { Component, Fragment } from "react";
import {
  getMPNListAction,
  getMPNAction,
} from "../../../actions/inventory/mpn-action";
import Header from "../../../components/header/header";
import Search from "../../../components/shared/search/search";
import CustomTable from "../../../components/shared/table/table";
import store from "../../../store";
import { connect } from "react-redux";
import qs from "qs";
import { stringToInt, updateQueryParams } from "../../../helpers/utility";
import Sidebar from "../../../components/sidebar/sidebar";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class MPNListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mpnList: [],
      columnName: [
        { label: "IPN", key: "ics_pn" },
        { label: "MPN", key: "manufacturer_pn" },
        { label: "Manufacturer", key: "manufacturer" },
        { label: "Description", key: "description" },
        { label: "Type", key: "part_type_name" },
      ],
      queryParams: {},
      requestContent: {
        search_query: "",
      },
      pagination: {},

      gpnMembers: [],
      gpnMembersLabels: [
        { label: "GPN" },
        { label: "GPN Class" },
        { label: "Type" },
        { label: "Desciption" },
      ],
      parameters: [],
      parametersLabels: [
        { label: "Field Name" },
        { label: "Value" },
        { label: "Visibility" },
      ],
      mpnbomusage: [],
      mpnbomusageLabels: [
        { label: "BOM Name" },
        { label: "BOM Label" },
        { label: "Quantity" },
      ],
      bomusageSimilar: [],
      bomusageSimilarLabels: [
        { label: "BOM Name" },
        { label: "BOM Label" },
        { label: "Quantity" },
        { label: "Active" },
      ],
      mpnhistory: [],
      mpnhistoryLabels: [
        { label: "Field" },
        { label: "Message" },
        { label: "New Value" },
        { label: "Old Value" },
        { label: "Time" },
      ],
      row: ""
    };
    this.tableFilters = this.tableFilters.bind(this);
    this.headerSearch = this.headerSearch.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    document.title = "MPN List";
    this.setState(
      {
        queryParams: stringToInt(
          qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        ),
      },
      () => {
        this.getData(this.state.queryParams);
      }
    );
    getMPNAction(1);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.mpnList) !== JSON.stringify(this.props.mpnList)
    ) {
      this.setState({
        mpnList: this.props.mpnList.map((elem) => {
          return {
            0: elem.ics_pn,
            1: elem.manufacturer_pn,
            2: elem.manufacturer,
            3: elem.description,
            4: elem.part_type_name,
            id: elem.id,
            active: elem.active,
          };
        }),
      });
    }

    if (
      JSON.stringify(prevProps.mpnListRaw) !==
      JSON.stringify(this.props.mpnListRaw)
    ) {
      // PageCount
      this.setState({ pagination: this.props.mpnListRaw.pagination });
    }
    //  sidebar api's
    if (
      JSON.stringify(prevProps.gpnMembers) !==
      JSON.stringify(this.props.gpnMembers)
    ) {
      this.setState({
        gpnMembers: this.props.gpnMembers.map((elem) => {
          return {
            value1: (
              <Link
                to={`/gpn?filter_options%5B0%5D%5Bmatching%5D=equals&filter_options%5B0%5D%5Bquery%5D=${elem.group_pn}&filter_options%5B0%5D%5Bcolumn%5D=group_pn`}
              >
                {elem.group_pn}
              </Link>
            ),
            value2: elem.gpn_class,
            value3: elem.part_type_name,
            value4: elem.description,
          };
        }),
      });
    }
    if (
      JSON.stringify(prevProps.parameters) !==
      JSON.stringify(this.props.parameters)
    ) {
      this.setState({
        parameters: this.props.parameters.map((elem) => {
          return {
            value1: elem.field_name,
            value2: elem.value,
            value3: elem.visibility_sort_order,
          };
        }),
      });
    }
    if (
      JSON.stringify(prevProps.mpnbomusage) !==
      JSON.stringify(this.props.mpnbomusage)
    ) {
      this.setState({
        mpnbomusage: this.props.mpnbomusage.map((elem) => {
          return {
            value1: elem.bom_name,
            value2: elem.bom_label,
            value3: elem.quantity,
          };
        }),
      });
    }
    if (
      JSON.stringify(prevProps.bomusageSimilar) !==
      JSON.stringify(this.props.bomusageSimilar)
    ) {
      this.setState({
        bomusageSimilar: this.props.bomusageSimilar.map((elem) => {
          return {
            value1: elem.bom_name,
            value2: elem.bom_label,
            value3: elem.quantity,
            value4:
              elem.active == true ? (
                <i class="fa fa-check" aria-hidden="true"></i>
              ) : (
                <i class="fa fa-times" aria-hidden="true"></i>
              ),
          };
        }),
      });
    }
    if (
      JSON.stringify(prevProps.mpnhistory) !==
      JSON.stringify(this.props.mpnhistory)
    ) {
      this.setState({
        mpnhistory: this.props.mpnhistory.map((elem) => {
          return {
            value1: elem.field,
            value2: elem.message,
            value3: elem.new_value,
            value4: elem.old_value,
            value5: elem.time,
          };
        }),
      });
    }
  }

  headerSearch(searchQuery) {
    this.setState(
      {
        requestContent: {
          ...this.state.requestContent,
          search_query: searchQuery,
        },
      },
      () => {
        updateQueryParams(this.state.requestContent);
        this.getData(this.state.requestContent);
      }
    );
  }

  tableFilters(requestContent = {}, type = "list") {
    let { search_query } = this.state.requestContent;
    this.setState(
      { requestContent: { ...requestContent, search_query } },
      () => {
        this.getData(this.state.requestContent, type);
      }
    );
  }

  getData(requestContent = {}, type = "list") {
    requestContent = stringToInt(requestContent);
    // console.log("getData: ", requestContent);
    store.dispatch(
      getMPNListAction({ request_content: requestContent, request_type: type })
    );
  }

  handleRowClick = (id) => {
    getMPNAction(id);
    this.setState({row: id})
  };

  render() {
    return (
      <Fragment>
        <div className="main-section">
          <Header>
            <Search
              search={this.headerSearch}
              queryParams={this.state.queryParams}
            />
          </Header>
          <CustomTable
            queryParams={this.state.queryParams}
            columns={this.state.columnName}
            getId={this.handleRowClick}
            isLoading={this.props.mpnListLoading}
            tableFilters={this.tableFilters}
            data={this.state.mpnList}
            pagination={this.state.pagination}
          />
          <Sidebar
            row={this.state.row}
            module1name={"GPN Membership"}
            module1={this.state.gpnMembers}
            module1Headings={this.state.gpnMembersLabels}
            module1Loading={this.props.gpnMembersLoading}
            module2name={"Parameters"}
            module2={this.state.parameters}
            module2Headings={this.state.parametersLabels}
            module2Loading={this.props.parametersLoading}
            module4name={"Bom Usage (Member GPNs)"}
            module4={this.state.bomusageSimilar}
            module4Headings={this.state.bomusageSimilarLabels}
            module4Loading={this.props.bomusageSimilarLoading}
            module3name={"Bom Usage (Exact)"}
            module3={this.state.mpnbomusage}
            module3Headings={this.state.mpnbomusageLabels}
            module3Loading={this.props.mpnbomusageLoading}
            module5name={"History"}
            module5={this.state.mpnhistory}
            module5Headings={this.state.mpnhistoryLabels}
            module5Loading={this.props.mpnhistoryLoading}
            module6={this.props.mpnbyId.ics_pn}
            module6Loading={this.props.mpnbyIdLoading}
            module7={this.props.mpnbyId.description}
            module8={this.props.mpnStock}
            module8Loading={this.props.mpnStockLoading}
          />
        </div>
      </Fragment>
    );
  }
}
const stateMap = (state) => {
  return {
    mpnList: state.inventory.mpnList,
    mpnListRaw: state.inventory.mpnListRaw,
    mpnListLoading: state.inventory.mpnListLoading,
    mpnbyId: state.inventory.mpnbyId,
    mpnbyIdLoading: state.inventory.mpnbyIdLoading,
    gpnMembers: state.inventory.gpnMembers,
    gpnMembersLoading: state.inventory.gpnMembersLoading,
    parameters: state.inventory.parameters,
    parametersLoading: state.inventory.parametersLoading,
    mpnbomusage: state.inventory.mpnbomusage,
    mpnbomusageLoading: state.inventory.mpnbomusageLoading,
    bomusageSimilar: state.inventory.bomusageSimilar,
    bomusageSimilarLoading: state.inventory.bomusageSimilarLoading,
    mpnhistory: state.inventory.mpnhistory,
    mpnhistoryLoading: state.inventory.mpnhistoryLoading,
    mpnStock: state.inventory.mpnStock,
    mpnStockLoading: state.inventory.mpnStockLoading,
  };
};
export default connect(stateMap)(MPNListContainer);
