import { init } from "../../api/http";
import { bulkItemLookup } from "../../api/inventory/bulk-api";
import {
  BULK_ITEM_LOOKUP,
  BULK_ITEM_LOOKUP_LOADING,
} from "../../helpers/constants";
import { errorToast } from "../../helpers/utility";

export function bulkItemLookupAction(id) {
  init()
  return async function (dispatch) {
    dispatch({
      type: BULK_ITEM_LOOKUP_LOADING,
      payload: true,
    });
    dispatch({
      type: BULK_ITEM_LOOKUP,
      payload: [],
    });

    try {
      const res = await bulkItemLookup(id);

      if (res.data) {
        dispatch({
          type: BULK_ITEM_LOOKUP,
          payload: res.data.complete_pn_list,
        });
      } else {
        dispatch({
          type: INVENTORY_ERROR,
          payload: res.data.message,
        });
      }
      dispatch({
        type: BULK_ITEM_LOOKUP_LOADING,
        payload: false,
      });
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch({
        type: BULK_ITEM_LOOKUP_LOADING,
        payload: false,
      });
    }
  }
}
