import { init, refreshinit } from "../../api/http";
import {
  googleLogin,
  login,
  refresh,
  revokeAccess,
  revokeRefresh,
} from "../../api/user/user-api";
import {
  LOGIN_RES,
  LOGIN_MESSAGE,
  IS_LOGIN,
  TOKEN_COOKIE,
  PATH_COOKIE,
  REFRESH_ERROR,
  REFRESH_TOKEN_COOKIE,
} from "../../helpers/constants";
import cookie from "react-cookies";
import { errorToast, successToast } from "../../helpers/utility";
import { Redirect } from "react-router-dom";
import store from "../../store";

require("es6-promise").polyfill();
export function LoginAction(data) {
  return async function (dispatch) {
    try {
      dispatch({
        type: LOGIN_MESSAGE,
        payload: "",
      });
      const res = await login(data);
      localStorage.setItem("admin", res.data.is_admin);
      if (res.data) {
        dispatch({
          type: LOGIN_RES,
          payload: res.data,
        });
        dispatch({
          type: IS_LOGIN,
          payload: true,
        });
        successToast("login successfully!");
      } else {
        dispatch({
          type: LOGIN_MESSAGE,
          payload: res.data.message,
        });
      }
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch({
        type: LOGIN_MESSAGE,
        payload: error.message,
      });
    }
  };
}

export function googleLoginAction(data) {
  return async function (dispatch) {
    try {
      dispatch({
        type: LOGIN_MESSAGE,
        payload: "",
      });
      const res = await googleLogin(data);
      if (res.data) {
        dispatch({
          type: LOGIN_RES,
          payload: res.data,
        });
        dispatch({
          type: IS_LOGIN,
          payload: true,
        });
        successToast("login successfully!");
      } else {
        dispatch({
          type: LOGIN_MESSAGE,
          payload: res,
        });
      }
    } catch (error) {
      errorToast(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch({
        type: LOGIN_MESSAGE,
        payload: error.message,
      });
    }
  };
}

//  Refresh token
var errorCount = 0;

export async function RefreshAction() {
  refreshinit();
  try {
    const res = await refresh();
    if (res.data.access_token) {
      cookie.save(TOKEN_COOKIE, res.data.access_token, {
        path: PATH_COOKIE,
        expires: new Date(res.data.access_expiry),
      });
      <Redirect to={"/"} />;
    }
  } catch (error) {
    errorToast(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
    store.dispatch({
      type: REFRESH_ERROR,
      payload: "error",
    });
    errorCount = errorCount + 1;

    if (errorCount > 2) {
      cookie.remove(REFRESH_TOKEN_COOKIE, { path: PATH_COOKIE });
    }
  }
}
export function revokeTokenAction() {
  revokeAccessAction();
  revokeRefreshAction();
}

async function revokeAccessAction() {
  init();
  const res = await revokeAccess();
  if (res.data) {
    console.log(res.data);
  }
}

async function revokeRefreshAction() {
  init();
  const res = await revokeAccess();
  if (res.data) {
    console.log(res.data);
  }
}
