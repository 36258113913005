import React from "react";
import ReactDOM from "react-dom";
import 'bootswatch/dist/darkly/bootstrap.min.css'; // Added this :boom:
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import store from './store';
import { Provider } from 'react-redux';
import TimeAgo from 'javascript-time-ago'
import { GoogleOAuthProvider } from '@react-oauth/google';

import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)

try {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <GoogleOAuthProvider clientId="841626067022-i8u9mdn6mf3a900m8ot01d63q6fqjilq.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </Router>
    </Provider>,
    document.getElementById('root')
  );
} catch (e) {
  document.write('something went wrong');
}
