import React from "react";
import { Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <div
    // style={{
    //   width: "50px",
    //   height: "50px",
    //   position: "absolute",
    //   right: "10%",
    //   bottom: "10%",
    // }}
    >
      <Spinner animation="grow" variant="info"></Spinner>
    </div>
  );
};

export default Loading;
