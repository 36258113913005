import {
  GPN_COMMENT_LIST_LOADING,
  GPN_COMMENT_LIST,
  ADD_GPN_COMMENT_LOADING,
  ADD_GPN_COMMENT,
  MPN_COMMENT_LIST,
  MPN_COMMENT_LIST_LOADING,
  ADD_MPN_COMMENT,
  ADD_MPN_COMMENT_LOADING,
  BOM_COMMENT_LIST,
  BOM_COMMENT_LIST_LOADING,
  ADD_BOM_COMMENT,
  ADD_BOM_COMMENT_LOADING,
} from "../helpers/constants";

export default function comments(
  state = {
    getGpnComments: [],
    getGpnCommentsLoading: false,
    addGpnComment: {},
    addgpnCommentLoading: false,
    getMpnComments: [],
    getMpnCommentsLoading: false,
    addMpnComment: {},
    addMpnCommentLoading: false,
    getBomComments: [],
    getBomCommentsLoading: false,
    addBomComment: {},
    addBomCommentLoading: false,
  },
  action
) {
  switch (action.type) {
    case GPN_COMMENT_LIST: {
      return { ...state, getGpnComments: action.payload };
    }
    case GPN_COMMENT_LIST_LOADING: {
      return { ...state, getGpnCommentsLoading: action.payload };
    }

    case ADD_GPN_COMMENT: {
      return { ...state, addGpnComment: action.payload };
    }
    case ADD_GPN_COMMENT_LOADING: {
      return { ...state, addgpnCommentLoading: action.payload };
    }
    //  mpn
    case MPN_COMMENT_LIST: {
      return { ...state, getMpnComments: action.payload };
    }
    case MPN_COMMENT_LIST_LOADING: {
      return { ...state, getMpnCommentsLoading: action.payload };
    }
    case ADD_MPN_COMMENT: {
      return { ...state, addMpnComment: action.payload };
    }
    case ADD_MPN_COMMENT_LOADING: {
      return { ...state, addMpnCommentLoading: action.payload };
    }
    //  bom
    case BOM_COMMENT_LIST: {
      return { ...state, getBomComments: action.payload };
    }
    case BOM_COMMENT_LIST_LOADING: {
      return { ...state, getBomCommentsLoading: action.payload };
    }
    case ADD_BOM_COMMENT: {
      return { ...state, addBomComment: action.payload };
    }
    case ADD_BOM_COMMENT_LOADING: {
      return { ...state, addBomCommentLoading: action.payload };
    }

    default: {
      if (action.type.indexOf("@@") === -1) {
      }
    }
  }
  return state;
}
